<template>

    <div v-if="post">

        <app-section
            color="gold"
            class="l-section--no-padding"
        >
            <div class="grid__row --items-center --justify-end">
                <div
                    v-if="post.category.handle == 'capsules' && post.vimeo"
                    class="grid__col--12 grid__col--6@md grid__col--8@lg --no-gutter"
                >
                    <video-plyr
                        :videoID="post.vimeo.full"
                        :sticky="true"
                    />
                </div>
                <!-- TODO: dynamic asset -->
                <div
                    v-else-if="post.category.handle == 'chroniques' && post.image"
                    class="grid__col--12 grid__col--6@md grid__col--8@lg --no-gutter"
                >
                    <img
                        :src="post.image.lg"
                        :alt="post.image.title"
                        class="u-img"
                    />
                </div>
                <!-- ./TODO: dynamic asset -->
                <div class="l-section__content | p-adventures__header | grid__col--12 grid__col--6@md grid__col--4@lg">
                    <frame
                        :label="post.category.name"
                        :modifier="post.category.handle"
                    />
                    <h2 class="t-poster-40">{{ post.title }}</h2>

                    <router-link
                        v-if="author"
                        :to="author.url"
                        class="p-author"
                    >
                        <span
                            v-if="author.image"
                            class="p-author__image | u-rounded"
                        >
                            <asset :src="author.image.sm" />
                        </span>
                        <span class="p-author__name | t-heading-20">{{ author.name }}</span>
                    </router-link>


                    <div
                        v-if="post.excerpt"
                        v-html="post.excerpt"
                        class="t-cms"
                    ></div>
                    <tags
                        v-if="tags"
                        :tags="tags"
                        class="mt-4"
                    />
                </div>
<!--
                <div
                    v-if="post.image"
                    class="p-cover | grid__col--12 grid__col--6@md --no-gutter">
                    <asset
                        :src="post.image.lg"
                        :alt="post.title"
                    />
                </div>
-->
            </div>
        </app-section>

        <app-section v-if="post.builder">
            <div class="grid__row --justify-center --justify-start@lg">
                <article class="grid__col--12 grid__col--7@md --offset-5@md grid__col--7@lg --offset-4@lg">
                    <builder-loop :blocks="post.builder" />
                </article>
            </div>
        </app-section>

    </div>

</template>

<script>
    import types        from 'store/types'
    import BuilderLoop  from 'templates/builder/BuilderLoop'
    import AppSection     from 'layout/AppSection'
    import Tags        from 'objects/Tags'
    import Asset       from 'objects/Asset'
    import Frame       from 'objects/Frame'
    import VideoPlyr       from 'objects/VideoPlyr'

    import 'src/filters/uscape'

    export default {
        name: 'Chronique',
        components: {
            BuilderLoop,
            AppSection,
            Tags,
            Asset,
            Frame,
            VideoPlyr,
        },
        props: {
            body: {
                type: String
            },
            tags: {
                type: Array | Object
            },
        },
        data: () => ({
            author: null,
            post: null
        }),
        metaInfo() {
            return {
                title: this.post ? this.$options.filters.uscape(this.post.seo.title) : null,
            }
        },
        created() {

            if (this.isEmpty(this.$route.params.entrySlug))
                return this.$router.push(-1)

            this.$store
                .dispatch(types.LOAD_POST_BY_SLUG, this.$route.params.entrySlug)
                .catch(err => {
                    return this.$router.push(-1)
                })
                .then((post) => {

                    // Set post (that contain the builder)
                    this.post = post

                    // Dispatch three to get all users from all type with their `type` (role) prop
                    this.$store.dispatch(types.LOAD_ALL_GUIDES)
                        .then((guides) => {

                            this.$store.dispatch(types.LOAD_ALL_AUTHORS)
                                .then((authors) => {

                                    this.$store.dispatch(types.LOAD_USER_BY_ID, {id: post.authorId})
                                        .then(author => {
                                            this.author = author
                                        })
                                })
                        })
                })
        }
    }
</script>

<style lang="scss">

    .p-author {

        margin-top: 0.5em;
        margin-bottom: 1.5em;
        display: flex;
        align-items: center;

        .p-author__image {
            display: inline-block;
            width: 1.75em;
            height: 1.75em;
            margin-right: 0.5em;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
</style>
