<template>
    <div class="c-form__wrapper">
        <div v-if="success" class="o-success">
            <h3 class="o-success__title | t-heading-20">Demande envoyée</h3>
            <div class="o-success__desc | t-cms">
                <p>
                    Votre demande d'achat a bien été envoyée, nous vous
                    recontacterons rapidement.
                </p>
            </div>
        </div>

        <form action="/" class="c-form | grid__row" @submit.prevent="submit">
            <div
                v-for="(input, i) in inputs"
                :key="`input-${i}`"
                class="c-form__el | grid__col--12"
                :class="{ 'grid__col--6@xs': input.half }"
            >
                <app-input
                    :label="input.label"
                    :modifier="input.modifier"
                    :type="input.type"
                    :autocomplete="input.autocomplete"
                    :required="input.required"
                    :disabled="input.disabled ? input.disabled : false"
                    :options="input.options ? input.options : null"
                    :defaultValue="defaultValue(input.name)"
                    @change="
                        value => {
                            input.value = value;
                        }
                    "
                />
            </div>

            <div class="c-form__el c-form__el--submit | grid__col--12">
                <btn
                    label="Envoyer"
                    iconAfter="arrow-right-circ"
                    type="submit"
                />
            </div>
        </form>
    </div>
</template>

<script>
import AppInput from "objects/AppInput";
import Btn from "components/Btn";

export default {
    name: "ContactForm",
    components: {
        AppInput,
        Btn
    },
    data: () => ({
        inputs: [
            {
                label: "Prénom et nom",
                name: "fromName",
                value: "",
                modifier: "text",
                type: "text",
                required: true,
                autocomplete: "name",
                half: false
            },
            {
                label: "Adresse courriel",
                name: "fromEmail",
                value: "",
                modifier: "text",
                type: "email",
                disabled: false,
                defaultValue: false,
                required: true,
                autocomplete: "email",
                half: true
            },
            {
                label: "Téléphone",
                name: "message.phone",
                value: "",
                modifier: "text",
                type: "phone",
                required: false,
                autocomplete: "tel",
                half: true
            },
            {
                label: "Sujet",
                name: "subject",
                value: "",
                modifier: "select",
                type: "select",
                required: true,
                autocomplete: "",
                half: false,
                options: [
                    {
                        value: "formations",
                        label: "Formations"
                    },
                    {
                        value: "renseignements",
                        label: "Demande de renseignements"
                    },
                    {
                        value: "conseils",
                        label: "Conseils"
                    },
                    {
                        value: "destination",
                        label: "Destination"
                    },
                    {
                        value: "commentaires",
                        label: "Commentaires"
                    },
                    {
                        value: "partenariat",
                        label: "Partenariat"
                    }
                ]
            },
            {
                label: "Message",
                name: "message.content",
                value: "",
                modifier: "textarea",
                type: "textarea",
                required: true,
                autocomplete: "",
                half: false
            }
        ],
        submission: {
            message: {}
        },
        success: false
    }),
    created() {
        // Add Craft CSRF token to submission
        this.submission[this.csrfName] = this.csrfToken;
    },
    computed: {
        csrfName: () => window.csrfTokenName,
        csrfToken: () => window.csrfTokenValue
    },
    methods: {
        defaultValue(inputName) {
            return false; /* Keep TMP */
        },
        submit() {
            // Submit form if no errors
            let inputName;
            const user = {};

            this.success = !this.success;

            this.inputs.forEach(input => {
                inputName = input.name;

                if (inputName.startsWith("message.")) {
                    inputName = inputName.replace("message.", "");
                    this.submission.message[inputName] = input.value;
                } else {
                    this.submission[inputName] = input.value;
                }
            });

            fetch("/actions/contact-form/send", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(this.submission)
            })
                //.then(r => r.json())
                .then(r => r.text())
                .then(data => {
                    //console.log('data', data)
                    this.success = true;
                })
                .catch(error => console.error(error));
        }
    }
};
</script>

<style lang="scss">
.c-form {
    &__el {
        margin-bottom: 1.75em;
    }
}

/*----------  Success  ----------*/

.o-success {
    margin-top: 5vh;
    margin-bottom: 5vh;
    background-color: $color-light;
    padding: 2em;

    @media #{md("lg")} {
        padding: 4em;
    }

    &:after {
        @include pseudo-el($width: auto, $height: auto);
        position: absolute;
        top: 1em;
        left: 1em;
        right: 1em;
        bottom: 1em;
        border: 2px solid $color-dark;

        @media #{md("lg")} {
            top: 2em;
            left: 2em;
            right: 2em;
            bottom: 2em;
        }
    }
}

.o-success__title {
    margin-bottom: 0.75em;
}

.o-success__desc.t-cms {
}
</style>
