<template>
    <figure
        class="o-video-figure"
    >
        <div
            class="o-video-figure__inner"
            ref="inner"
        >

            <img
                v-if="image"
                class="lazyload"
                :src="image.lg"
            />

        </div>
    </figure>
</template>

<script>

import 'lazysizes';

import Variables from 'src/mixins/variables';
import debounce from 'lodash/debounce';

export default {
    name: 'FigureRatio',
    mixins: [ Variables ],
    props: {
        image: {
            type: Object,
            required: true,
        },
        ratio: {
            default: () => {
                return { 0: 1, 480: 4/3, 768: 3/4 }
            }
        }
    },
    data: () => ({
        resize : false,
    }),
    computed: {
    },
    methods: {

        setRatio() {
            let ratio
            if(typeof this.ratio === 'number') {
                ratio = `${1/this.ratio * 100}%`
            } else {
                ratio = this.getResponsiveRatio(this.ratio)
                ratio =`${1/ratio * 100}%`
            }

            this.$el.style.setProperty('--ratio', ratio);
        },
        getResponsiveRatio(ratios) {

            //console.log('ratios', ratios)

            let prev = -1
            let i
            let loop = 0
            let ratiosLength = Object.keys(ratios).length
            for(i in ratios) {
                loop++
                i = parseInt(i);
                if ((prev != -1) && (this.W.w < i)) {
                    return ratios[prev];
                } else {
                    prev = i;
                }

                if(loop === ratiosLength) {
                    return ratios[prev]
                }
            }
        }
    },
    created() {
    },
    mounted() {

        this.setRatio();

        // Bind window resize with ratio of multiple ratios
        if(typeof this.ratio === 'object') {
            window.addEventListener('resize', this.resize = debounce(this.setRatio, 100));
        }
    },
    destroyed() {
        // Remove window event
        if(typeof this.ratio === 'object') {
            window.removeEventListener('resize', this.resize);
        }
    }
}

</script>

<style lang="scss">

    .o-video-figure {
        //position: relative;
        --ratio: 100%;
        width: 100%;

        overflow: hidden;

        .o-video-figure__inner {
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-top: var(--ratio);
            transform-origin: 50% 100%;

            & > * {
                position: absolute;
            }

            @supports (object-fit: cover) {

                & > * {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }

            @supports not (object-fit: cover) {
                overflow: hidden;

                & > * {
                    top: 50%;
                    left: 50%;
                    min-width: 100%;
                    min-height: 100%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

</style>
