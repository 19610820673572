<template>
    <div>
        <app-section
            v-if="page"
            @mousemove.native="tallyHover"
        >
            <div
                class="o-tally | grid__row"
                :style="{ '--tally-x': tally.x }"
                v-view.reveal="2"
            >
                <div class="o-tally__title | grid__col--12 grid__col--6@md">
                    <h1 v-if="page.fields.headline"class="t-poster-80">{{ page.fields.headline }}</h1>
                </div>

                <div class="o-tally__grid">
                    <div
                        v-for="(slide, i) in carousel"
                        :key="`tally-${i}`"
                        class="o-tally__item"
                    >
                        <div class="o-tally__content">
                            <video-background
                                v-if="slide.videoId"
                                :videoID="slide.videoId"
                                :ratio="5/3"
                                class="c-card-video__asset"
                            />
                        </div>
                        <badge
                            v-if="i === 0"
                            icon="round-general"
                            color="orange"
                        />
                    </div>
                </div>
            </div>
        </app-section>

        <app-section>

            <article
                v-if="featuredSerie"
                class="p-home__serie | grid__row | --items-start --justify-end"
            >
                <div class="col-text | grid__col--12 grid__col--6@md | ml-auto">
                    <div class="u-boxed t-content">
                        <h2 class="t-poster-60">
                            <badge icon="round-series" />
                            <span>{{ featuredSerie.title }}</span>
                        </h2>
                        <p>{{ featuredSerie.excerpt }}</p>
                        <btn
                            tag="router-link"
                            label="Découvrez la série"
                            :href="featuredSerie.url"
                            iconAfter="arrow-right-circ"
                        />
                    </div>
                </div>
                <div
                    v-if="featuredSerie.episodes[0]"
                    class="col-video | grid__col--12 grid__col--6@md --no-gutter --gutter@xs --no-gutter@md | ml-auto"
                >
                    <card-video
                        :entry="featuredSerie.episodes[0]"
                        :title="featuredSerie.episodes[0]['title']"
                        :button="`Visionner l’épisode 1/${featuredSerie.episodes.length}`"
                        :url="`/aventures/${featuredSerie.episodes[0]['slug']}`"
                        :videoID="featuredSerie.episodes[0].vimeo[0].preview"
                        :ratio="1"
                        revealDirection="right"
                    />
                </div>
            </article>

            <article
                v-if="featuredAdventure"
                class="p-home__adventure | grid__row | --items-center --justify-end"
            >
                <Deco class="p-home__deco" />
                <div class="col-video | grid__col--12 grid__col--6@md grid__col--4@lg --no-gutter --gutter@xs --no-gutter@md">
                    <card-video :videoID="featuredAdventure.vimeo.preview" />
                </div>
                <div class="col-text | grid__col--12 grid__col--6@md">
                    <div class="u-boxed | t-content">
                        <h2 class="t-heading-20">Aventure en vedette</h2>
                        <adventure-label
                            :adventure="featuredAdventure"
                            color="o-green"
                        />
                        <h3
                            v-html="featuredAdventure.title"
                            class="t-poster-60"
                        ></h3>
                        <btn
                            tag="router-link"
                            label="Découvrez l’aventure"
                            iconBefore="play"
                            color="orange"
                            :href="`/aventures/${featuredAdventure.slug}`"
                        />
                    </div>
                </div>
            </article>
        </app-section>

        <app-section
            v-if="posts.length > 0"
            color="orange"
            class="p-home__journal"
        >
            <mask-title
                v-if="journal"
                :title="journal.title"
                :image="journal.fields.backgroundImage ? journal.fields.backgroundImage.transform.large : ''"
                class="p-home__journal-title"
            />
            <card-grid :cards="posts" />
        </app-section>
    </div>
</template>

<script>

    import types from 'store/types'
    import { routes } from 'src/router'

    import AppSection     from 'layout/AppSection'
    import CardGrid    from 'components/CardGrid'
    import CardVideo   from 'components/CardVideo'
    import Btn         from 'components/Btn'

    import AdventureLabel  from 'objects/AdventureLabel'
    import Asset       from 'objects/Asset'
    import Badge       from 'objects/Badge'
    import MaskTitle   from 'objects/MaskTitle'
    import VideoBackground  from 'objects/VideoBackground'
    import Deco  from 'objects/Deco'

    import Variables from 'src/mixins/variables';

    import gsap from 'gsap';

    export default {
        name: 'Home',
        mixins: [ Variables ],
        components: {
            AppSection,
            CardGrid,
            CardVideo,
            Btn,
            AdventureLabel,
            Asset,
            Badge,
            MaskTitle,
            VideoBackground,
            Deco,
        },
        metaInfo() {
            return {
                title: this.page.seo.title,
            }
        },
        computed: {

            page() {
                let pageId = this.$route.meta.id
                let page = this.$store.getters.getPageById(pageId)
                if (typeof page === 'undefined') {
                    return null
                }
                return page
            },

            carousel() {

                if (!this.page)
                    return

                return this.page.fields.carousel

            },

            // Fetch 3 last posts
            posts() {

                let _posts = this.$store.state.data.posts.map(post => ({
                    ...post,
                    body: {
                        title: post.title,
                        excerpt: post.excerpt
                    },
                    button: {
                        label: 'Lire la suite',
                        to: post.url
                    }
                }))
                return _posts.slice(0, 3)
            },

            journal() {
                const journal = this.$store.getters.getPageBySlug('journal')

                if(typeof journal === 'undefined') {
                    return
                }

                return journal
            }
        },
        data: () => {
            return {
                tally: {
                    x: 0
                },
                featuredSerie: null,
                featuredAdventure: null,
            }
        },
        methods: {
            tallyHover(e) {
                let x = -(Math.round((e.clientX - this.sidebarWidth) * 100/this.mainWidth) - 50)/50

                gsap.to(this.tally, .6, {
                    x: x,
                    ease: 'power2.out'
                })
            },
            tallyOrientation(e) {

                gsap.to(this.tally, .6, {
                    x: e.gamma/20,
                    ease: 'power2.out'
                })
            }
        },
        created() {

            const fields = this.page.fields
            const entryAdventure = fields.selectEntryAdventure
            const entrySerie = fields.selectEntrySerie

            // Adventure
            if ( entryAdventure ) {
                this.$store.dispatch(types.LOAD_ADVENTURE_BY_SLUG, entryAdventure.slug)
                    .then(adventure => {
                        this.featuredAdventure = adventure
                    })
            }

            // Serie
            if ( entrySerie ) {
                this.$store.dispatch(types.LOAD_SERIE_BY_SLUG, entrySerie.slug)
                    .then(serie => {
                        this.featuredSerie = serie
                    })
            }

            if (window.DeviceOrientationEvent) {
                window.addEventListener('deviceorientation', this.tallyOrientation);
            }
        },
        destroyed() {

            if (window.DeviceOrientationEvent) {
                window.removeEventListener('deviceorientation', this.tallyOrientation);
            }
        }
    }
</script>

<style lang="scss">

</style>
