<template>
    <div
        v-if="mailchimp && mailchimp.accountId"
        class="o-mailchimp"
    >
        <h3 class="t-poster-40">Restez à l’affût</h3>
        <p>Offres spéciales, nouveautés, promotions</p>

        <mailchimp-subscribe
            :url="`https://${mailchimp.listHandle}.list-manage.com/subscribe/post-json`"
            :user-id="mailchimp.accountId"
            :list-id="mailchimp.listId"
            @error="onError"
            @success="onSuccess"
        >
            <template v-slot="{ subscribe, setEmail, error, success, loading }">
                <form
                    class="c-form c-form--mailchimp | mt-5"
                    :class="[
                        { 'js-loading' : loading },
                    ]"
                    @submit.prevent="subscribe"
                >

                    <input
                        type="email"
                        placeholder="Adresse courriel"
                        class="c-form__input"
                        @input="setEmail($event.target.value)"
                    />

                    <button
                        type="submit"
                        class="c-form__submit"
                    >M’abonner</button>

                    <div class="c-form--mailchimp__messages | t-cms">
                        <div v-if="error" v-html="error"></div>
                        <div v-if="success">Réussi. Vérifiez vos courriels!</div>
                        <div v-if="loading">Chargement...</div>
                    </div>

                </form>
            </template>
        </mailchimp-subscribe>

    </div>
</template>

<script>
    import MailchimpSubscribe from 'vue-mailchimp-subscribe'

    export default {
        name: 'Mailchimp',
        components: {
            MailchimpSubscribe,
        },
        computed: {

            mailchimp() {

                let _mailchimp = this.$store.state.data.settings.mailchimp
                if ( _mailchimp )
                    return _mailchimp
            }
        },
        created() {
            //
        },
        methods: {
            onError() {
                // handle error
            },
            onSuccess() {
                // handle success
            },
        }
    }
</script>

<style lang="scss">

.o-mailchimp {

}

</style>
