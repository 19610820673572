<template>
    <p
        v-if="block"
        class="b-headline | t-lg"
        v-html="block.headline"
    ></p>
</template>

<script>

    export default {
        name: 'BlockHeadline',
        props: {
            block: {
                type: Object | Array
            },
            blockType: {
                type: String
            }
        },
    }
</script>

<style lang="scss">

    .b-headline {}

</style>


