<template>
    <div>
        <hero
            v-if="page"
            :title="page.title"
            :color="page.fields.color.label"
            :asset="
                page.fields.backgroundImage
                    ? page.fields.backgroundImage.transform.large
                    : ''
            "
            badgeIcon="round-guides"
        />

        <app-section v-if="allGuides">
            <div class="grid__row">
                <div class="grid__col--12 grid__col--8@lg grid__col--7@xxl">
                    <h2 class="l-section__title | t-poster-80">
                        {{ allGuides.length }} {{ title }}
                    </h2>
                </div>
            </div>

            <div
                v-if="featuredGuide"
                class="p-guides__featured | grid__row items-start"
            >
                <div
                    class="p-guides__cover | grid__col--12 grid__col--6@sm --no-gutter --gutter@xs --no-gutter@md"
                >
                    <asset
                        :src="featuredGuide.image"
                        :alt="featuredGuide.name"
                    />
                </div>
                <div class="grid__col--12 grid__col--6@sm">
                    <h3 class="l-section__title | t-poster-40">
                        {{ featuredGuide.name }}
                    </h3>
                    <div v-html="featuredGuide.body" class="t-cms"></div>
                    <tags
                        v-if="featuredGuide.specialities"
                        :tags="featuredGuide.specialities"
                        class="mt-4"
                    />
                </div>

                <div v-if="featuredGuideEntries" class="grid__col--12">
                    <card-grid
                        class="p-guides__cards"
                        :cards="featuredGuideEntries"
                    />
                </div>
            </div>

            <!--             <h2 class="l-section__title | t-poster-80">Tous les guides</h2> -->

            <card-grid
                v-if="guides"
                :cards="guides"
                :ratio="{ 0: 1, 480: 4 / 3, 768: 5 / 4 }"
            />
        </app-section>
    </div>
</template>

<script>
import types from "store/types";
import isEmpty from "lodash/isEmpty";

import Hero from "layout/Hero";
import AppSection from "layout/AppSection";
import CardGrid from "components/CardGrid";
import Tags from "objects/Tags";
import Asset from "objects/Asset";

import "src/filters/truncate";

export default {
    name: "Guides",
    components: {
        Hero,
        AppSection,
        CardGrid,
        Tags,
        Asset
    },
    data: () => {
        return {
            guides: null,
            allGuides: null,
            featuredGuide: null,
            featuredGuideSlug: "michel-therrien",
            featuredGuideEntries: null,
            title: "guides multi-espèces aux compétences et expertises variées"
        };
    },
    metaInfo() {
        return {
            title: this.page.seo.title
        };
    },
    computed: {
        page() {
            let pageId = this.$route.meta.id;
            let page = this.$store.getters.getPageById(pageId);
            if (typeof page === "undefined") {
                return null;
            }
            return page;
        }
    },
    methods: {
        populateOtherGuides() {
            //const _featuredIndex = this.featuredIndex
            let _guides = this.allGuides;

            // Filter guides object to remove Michel
            //_guides = _guides.filter((guide, index) => index != _featuredIndex)

            return _guides.map(guide => ({
                ...guide,

                body: {
                    title: guide.name,
                    excerpt: this.$options.filters.truncate(guide.body, 190)
                },
                tags: guide.specialities,
                button: {
                    label: "Voir le guide",
                    to: `/guides/${guide.slug}`
                }
            }));
        },
        populateFeaturedGuideEntries() {
            /*
                const _featuredIndex = this.featuredIndex
                const featuredGuideEntries = this.$store.state.data.guides[_featuredIndex].entries

                return featuredGuideEntries.slice(0, 3).map(entry => ({
                    ...entry,
                    body: {
                        title: entry.title,
                        excerpt: entry.excerpt
                    },
                    button: {
                        label: 'Lire la suite',
                        to: entry.url
                    }
                }))
*/
        }
    },
    created() {
        this.$store.dispatch(types.LOAD_ALL_GUIDES).then(guides => {
            // console.log('VIEW::LOAD_ALL_GUIDES', guides)

            //const featuredGuideSlug = this.featuredGuideSlug

            this.allGuides = guides;
            //this.featuredIndex = guides.findIndex(q => q.slug === featuredGuideSlug)
            //this.featuredGuide = guides[this.featuredIndex]

            // Filter guides (remove featuredGuide) and populate cards (tags, button, etc)
            this.guides = this.populateOtherGuides();

            /*
                    this.$store.dispatch(types.LOAD_ENTRIES_BY_USER, featuredGuideSlug)
                        .then((entries) => {

                            // Populate Entries(adventure and post) card
                            this.featuredGuideEntries = this.populateFeaturedGuideEntries()
                        })
*/
        });
    }
};
</script>
