<template>
    <footer class="l-footer">
        <badge
            color="orange"
            icon="round-newsletter"
            class="l-footer__badge"
        />
        <div class="grid">
            <div class="grid__row">
                <div class="grid__col--12 grid__col--4@lg">
                    <mailchimp class="l-footer__mailchimp" />
                </div>
                <div class="grid__col--12 grid__col--8@lg">
                    <div class="l-footer__nav">
                        <ul class="l-footer__list">
                            <li
                                v-for="(item, i) in navMain"
                                :key="`nav-main-${i}`"
                            >
                                <router-link
                                    :to="`/${item.slug}`"
                                    :title="item.title"
                                    class="t-poster-60"
                                >{{ item.title }}</router-link>
                            </li>
                        </ul>
                        <div class="l-footer__secondary">
                            <ul class="l-footer__list">
                                <li
                                    v-for="(item, i) in navSecondary"
                                    :key="`nav-secondary-${i}`"
                                >
                                    <router-link
                                        :to="`/${item.slug}`"
                                        :title="item.title"
                                        class="t-poster-30"
                                    >{{ item.title }}</router-link>
                                </li>
                            </ul>
                            <socials class="l-footer__socials" />
                        </div>
                    </div>
                </div>
                <div class="grid__col--12">
                    <div class="l-footer__bottom">
                        <span class="l-footer__copy">&copy;&nbsp;{{ year }} Chasse Québec, Tous droits réservés.</span>
                        <span class="l-footer__mambo">
                            Design et développement web par
                            <a href="https://mambomambo.ca/" title="MamboMambo, studio de design graphique et web" class="l-footer__content">MamboMambo</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import Icon from 'objects/Icon'
    import Badge from 'objects/Badge'
    import Mailchimp from 'objects/Mailchimp'
    import Socials from 'objects/Socials'

    export default {
        name: 'AppFooter',
        components: {
            Icon,
            Badge,
            Mailchimp,
            Socials,
        },
        computed: {
            year() {
                return new Date().getFullYear()
            },
            navMain() {
                return this.$store.getters.getPagesBySlugs(['series', 'aventures', 'journal'])
            },
            navSecondary() {
                return this.$store.getters.getPagesBySlugs(['guides', 'a-propos', 'contact'])
            }
        }
    }
</script>

<style lang="scss">

.l-footer {
    --border: 2px solid #{$color-gold};
    --color-text: #{$color-gold};

    padding-top: 4em;
    color: var(--color-text);
    background-color: $color-green;

    .grid {
        // overflow: hidden;
    }
}

.l-footer__badge {
    z-index: 1;
    //top: calc(-1 * var(--size));
    right: auto;
    left: 1.5em;

    @media #{md("lg")} {
        left: 20%;
    }
}

.l-footer__nav {

    @media #{md("lg", "max")} {
        margin-top: 2em;
    }

    @media #{md("sm")} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 4em;
        padding-top: 2em;
        padding-bottom: 2em;
        border-top: var(--border);

        &:after {
            @include pseudo-el($width: 2px, $height: 100%, $bg: currentColor);
            position: absolute;
            bottom: 0;
            left: calc(50% - 2px/2);
        }
    }

    @media #{md("lg")} {
        padding-top: 0;
        padding-bottom: 4em;
        padding-left: 10%;
        border-top: 0;

        &:after {
            left: 0;
            height: calc(100% + 4em);
        }
    }
}

.l-footer__secondary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.l-footer__list {

    li {

        &:nth-child(n+2) {

            a {
                margin-top: .4em;
            }
        }
    }

    a {
        display: inline-block;

        &:after {
            @include pseudo-el($height: .06em, $bg: currentColor);
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0, 1);
            transform-origin: 100% 50%;
            transition: transform .4s $out-quint;
        }

        &:hover {

            &:after {
                transform: scale(1);
                transform-origin: 0 50%;
                transition: transform .8s $out-quint;
            }
        }
    }

    @media #{md("sm", "max")} {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
    }

    @media #{md("sm", "max")} {
        border-top: var(--border);
    }
}

.l-footer__socials {

    .o-socials__list {
        margin-top: 0;
    }

    @media #{md("sm", "max")} {
        margin-top: 0.25em;
        margin-bottom: 1.5em;
    }

    @media #{md("sm")} {
        margin-top: 2em;
        margin-bottom: -.5em;
    }
}

.l-footer__bottom {
    display: flex;
    align-items: center;
    padding-top: 2em;
    padding-bottom: 1em;
    border-top: var(--border);

    @media #{md("lg", "max")} {
        flex-direction: column;
        text-align: center;
    }

    @media #{md("lg")} {
        justify-content: space-between;
        padding-top: 1em;
        padding-bottom: 1.5em;
    }
}

.l-footer__copy {}

.l-footer__mambo {}

</style>
