<template>
    <div
        v-if="block && isReady"
        class="b-gallery"
    >
        <slider
            v-if="block.display === 'slider'"
            class="b-gallery__slider"
            ref="slider"
        >
            <div
                v-for="(image, i) in block.images"
                :key="`slide-${i}`"
                class="c-slider__slide"
            >
                <img
                    :src="image.lg2X"
                    :alt="image.title"
                    class="c-slider__img"
                    @load="refreshSlider"
                />
            </div>
        </slider>
        <div
            v-else-if="block.display === 'grid'"
            class="b-gallery__grid"
            :class="[block.images.length % 3 == 1 ? 'b-gallery__grid--odd' : 'b-gallery__grid--even' ]"
       >
            <asset
                v-if="block.images"
                v-for="(image, i) in block.images"
                :key="`image-${i}`"
                :src="image.lg2X"
                class="b-gallery__img"
            />
        </div>
    </div>
</template>

<script>
    import Asset from 'objects/Asset'
    import Slider from 'components/Slider'

    export default {

        name: 'BlockGallery',
        components: {
            Asset,
            Slider,
        },
        props: {
            block: {
                type: Object | Array
            },
        },
        data: () => ({
            isReady: false
        }),
        mounted() {
            this.isReady = true
        },
        methods: {

            refreshSlider() {
                if ( this?.$refs?.slider )
                    return this.$refs.slider.resize()
            }
        }
    }
</script>

<style lang="scss">

    .b-gallery {

        @media #{md("md")} {
            left: -7.5%;
            right: -7.5%;
            width: 115%;

            .c-slider__slide {
                width: 92%;
            }
        }
    }

    .b-gallery__grid {
        display: grid;
        grid-gap: .5em;

        @media #{md("xs")} {
            align-items: center;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .b-gallery__img {
        @include img;

        @media #{md("xs")} {

            .b-gallery__grid--even &:nth-child(3n) {
                grid-column: 1/3;
            }

            .b-gallery__grid--odd &:nth-child(3n+1) {
                grid-column: 1/3;
            }
        }
    }

</style>


