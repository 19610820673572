<template>
    <span
        class="o-frame"
        :class="className"
    >
        <icon
            class="o-frame__icon"
            :icon="(modifier == 'chroniques') ? 'pennant' : 'diamond'"
        ></icon>
        <span class="o-frame__label">
            <span>{{ label }}</span>
        </span>
    </span>
</template>

<script>
    import Icon from 'objects/Icon'

    export default {
        name: 'Frame',
        components: {
            Icon
        },
        props: {
            modifier: {
                type: String,
                default: 'diamond'
            },
            x: {
                type: String,
                default: null,
            },
            y: {
                type: String,
                default: null,
            },
            label: {
                type: String
            }
        },
        computed: {
            className() {
                let className = this.modifier ? `o-frame--${this.modifier}` : ''
                className += this.x ? ` o-frame--${this.x}` : ''
                className += this.y ? ` o-frame--${this.y}` : ''
                return className;
            }
        }
    }
</script>


<style lang="scss">

    .o-frame {
        display: inline-flex;
        z-index: 1;

        &--top {
            position: absolute;
            top: calc(-3.28em/2);
        }

        &--bottom {
            bottom: calc(-3.28em/2);
            top: calc(-3.28em/2);
        }

        &--center {

            &.o-frame--capsules {
                left: calc(50% - 5.9em/2);
            }

            &.o-frame--chroniques {
                left: calc(50% - 7em/2);
            }
        }

        &--chroniques {

            .o-frame__label {
                span {
                    text-indent: -1.65em;
                }
            }
        }
    }

    .o-frame__icon {
    }

    .o-frame__label {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-family: theme("fontFamily.heading");
        font-size: theme("fontSize.sm");
        text-transform: uppercase;

        span {
            display: inline-block;
            line-height: 1;
            padding-top: 1px;
        }
    }

</style>
