<template>
    <blockquote
        v-if="block"
        v-html="block.quote"
        class="b-quote | t-cms"
    ></blockquote>
</template>

<script>

    export default {
        name: 'BlockQuote',
        props: {
            block: {
                type: Object | Array
            },
            blockType: {
                type: String
            }
        },
    }
</script>

<style lang="scss">

    .b-quote {
        @apply font-poster leading-poster tracking-poster font-normal text-md;

        margin: 1em;
            margin-right: 0;
        padding: 0 1em 0 1em;
        border-left: 0.2em solid currentColor;

        @media #{md("lg")} {
            @apply text-lg;
            margin: 2em;
                margin-right: 0;
            padding: 0 2em 0 1em;
        }
    }

</style>


