<template>
    <ul
        v-if="typeof tags === 'object'"
        class="o-tags"
    >
        <li
            v-for="(tag, name) in tags"
            :key="name"
            class="o-tag"
            :class="className"
        >
            {{ tag.title }}
        </li>
    </ul>
    <ul
        v-else-if="typeof tags === 'array'"
        class="o-tags"
    >
        <li
            v-for="(tag, name) in tags"
            :key="name"
            class="o-tag"
            :class="className"
        >
            {{ tag }}
        </li>
    </ul>
    <span
        v-else-if="typeof tags === 'string'"
        class="o-tag"
        :class="className"
    >
        {{ tags }}
    </span>
</template>

<script>
export default {
    name: 'Tags',
    props: {
        tags: {
            type: Array | Object | String,
        },
        color: {
            type: String,
            default: 'gold',
        },
        stroke: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        className() {
            let className = `o-tag--${this.color}`
            className += this.stroke ? ' o-tag--stroke' : ''
            return className
        }
    }
}
</script>

<style lang="scss">

    .o-tags {
        margin: -.5em;

        li {
            margin: .5em;
        }
    }

    .o-tag {
        --color-text:   #{$color-light};
        --color-bg:     #{$color-gold};
        --color-border: transparent;

        &--orange {
            --color-text:   #{$color-green};
            --color-bg:     #{$color-orange};
        }

        &--green {
            --color-text:   #{$color-light};
            --color-bg:     #{$color-green};
        }

        &--light {
            --color-text:   #{$color-green};
            --color-bg:     #{$color-light};
        }

        &--stroke {
            --color-text:   var(--color-bg);
            --color-border: var(--color-bg);
            background-color: transparent;
        }

        display: inline-block;
        padding: .3em .75em;
        font-family: theme("fontFamily.heading");
        font-size: theme("fontSize.xs");
        color: var(--color-text);
        line-height: 1.15em;
        text-transform: uppercase;
        background-color: var(--color-bg);
        border: 1px solid var(--color-border);
    }

</style>
