<template>
    <div id="app">
        <div
            v-if="firstload"
            class="o-firstloader"
            :class="{ 'is-hidding' : firstloadOut }"
        >
            <span class="o-firstloader__bg"></span>
            <i class="o-firstloader__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 842 643" class="o-firstloader__svg">
                    <g class="svg-trees svg-trees--right">
                        <path d="M768,277c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C765.5,276.6,766.7,277,768,277z"/>
                        <path d="M789.1,261.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C786.6,261.4,787.8,261.8,789.1,261.8z"/>
                        <path d="M811.8,332.9c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V332.9z"/>
                        <path d="M830.6,316.1c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-1c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            c0,0,0,0,0,0v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V316.1z"/>
                        <path d="M826.4,263.4v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3S826.4,264.1,826.4,263.4z"/>
                        <path d="M792.7,226.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V225
                            C790.1,225.7,791.3,226.1,792.7,226.1z"/>
                        <path d="M752.1,207.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V199c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C749.5,207.2,750.8,207.6,752.1,207.6z"/>
                        <path d="M565.2,128.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.7
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1C562.6,128.3,563.9,128.7,565.2,128.7z"/>
                        <path d="M646.4,122.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0
                            V127c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C643.8,121.9,645,122.3,646.4,122.3z"/>
                        <path d="M636.5,100.8c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            V101c1,0.7,2.2,1.1,3.5,1.1C636,102.1,636.5,101.5,636.5,100.8z"/>
                        <path d="M735.9,121.8c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C739.5,120.3,737.9,121.8,735.9,121.8z"/>
                        <path d="M702.4,126.7c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.4,0,2.6-0.4,3.6-1.2
                            v0.6c0,0.1-0.1,0.2-0.1,0.3c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.4,0,2.6-0.4,3.6-1.2v0.6
                            c0,0.1-0.1,0.2-0.1,0.3c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.4,0,2.6-0.4,3.6-1.2v8.7
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-1.7,0-3.1-1.2-3.4-2.8v-1.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5
                            C699.9,126.3,701.1,126.7,702.4,126.7z"/>
                        <path d="M585,126.7c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C582.5,126.3,583.7,126.7,585,126.7z"/>
                        <path d="M757.9,195.4c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V181c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V195.4z"/>
                        <path d="M788.1,153.7c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5C788.6,152.5,788.1,153,788.1,153.7z"/>
                        <path d="M799.6,184.4c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V184.4z"/>
                        <path d="M769.4,241.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C766.9,241.4,768.1,241.8,769.4,241.8z"/>
                        <path d="M782.4,304.8c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            C783.7,305.3,783.1,304.8,782.4,304.8z"/>
                        <path d="M802.3,223.9c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V224c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            C802.9,222.6,802.3,223.2,802.3,223.9z"/>
                        <path d="M794.1,329.7c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1C791.6,329.3,792.8,329.7,794.1,329.7
                            z"/>
                        <path d="M818.9,351.1c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.6,3.1-3.5,3.1
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V351.1z"/>
                        <path d="M807.5,259.6c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1C807,260.9,807.5,260.3,807.5,259.6z
                            "/>
                        <path d="M814.2,271c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3V271z"/>
                        <path d="M801.3,298.3c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3V303c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C798.8,297.9,800,298.3,801.3,298.3z"/>
                        <path d="M840.8,337.8c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            C842,338.3,841.4,337.8,840.8,337.8z"/>
                        <path d="M750.4,242.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V241C747.8,241.7,749,242.1,750.4,242.1z
                            "/>
                        <path d="M773.2,211.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C770.7,210.8,771.9,211.2,773.2,211.2z"/>
                        <path d="M742,157c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            C739.5,156.6,740.7,157,742,157z"/>
                        <path d="M766.5,150.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            C770,149.4,768.4,150.9,766.5,150.9z"/>
                        <path d="M778.6,188.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3C776.4,189.2,777.6,188.8,778.6,188.1z"/>
                        <path d="M749.5,143.1c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C752.7,141.8,751.3,143.1,749.5,143.1z"
                            />
                        <path d="M724.7,130.6c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V122
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C722.1,130.2,723.3,130.6,724.7,130.6z"/>
                        <path d="M713.9,105.8c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V100
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3
                            V95c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V105.8z"/>
                        <path d="M778,131c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C781.5,129.5,779.9,131,778,131z"/>
                        <path d="M759.1,114.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C762.6,112.5,761.1,114.1,759.1,114.1z"/>
                        <path d="M541,132.8c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V132.8z"/>
                        <path d="M606.3,133.4c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C603.8,133,605,133.4,606.3,133.4z"/>
                        <path d="M598.1,109.8c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3V98
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6
                            C595.6,109.3,596.8,109.8,598.1,109.8z"/>
                        <path d="M617.8,121.1v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3
                            v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3S617.8,121.8,617.8,121.1z"/>
                        <path d="M550.7,100.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V99
                            C548.2,99.7,549.4,100.1,550.7,100.1z"/>
                        <path d="M576.3,102.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            C573.8,102.2,575,102.6,576.3,102.6z"/>
                        <path d="M628.1,134.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C625.5,134.3,626.8,134.7,628.1,134.7z"/>
                        <path d="M664.3,133c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1C661.7,132.5,662.9,133,664.3,133z"/>
                        <path d="M683.6,133.4c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C681,133,682.3,133.4,683.6,133.4z"/>
                        <path d="M673,114.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V113
                            C670.4,113.7,671.6,114.1,673,114.1z"/>
                        <path d="M656,100.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3V88
                            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5C653.6,99.7,654.7,100.1,656,100.1z"
                            />
                        <path d="M692.8,97.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            C690.2,97.3,691.4,97.7,692.8,97.7z"/>
                    </g>
                    <g class="svg-trees svg-trees--middle">
                        <path d="M595.4,346.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C592.9,346.4,594.1,346.8,595.4,346.8z"/>
                        <path d="M554.7,369.4c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V355c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-1c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V369.4z"/>
                        <path d="M505.2,308.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C508.7,307.3,507.1,308.9,505.2,308.9z"/>
                        <path d="M521.7,371.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V370C519.2,370.7,520.4,371.1,521.7,371.1z"/>
                        <path d="M566.5,298.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C570,296.6,568.5,298.1,566.5,298.1z"/>
                        <path d="M550.4,278.9c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1C553.9,277.4,552.4,278.9,550.4,278.9z
                            "/>
                        <path d="M530,241.5c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5C530.5,240.2,530,240.8,530,241.5z"/>
                        <path d="M488.4,204.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V196
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C485.9,204.2,487.1,204.6,488.4,204.6z"/>
                        <path d="M468.3,221.5c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V213c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V232
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C465.8,221.1,467,221.5,468.3,221.5z"/>
                        <path d="M458.7,278.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V271
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3
                            c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.6,3.1-3.5,3.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V278.5z"
                            />
                        <path d="M494.8,376.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C492.3,375.8,493.5,376.2,494.8,376.2z"/>
                        <path d="M482.5,404.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0
                            v-1c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C479.9,403.9,481.1,404.3,482.5,404.3z"/>
                        <path d="M508.2,410.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6C505.6,409.9,506.8,410.3,508.2,410.3z"/>
                        <path d="M425.9,215.5c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V226
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C423.4,215.1,424.6,215.5,425.9,215.5z"/>
                        <path d="M384.9,226.4c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6c1,0.7,2.1,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3
                            c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6
                            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6
                            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.5C382.4,226,383.6,226.4,384.9,226.4z"/>
                        <path d="M391.4,174.5c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V185
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C388.9,174,390.1,174.5,391.4,174.5z"/>
                        <path d="M408.5,189.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V179c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C412,187.7,410.4,189.3,408.5,189.3z"/>
                        <path d="M393.6,125.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V105c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3C392.4,124.7,392.9,125.3,393.6,125.3z"/>
                        <path d="M361.1,138.5c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6C358.6,138.1,359.8,138.5,361.1,138.5z"/>
                        <path d="M436.4,285.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6C433.9,285.2,435.1,285.6,436.4,285.6z"/>
                        <path d="M466.2,182.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C463.7,181.8,464.9,182.2,466.2,182.2z"/>
                        <path d="M551.6,329.8v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V302c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3S551.6,330.5,551.6,329.8z"/>
                        <path d="M490.5,114.9v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V134
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3C491.1,113.7,490.5,114.2,490.5,114.9z"/>
                        <path d="M456.5,121.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0
                            v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C460,120,458.5,121.5,456.5,121.5z"
                            />
                        <path d="M422.4,92.9v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3C424,96.4,422.4,94.8,422.4,92.9z"/>
                        <path d="M448.3,49.4c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V45
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C451.8,47.9,450.3,49.4,448.3,49.4z"/>
                        <path d="M470.7,43.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3V45c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C474.2,42.1,472.7,43.6,470.7,43.6z"/>
                        <path d="M453.8,167.1c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            c0,0,0,0,0,0v-1c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            C452.5,166.5,453.1,167.1,453.8,167.1z"/>
                        <path d="M612.8,343.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            S613.5,343.3,612.8,343.3z"/>
                        <path d="M611.9,378.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1C609.4,377.7,610.6,378.2,611.9,378.2
                            z"/>
                        <path d="M587.7,317.5v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3C585.5,318.6,586.7,318.2,587.7,317.5z"/>
                        <path d="M570.2,344.7c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1V344.7z"/>
                        <path d="M540,283.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C537.5,282.8,538.7,283.2,540,283.2z"/>
                        <path d="M487.3,305.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V298
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V305.5z"/>
                        <path d="M500.4,288.6c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V272c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C499.2,288,499.7,288.6,500.4,288.6z"/>
                        <path d="M528.8,333.9c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C527.5,333.3,528.1,333.9,528.8,333.9z"/>
                        <path d="M564.3,392.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V397c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C561.8,391.8,563,392.2,564.3,392.2z"/>
                        <path d="M543.1,386.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C540.6,385.8,541.8,386.2,543.1,386.2z"/>
                        <path d="M526.2,405.8c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V405.8z"/>
                        <path d="M517.4,427.5c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V419c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C514.9,427.1,516.1,427.5,517.4,427.5z"/>
                        <path d="M494.6,426.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V431
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C492,425.8,493.2,426.2,494.6,426.2
                            z"/>
                        <path d="M511.3,354.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C510,353.8,510.6,354.3,511.3,354.3z"/>
                        <path d="M540,351.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V350C537.5,350.7,538.7,351.1,540,351.1z"/>
                        <path d="M511.4,252.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3
                            c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V254
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C514.7,251.2,513.2,252.5,511.4,252.5z"/>
                        <path d="M495.1,171.7c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3
                            c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V171.7z"/>
                        <path d="M486.1,171.6c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C483.6,171.2,484.8,171.6,486.1,171.6z"/>
                        <path d="M472.2,155.9c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1V155.9z"/>
                        <path d="M474.6,102.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V91c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C478.1,101.4,476.6,102.9,474.6,102.9z"/>
                        <path d="M480,65.7c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            C480.5,64.4,480,65,480,65.7z"/>
                        <path d="M498.8,57c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C502.4,55.5,500.8,57,498.8,57z"/>
                        <path d="M491.4,240.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V232
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C488.9,240.1,490.1,240.5,491.4,240.5z"/>
                        <path d="M429.3,174.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V163c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            C432.8,173.3,431.3,174.9,429.3,174.9z"/>
                        <path d="M419,128.7c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6
                            C420.3,129.2,419.7,128.7,419,128.7z"/>
                        <path d="M438.5,93.6c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            C439,92.3,438.5,92.9,438.5,93.6z"/>
                        <path d="M407.5,91.7c0.7,0,1.3-0.6,1.3-1.3V81c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C406.2,91.2,406.8,91.7,407.5,91.7z"/>
                        <path d="M392.6,39.7c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1V39.7z"/>
                        <path d="M343,73.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V69
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C346.5,71.9,344.9,73.5,343,73.5z"/>
                        <path d="M404.3,150.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C401.8,150.3,403,150.7,404.3,150.7z"/>
                        <path d="M375.5,153.6c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V145c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C373,153.2,374.2,153.6,375.5,153.6z"/>
                        <path d="M416,252.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.6,3.1-3.5,3.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V252.6z"/>
                        <path d="M405.6,217.9c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V218c1,0.7,2.2,1.1,3.5,1.1
                            C405,219.1,405.6,218.6,405.6,217.9z"/>
                        <path d="M367.7,186.3c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V186.3z"/>
                        <path d="M379.2,112.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V112.6z"/>
                        <path d="M361.7,92.8c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3
                            c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3V100c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C365,91.5,363.5,92.8,361.7,92.8z"/>
                        <path d="M381.6,86c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3S391.7,86,391,86c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C384.8,84.7,383.4,86,381.6,86z"/>
                        <path d="M429.3,69.8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            C432.8,68.2,431.3,69.8,429.3,69.8z"/>
                        <path d="M430.2,29c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C433.7,27.4,432.1,29,430.2,29z"/>
                        <path d="M345.3,107.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.3
                            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.8-1.6,3.1-3.5,3.1c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5C342.8,107.2,344,107.6,345.3,107.6z
                            "/>
                        <path d="M338.6,130.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C336.1,130.4,337.3,130.9,338.6,130.9z"/>
                        <path d="M413.5,41.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V46c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            C412.2,40.7,412.8,41.3,413.5,41.3z"/>
                        <path d="M401.7,13.1c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V24c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V8.7
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            C405.2,11.6,403.6,13.1,401.7,13.1z"/>
                        <path d="M367.4,59c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3S377.7,59,377,59c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C370.9,57.4,369.4,59,367.4,59z"/>
                        <path d="M351,42.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C354.5,41.1,352.9,42.6,351,42.6z"/>
                        <path d="M369.3,21.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3V23c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0
                            v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V9.8c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C372.9,20.1,371.3,21.6,369.3,21.6z"/>
                        <path d="M397.8,243.6c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V235c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C395.3,243.1,396.5,243.6,397.8,243.6z"/>
                        <path d="M457.6,71.7c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3V67c0,2-1.5,3.5-3.5,3.5
                            C458.2,70.5,457.6,71,457.6,71.7z"/>
                        <path d="M516.7,78.4c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V74
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3.1-3.5,3.1c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C519.9,77,518.5,78.4,516.7,78.4z"/>
                        <path d="M437,146.8c0.7,0,1.3-0.6,1.3-1.3V136c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.6,3.1-3.5,3.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C435.8,146.2,436.3,146.8,437,146.8z"/>
                        <path d="M447.5,206.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V198c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C445,206.1,446.2,206.6,447.5,206.6z"/>
                        <path d="M478.1,286.4c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C476.8,285.8,477.4,286.4,478.1,286.4z"/>
                        <path d="M451.8,251.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V245c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5
                            C449.3,251.4,450.5,251.9,451.8,251.9z"/>
                        <path d="M465.8,329.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V329.6z"/>
                        <path d="M446.8,313.9c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C444.3,313.5,445.5,313.9,446.8,313.9z"/>
                        <path d="M354.7,165.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C352.2,165.5,353.4,165.9,354.7,165.9z"/>
                        <path d="M473.5,377.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V376
                            C471,376.7,472.2,377.1,473.5,377.1z"/>
                        <path d="M525.7,103.5C525.7,103.5,525.7,103.5,525.7,103.5l0-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C527.2,107.1,525.7,105.5,525.7,103.5z"/>
                        <path d="M489,343.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V342C486.5,342.7,487.7,343.1,489,343.1z"/>
                        <path d="M460.4,345.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1
                            C457.9,345.3,459.1,345.7,460.4,345.7z"/>
                        <path d="M505.9,136.9c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V137c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            C506.5,135.6,505.9,136.2,505.9,136.9z"/>
                        <path d="M498,99c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3
                            v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C501.5,97.4,500,99,498,99z"/>
                        <path d="M506.8,218.7c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V220
                            C508.1,219.3,507.5,218.7,506.8,218.7z"/>
                        <path d="M577.5,372.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.4,0,2.6-0.4,3.6-1.2v0.6c0,0.1-0.1,0.2-0.1,0.3c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.4,0,2.6-0.4,3.6-1.2v9.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5C575.1,371.8,576.3,372.2,577.5,372.2z"/>
                        <path d="M594.1,390.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C591.6,389.9,592.8,390.3,594.1,390.3z"/>
                        <path d="M424.7,324.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0V323C422.2,323.7,423.4,324.1,424.7,324.1z"/>
                        <path d="M415.5,298.9c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V292c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5
                            C412.9,298.5,414.1,298.9,415.5,298.9z"/>
                        <path d="M393.8,274.4c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C391.2,274,392.4,274.4,393.8,274.4z"/>
                    </g>
                    <g class="svg-trees svg-trees--bottom">
                        <path d="M705,357.7c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V357.7z"/>
                        <path d="M605.1,215.9c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C608.3,214.6,606.9,215.9,605.1,215.9z"
                            />
                        <path d="M692.1,342.2c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.6,3.1-3.5,3.1
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V342.2z"/>
                        <path d="M590.1,206.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3V208c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C593.6,205,592.1,206.6,590.1,206.6z"/>
                        <path d="M533.8,439c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C537.3,437.4,535.8,439,533.8,439z"/>
                        <path d="M557,451.2c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C560.5,449.7,559,451.2,557,451.2z"/>
                        <path d="M510.1,549.3c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V554c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C507.5,548.9,508.7,549.3,510.1,549.3z"/>
                        <path d="M546.7,488.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.3
                            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C549.9,487,548.5,488.3,546.7,488.3z"/>
                        <path d="M566.6,496.7v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3C567.1,495.4,566.6,496,566.6,496.7z"/>
                        <path d="M564.8,596.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V601c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            v-0.9C562.2,595.8,563.4,596.2,564.8,596.2z"/>
                        <path d="M526.6,584.6v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5
                            v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3S526.6,585.3,526.6,584.6z"/>
                        <path d="M543.4,546.1c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V533c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            C542.8,547.4,543.4,546.8,543.4,546.1z"/>
                        <path d="M547.8,532.2c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5C546.6,531.6,547.1,532.2,547.8,532.2z
                            "/>
                        <path d="M570.8,474.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C574.3,472.5,572.8,474.1,570.8,474.1z"/>
                        <path d="M598.3,506.7c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V501c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            C597.8,507.9,598.3,507.4,598.3,506.7z"/>
                        <path d="M617.7,491.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C615.2,491.3,616.4,491.7,617.7,491.7z"/>
                        <path d="M599.6,459.7C599.6,459.7,599.6,459.7,599.6,459.7l0-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3C601.1,463.2,599.6,461.6,599.6,459.7z"/>
                        <path d="M583.4,539.8c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C580.9,539.3,582.1,539.8,583.4,539.8z"/>
                        <path d="M607.1,530.4c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C604.6,530,605.8,530.4,607.1,530.4z"/>
                        <path d="M560.1,546.3c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V551
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C557.6,545.9,558.8,546.3,560.1,546.3z"
                            />
                        <path d="M575.3,570.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3
                            V575c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1C572.8,569.9,574,570.3,575.3,570.3z"
                            />
                        <path d="M550,579.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.6,3.1-3.5,3.1c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6C547.5,579.5,548.7,579.9,550,579.9z"/>
                        <path d="M535.4,607.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C532.8,606.8,534.1,607.2,535.4,607.2z"/>
                        <path d="M528.4,506.2c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C531.9,504.7,530.3,506.2,528.4,506.2z"/>
                        <path d="M523.8,529c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1C523.3,530.2,523.8,529.7,523.8,529z
                            "/>
                    </g>
                    <g class="svg-trees svg-trees--left">
                        <path d="M359.4,341.7v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3C361,345.2,359.4,343.7,359.4,341.7z"/>
                        <path d="M390.8,370c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C388.2,369.6,389.4,370,390.8,370z"/>
                        <path d="M311.7,420.4c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6C310.4,419.8,311,420.4,311.7,420.4z"/>
                        <path d="M278.5,350.7c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5C279.7,351.2,279.2,350.7,278.5,350.7z"/>
                        <path d="M337.3,428.1c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V428.1z"/>
                        <path d="M325.9,467.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V472c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C323.4,466.8,324.6,467.3,325.9,467.3z"/>
                        <path d="M277.1,466.7c0.7,0,1.3-0.6,1.3-1.3v-8.7c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V451c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.7C275.8,466.1,276.4,466.7,277.1,466.7z"/>
                        <path d="M248.9,556.9c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V538c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1C248.4,558.2,248.9,557.6,248.9,556.9z"/>
                        <path d="M292.6,575c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9C290,574.6,291.2,575,292.6,575z"/>
                        <path d="M257.4,590.9c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V591c1,0.7,2.2,1.1,3.5,1.1C256.8,592.1,257.4,591.6,257.4,590.9z"/>
                        <path d="M328.8,612.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-8.6
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C326.3,612.4,327.5,612.8,328.8,612.8z"/>
                        <path d="M238.9,365.7c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V348c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C242.4,364.2,240.9,365.7,238.9,365.7z"/>
                        <path d="M334.7,353.8c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1V353.8z"/>
                        <path d="M312.8,324.6c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3V326c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C316.3,323.1,314.8,324.6,312.8,324.6z"/>
                        <path d="M402.3,353.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            S403,353.3,402.3,353.3z"/>
                        <path d="M391.7,319.4c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V315
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C395.2,317.9,393.6,319.4,391.7,319.4z"/>
                        <path d="M377,347.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V332c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1V347.4z"/>
                        <path d="M384.5,414.8c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C382,414.4,383.2,414.8,384.5,414.8z"/>
                        <path d="M362.5,431.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C360,431.3,361.2,431.7,362.5,431.7z"/>
                        <path d="M347.6,458.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            C345.1,458.5,346.3,458.9,347.6,458.9z"/>
                        <path d="M322.4,511.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1
                            C321.9,512.5,322.4,512,322.4,511.3z"/>
                        <path d="M295.6,457.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C299.1,455.6,297.5,457.1,295.6,457.1z"/>
                        <path d="M289.3,478.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C286.8,477.9,288,478.3,289.3,478.3z"/>
                        <path d="M305.8,485.3c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V490c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9C303.2,484.9,304.4,485.3,305.8,485.3z"/>
                        <path d="M293.3,515.1c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            S294,515.1,293.3,515.1z"/>
                        <path d="M271.5,523.2c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.9,0-1.8-0.4-2.5-1c-0.6-0.7-1-1.5-1-2.4
                            c0,0,0-0.1,0-0.1l0-2.5c0-0.7-0.6-1.3-1.2-1.3c0,0,0,0,0,0c-0.7,0-1.2,0.6-1.3,1.2l0,2.3c0,0.1,0,0.2,0,0.2c0,0.9-0.4,1.8-1,2.5
                            c-0.7,0.6-1.5,1-2.4,1c-0.7,0-1.3,0.5-1.3,1.2c0,0.7,0.5,1.3,1.2,1.3c0.1,0,0.1,0,0.2,0c1.2,0,2.4-0.4,3.4-1.1l0,1.1
                            c0,0.9-0.4,1.8-1,2.4c-0.7,0.6-1.5,1-2.4,1c-0.7,0-1.3,0.5-1.3,1.2c0,0.7,0.5,1.3,1.2,1.3c0.1,0,0.1,0,0.2,0c1.2,0,2.4-0.4,3.3-1.1
                            l-0.1,9.5c0,0.7,0.6,1.3,1.2,1.3c0,0,0,0,0,0c0.7,0,1.2-0.6,1.3-1.2l0.1-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-0.9,0-1.8-0.4-2.5-1c-0.6-0.7-1-1.5-1-2.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.9
                            C269,522.8,270.3,523.2,271.5,523.2z"/>
                        <path d="M299.1,545.4c0,0.7,0.5,1.3,1.2,1.3c0,0,0,0,0,0c0.7,0,1.2-0.6,1.3-1.2l0.1-9.6c1,0.7,2.2,1.2,3.5,1.2
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-0.9,0-1.8-0.4-2.5-1c-0.6-0.7-1-1.5-1-2.4c0-0.1,0-0.1,0-0.2l0-0.7
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-0.9,0-1.8-0.4-2.5-1c-0.5-0.6-0.8-1.2-0.9-2l0-3
                            c0-0.7-0.5-1.3-1.2-1.3c-0.7,0-1.3,0.5-1.3,1.2l0,2.3c0,0.1,0,0.1,0,0.2c0,0.9-0.4,1.8-1,2.5c-0.7,0.6-1.5,1-2.4,1
                            c-0.7,0-1.3,0.5-1.3,1.2c0,0.7,0.5,1.3,1.2,1.3c0.1,0,0.1,0,0.2,0c1.2,0,2.4-0.4,3.4-1.1l0,0.8c0,0.1,0,0.1,0,0.2
                            c0,0.9-0.4,1.8-1,2.5c-0.7,0.6-1.5,1-2.4,1c-0.7,0-1.3,0.5-1.3,1.2c0,0.7,0.5,1.3,1.2,1.3c0.1,0,0.1,0,0.2,0c1.2,0,2.4-0.4,3.4-1.1
                            L299.1,545.4z"/>
                        <path d="M292.4,442.4c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-1.7,0-3.1-1.2-3.4-2.8v-1.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5
                            c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5C291.2,441.9,291.7,442.4,292.4,442.4z"/>
                        <path d="M352.9,326.6v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3S352.9,327.3,352.9,326.6z"/>
                        <path d="M294.9,340.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3V342c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C298.2,339.2,296.7,340.5,294.9,340.5z"
                            />
                        <path d="M281.1,323.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C284.6,321.7,283.1,323.3,281.1,323.3z"/>
                        <path d="M260.4,335c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3V341c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            C260.9,333.7,260.4,334.3,260.4,335z"/>
                        <path d="M251.1,319.9c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C248.6,319.5,249.8,319.9,251.1,319.9z"/>
                        <path d="M251.4,394.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V393
                            C248.9,393.7,250.1,394.1,251.4,394.1z"/>
                        <path d="M267.9,419c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1
                            C265.4,418.6,266.6,419,267.9,419z"/>
                        <path d="M294.3,377c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C297.8,375.5,296.2,377,294.3,377z"/>
                        <path d="M288.7,393.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            C290.3,397,288.7,395.5,288.7,393.5z"/>
                        <path d="M273.2,390c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C270.7,389.6,271.9,390,273.2,390z"/>
                        <path d="M323.3,361.6c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V353c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C320.8,361.1,322,361.6,323.3,361.6z"/>
                        <path d="M340.5,365.6v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0
                            v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3C341,364.4,340.5,364.9,340.5,365.6z"/>
                        <path d="M370.2,392.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3.1-3.5,3.1
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.2,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8V391
                            C367.7,391.7,368.9,392.1,370.2,392.1z"/>
                        <path d="M313.8,438.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3
                            v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C317.3,436.5,315.7,438.1,313.8,438.1z
                            "/>
                        <path d="M212,288c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9C209.4,287.6,210.6,288,212,288z"/>
                        <path d="M312.5,266.9c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5V267c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5C313,265.6,312.5,266.2,312.5,266.9z"/>
                        <path d="M272.3,233.7c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5C272.8,232.5,272.3,233,272.3,233.7z"/>
                        <path d="M350.6,241.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-8.6c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.5c1,0.7,2.1,1.1,3.4,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.3c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.2
                            c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C353.8,240,352.4,241.3,350.6,241.3z"/>
                        <path d="M283.1,275.5c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5C284.4,276.1,283.8,275.5,283.1,275.5z"/>
                        <path d="M240.4,269.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V259c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1C243.9,267.8,242.4,269.3,240.4,269.3z"/>
                        <path d="M204.4,264.7c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V259c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6
                            c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1C203.8,266,204.4,265.4,204.4,264.7z"/>
                        <path d="M193.1,214c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9C196.6,212.5,195.1,214,193.1,214z"/>
                        <path d="M331.8,258.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v8.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-8.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0
                            v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C335.3,256.8,333.8,258.3,331.8,258.3z"/>
                        <path d="M376.2,300.5c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            C375.7,301.8,376.2,301.2,376.2,300.5z"/>
                        <path d="M215.9,236.4c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1
                            C215.3,237.7,215.9,237.1,215.9,236.4z"/>
                        <path d="M237.2,212.7c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C240.7,211.1,239.2,212.7,237.2,212.7z"/>
                        <path d="M215,210.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C218.5,208.8,217,210.3,215,210.3z"/>
                        <path d="M289.2,217.8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5C292.4,216.5,291,217.8,289.2,217.8z"/>
                        <path d="M261.9,210.2c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            C265.4,208.6,263.9,210.2,261.9,210.2z"/>
                        <path d="M328.6,286.8c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6
                            c0,2-1.5,3.5-3.5,3.5C329.2,285.6,328.6,286.2,328.6,286.8z"/>
                        <path d="M349,275.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C352.5,274,351,275.5,349,275.5z"/>
                        <path d="M191.9,291.7c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-3.2c0-0.7-0.6-1.3-1.3-1.3
                            s-1.3,0.6-1.3,1.3v2.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.6c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c-0.2,1.7-1.7,3-3.5,3
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5
                            c1,0.7,2.1,1.1,3.4,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-1.7,0-3.1-1.2-3.4-2.8v-1.6
                            C189.4,291.3,190.6,291.7,191.9,291.7z"/>
                        <path d="M227.3,274.5c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            C226.7,275.8,227.3,275.2,227.3,274.5z"/>
                        <path d="M296.5,292.6c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1
                            c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            C297.1,291.3,296.5,291.9,296.5,292.6z"/>
                        <path d="M238.8,297c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5
                            c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1
                            C238.2,298.2,238.8,297.7,238.8,297z"/>
                        <path d="M254.9,306.1c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5
                            c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5
                            C253.6,305.6,254.2,306.1,254.9,306.1z"/>
                        <path d="M262.5,270.1c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C266,268.5,264.5,270.1,262.5,270.1z"/>
                        <path d="M250.1,234.3c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C253.6,232.8,252.1,234.3,250.1,234.3z"/>
                        <path d="M226.7,239.9c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            C225.5,239.3,226,239.9,226.7,239.9z"/>
                        <path d="M367.5,265.6c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3V267c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-1
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C371,264.1,369.5,265.6,367.5,265.6z"/>
                        <path d="M184.7,238v9.5c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3V238c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3
                            C182.5,239.1,183.7,238.7,184.7,238z"/>
                        <path d="M303.1,241c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C306.6,239.4,305.1,241,303.1,241z"/>
                        <path d="M318.9,227c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C322.5,225.5,320.9,227,318.9,227z"/>
                        <path d="M289,264.8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            C292.5,263.2,290.9,264.8,289,264.8z"/>
                        <path d="M279.7,547.8c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3V547.8z"/>
                        <path d="M266.6,556.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v1
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V555
                            C264.1,555.7,265.3,556.1,266.6,556.1z"/>
                        <path d="M266.6,612.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C264.1,611.6,265.3,612.1,266.6,612.1z"/>
                        <path d="M275.4,582.2c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.6c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-0.9
                            C272.9,581.8,274.1,582.2,275.4,582.2z"/>
                        <path d="M303.1,608.4c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3
                            c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V593c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3
                            c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.5c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3
                            s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3
                            c1.3,0,2.5-0.4,3.5-1.1V608.4z"/>
                        <path d="M312.5,627.5c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-2.6
                            c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1
                            v0.9c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.6c0,0.7,0.6,1.3,1.3,1.3
                            s1.3-0.6,1.3-1.3v-9.6c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5c0,0,0,0,0,0v-0.9
                            C310,627.1,311.2,627.5,312.5,627.5z"/>
                        <path d="M290.3,610.1c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5v-2.5c0-0.7-0.6-1.3-1.3-1.3
                            c-0.7,0-1.3,0.6-1.3,1.3v2.6c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v0.9
                            c0,2-1.5,3.5-3.5,3.5c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3c1.3,0,2.5-0.4,3.5-1.1v9.5c0,0.7,0.6,1.3,1.3,1.3
                            c0.7,0,1.3-0.6,1.3-1.3v-9.5c1,0.7,2.2,1.1,3.5,1.1c0.7,0,1.3-0.6,1.3-1.3s-0.6-1.3-1.3-1.3c-2,0-3.5-1.5-3.5-3.5V609
                            C287.8,609.7,289,610.1,290.3,610.1z"/>
                    </g>
                    <g class="svg-waves svg-waves--center">
                        <path d="M477,633.1c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C482.8,631.5,480.9,633.1,477,633.1z"/>
                        <path d="M504.2,618.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3
                            C505.5,617.7,504.9,618.3,504.2,618.3z"/>
                        <path d="M504.2,603.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C510,601.8,508,603.4,504.2,603.4z"/>
                        <path d="M504.2,588.5c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C505.5,588,504.9,588.5,504.2,588.5z"/>
                        <path d="M477,573.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C482.8,572.1,480.9,573.7,477,573.7z"/>
                        <path d="M477.1,558.8c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3S477.8,558.8,477.1,558.8z"/>
                        <path d="M477,543.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C482.8,542.3,480.9,543.9,477,543.9z"/>
                        <path d="M504.2,529c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C505.5,528.4,504.9,529,504.2,529z"/>
                        <path d="M504.2,514.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3
                            S504.9,514.2,504.2,514.2z"/>
                        <path d="M504.2,499.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C510,497.7,508,499.3,504.2,499.3z"/>
                        <path d="M531.4,484.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C532.6,483.8,532.1,484.4,531.4,484.4z"/>
                        <path d="M531.3,469.5c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3
                            s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3
                            s-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C537.1,467.9,535.1,469.5,531.3,469.5z"/>
                        <path d="M531.4,454.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3
                            C532.6,454.1,532.1,454.7,531.4,454.7z"/>
                        <path d="M449.9,439.8c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C455.7,438.2,453.7,439.8,449.9,439.8z"/>
                        <path d="M449.9,424.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C455.7,423.3,453.7,424.9,449.9,424.9z"/>
                        <path d="M449.9,410.1c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C455.7,408.5,453.7,410.1,449.9,410.1z"/>
                        <path d="M449.9,395.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C455.7,393.6,453.7,395.2,449.9,395.2z"/>
                        <path d="M449.9,380.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3
                            C451.1,379.7,450.6,380.3,449.9,380.3z"/>
                        <path d="M422.7,365.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C428.6,363.8,426.6,365.4,422.7,365.4z"/>
                        <path d="M422.7,350.5c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C428.6,348.9,426.6,350.5,422.7,350.5z"/>
                    </g>
                    <g class="svg-waves svg-waves--left">
                        <path d="M192.2,574.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C198.1,572.6,196.1,574.2,192.2,574.2z"/>
                        <path d="M219.4,559.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3
                            c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C220.7,558.7,220.1,559.3,219.4,559.3z"/>
                        <path d="M219.4,544.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C225.2,542.8,223.2,544.4,219.4,544.4z"/>
                        <path d="M246.6,529.6c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3S247.3,529.6,246.6,529.6z"/>
                        <path d="M246,514.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C251.9,513.3,249.9,514.9,246,514.9z"/>
                        <path d="M273.2,500c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3
                            c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C274.5,499.4,273.9,500,273.2,500z"/>
                        <path d="M273.2,485.1c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3
                            c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C274.5,484.5,273.9,485.1,273.2,485.1z"/>
                        <path d="M246,470.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C251.9,468.6,249.9,470.2,246,470.2z"/>
                        <path d="M246,455.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C251.9,453.8,249.9,455.3,246,455.3z"/>
                        <path d="M246.1,440.5c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c0.7,0,1.3,0.6,1.3,1.3S246.8,440.5,246.1,440.5z"/>
                        <path d="M246.1,425.6c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c0.7,0,1.3,0.6,1.3,1.3C247.4,425,246.8,425.6,246.1,425.6z"/>
                        <path d="M218.9,410.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C224.7,409.1,222.7,410.7,218.9,410.7z"/>
                        <path d="M218.9,395.8c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3
                            c-3,0-4.4,1.1-6,2.4C224.7,394.2,222.7,395.8,218.9,395.8z"/>
                        <path d="M219,380.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3S219.7,380.9,219,380.9z"/>
                        <path d="M219,366c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C220.2,365.5,219.7,366,219,366z"/>
                        <path d="M218.9,351.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3s-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C224.7,349.6,222.7,351.2,218.9,351.2z"/>
                        <path d="M218.9,336.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C224.7,334.7,222.7,336.3,218.9,336.3z"/>
                        <path d="M218.9,321.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3
                            s0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3
                            c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4C224.7,319.8,222.7,321.4,218.9,321.4z"/>
                    </g>
                    <g class="svg-waves svg-waves--right">
                        <path d="M683.6,462.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C689.5,461.1,687.5,462.7,683.6,462.7z"/>
                        <path d="M710.8,447.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C716.6,446.3,714.6,447.9,710.8,447.9z"/>
                        <path d="M738,433c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3S738.7,433,738,433z"/>
                        <path d="M765.1,418.1c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3
                            C766.4,417.5,765.8,418.1,765.1,418.1z"/>
                        <path d="M765.1,403.2c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-2.9,0-4.3,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1-0.8-2-1.6-3.4-2c-0.7-0.2-1-0.9-0.8-1.6c0.2-0.7,0.9-1,1.6-0.8c1.8,0.6,3,1.5,4.1,2.5c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3
                            c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3
                            c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C771,401.6,769,403.2,765.1,403.2z"/>
                        <path d="M792.3,388.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-2.9,0-4.3,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1-0.8-2-1.6-3.4-2c-0.7-0.2-1-0.9-0.8-1.6
                            c0.2-0.7,0.9-1,1.6-0.8c1.8,0.6,3,1.5,4.1,2.5c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C793.5,387.8,793,388.4,792.3,388.4z"/>
                        <path d="M683,373.5C683,373.5,683,373.5,683,373.5c-3.4-0.2-5.3-1.7-6.9-3c-1.6-1.3-3.1-2.5-6-2.5c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1-0.8-2-1.6-3.4-2c-0.7-0.2-1-0.9-0.8-1.6c0.2-0.7,0.9-1,1.6-0.8c1.8,0.6,3,1.5,4.1,2.5c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.5,1.2,2.9,2.3,5.4,2.4c0.7,0,1.2,0.6,1.2,1.3C684.2,372.9,683.7,373.5,683,373.5z"/>
                        <path d="M792.3,373.5c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-2.9,0-4.3,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-2.9,0-4.4,1.1-6,2.4c-1.3,1-2.7,2.2-4.9,2.7c-0.7,0.2-1.4-0.2-1.5-0.9s0.2-1.4,0.9-1.5
                            c1.6-0.4,2.7-1.3,3.9-2.2c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c0.7,0,1.3,0.6,1.3,1.3c0,0.7-0.6,1.3-1.3,1.3c-3,0-4.4,1.1-6,2.4
                            C798.1,371.9,796.1,373.5,792.3,373.5z"/>
                        <path d="M656.5,358.6c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1-0.8-2-1.6-3.4-2c-0.7-0.2-1-0.9-0.8-1.6c0.2-0.7,0.9-1,1.6-0.8c1.8,0.6,3,1.5,4.1,2.5
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3,0,4.8,1,5.9,1.8c0.6,0.4,0.7,1.2,0.3,1.7c-0.4,0.6-1.2,0.7-1.7,0.3c-1-0.7-2.3-1.3-4.5-1.3
                            c-3,0-4.4,1.1-6,2.4C662.3,357,660.3,358.6,656.5,358.6z"/>
                        <path d="M765.1,358.6c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-2.9,0-4.3,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-1.6,0-2.6,0.4-3.4,0.7c-0.6,0.3-1.4-0.1-1.6-0.7c-0.3-0.6,0.1-1.4,0.7-1.6
                            c0.9-0.4,2.3-0.8,4.3-0.8c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3
                            c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.4,1.1,2.6,2,4.6,2.3c0.7,0.1,1.2,0.7,1,1.4
                            c-0.1,0.7-0.7,1.1-1.4,1c-2.7-0.4-4.3-1.7-5.8-2.9c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4C771,357,769,358.6,765.1,358.6z"/>
                        <path d="M656.5,343.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1-0.8-2-1.6-3.4-2c-0.7-0.2-1-0.9-0.8-1.6c0.2-0.7,0.9-1,1.6-0.8c1.8,0.6,3,1.5,4.1,2.5
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c0.8,0.7,1.6,1.3,2.6,1.8c0.6,0.3,0.9,1,0.6,1.7c-0.3,0.6-1,0.9-1.7,0.6
                            c-1.3-0.6-2.2-1.3-3.1-2.1c-1.6-1.3-3-2.4-6-2.4c-3,0-4.4,1.1-6,2.4C662.3,342.1,660.3,343.7,656.5,343.7z"/>
                        <path d="M765.1,343.7c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-2.9,0-4.3,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-0.6,0.5-1.2,1-1.9,1.4c-0.6,0.4-1.4,0.2-1.7-0.4
                            c-0.4-0.6-0.2-1.4,0.4-1.7c0.6-0.4,1.1-0.8,1.7-1.2c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c2.9,0,4.3-1.1,5.9-2.4
                            c0.3-0.2,0.5-0.4,0.8-0.6c0.6-0.4,1.3-0.3,1.8,0.2c0.4,0.6,0.3,1.3-0.2,1.8c-0.3,0.2-0.5,0.4-0.7,0.6
                            C770.9,342.1,768.9,343.7,765.1,343.7z"/>
                        <path d="M765.1,328.8c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-2.9,0-4.3,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4s-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-0.8,0-1.6,0.1-2.4,0.3c-0.7,0.2-1.4-0.2-1.5-0.9c-0.2-0.7,0.2-1.4,0.9-1.5c1-0.3,2-0.4,3-0.4
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c0.6,0,1.2-0.1,1.7-0.1
                            c0.7-0.1,1.3,0.4,1.4,1.1c0.1,0.7-0.4,1.3-1.1,1.4C766.6,328.8,765.9,328.8,765.1,328.8z"/>
                        <path d="M738,314c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-2.9,0-4.3,1.1-5.9,2.4c-1.1,0.9-2.2,1.8-3.8,2.3
                            c-0.7,0.2-1.4-0.1-1.6-0.7c-0.2-0.6,0.1-1.4,0.7-1.6c1.2-0.4,2.1-1.2,3.1-1.9c1.7-1.4,3.7-3,7.5-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.9,0,5.9,1.6,7.6,3c0.6,0.5,1.2,1,1.9,1.4c0.6,0.4,0.8,1.1,0.4,1.7
                            c-0.4,0.6-1.1,0.8-1.7,0.4c-0.8-0.5-1.5-1-2.2-1.6c-1.6-1.3-3.1-2.5-6.1-2.5c-3,0-4.4,1.1-6,2.5C743.8,312.4,741.8,314,738,314z"/>
                        <path d="M738,299.1c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1-0.8-1.9-1.5-3.2-2c-0.7-0.2-1-1-0.8-1.6c0.2-0.7,0.9-1,1.6-0.8c1.7,0.6,2.8,1.5,3.9,2.4c1.6,1.3,3,2.4,6,2.4
                            c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.7,0,4.1-1,5.6-2.1c0.5-0.4,1.3-0.3,1.8,0.2c0.4,0.5,0.3,1.3-0.2,1.8
                            C743.4,297.7,741.5,299.1,738,299.1z"/>
                        <path d="M738,284.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-2.6,0-4.1,0.9-5.4,2c-0.5,0.4-1.3,0.3-1.8-0.2c-0.4-0.5-0.3-1.3,0.2-1.8c1.6-1.2,3.5-2.5,7-2.5
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.8,0,1.5-0.1,2.3-0.3
                            c0.7-0.2,1.4,0.2,1.5,0.9c0.2,0.7-0.2,1.4-0.9,1.5C740,284.1,739,284.2,738,284.2z"/>
                        <path d="M738,269.3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-0.4,0-0.8,0-1.3-0.1c-0.7-0.1-1.1-0.8-1-1.5
                            c0.1-0.7,0.8-1.1,1.5-1c0.2,0,0.5,0,0.8,0c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.8,0,1.5-0.1,2.3-0.3c0.7-0.2,1.4,0.2,1.5,0.9c0.2,0.7-0.2,1.4-0.9,1.5
                            C740,269.2,739,269.3,738,269.3z"/>
                        <path d="M710.8,254.4c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5
                            c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-0.6,0-1.2,0.1-1.8,0.1c-0.7,0.1-1.3-0.4-1.4-1.1c-0.1-0.7,0.4-1.3,1.1-1.4c0.6-0.1,1.3-0.1,2-0.1
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.5,3c1.4,1.1,2.6,2.1,4.8,2.4c0.7,0.1,1.2,0.7,1.1,1.4c-0.1,0.7-0.7,1.2-1.4,1.1c-2.9-0.4-4.6-1.7-6-2.9
                            c-1.6-1.3-3-2.4-6-2.4c-3,0-4.4,1.1-6,2.5C716.6,252.8,714.6,254.4,710.8,254.4z"/>
                        <path d="M710.8,239.5c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-2.9,0-4.4,1.1-6,2.4c-0.9,0.7-1.8,1.4-2.9,2c-0.6,0.3-1.4,0.1-1.7-0.6c-0.3-0.6-0.1-1.4,0.6-1.7
                            c0.9-0.5,1.7-1.1,2.4-1.7c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.5c1.7-1.4,3.7-3,7.4-3c0,0,0,0,0,0c0.7,0,1.2,0.6,1.3,1.2c0,0.7-0.5,1.3-1.2,1.3
                            c-2.8,0-4.2,1.1-5.8,2.4C716.6,237.9,714.6,239.5,710.8,239.5z"/>
                        <path d="M575.1,224.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.8,0,4.2-1,5.9-2.4c0.5-0.4,1.3-0.4,1.8,0.2c0.4,0.5,0.4,1.3-0.2,1.8
                            C580.7,223.3,578.7,224.7,575.1,224.7z"/>
                        <path d="M710.8,224.7c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.9-1.6-7.7-3.1l-0.5-0.4c-0.5-0.4-0.6-1.2-0.2-1.8c0.4-0.5,1.2-0.6,1.8-0.2
                            l0.6,0.5c1.7,1.4,3.1,2.5,6.1,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.5c1.7-1.4,3.7-3,7.4-3c0,0,0,0,0,0c0.7,0,1.2,0.6,1.3,1.2
                            c0,0.7-0.5,1.3-1.2,1.3c-2.8,0-4.2,1.1-5.8,2.4C716.6,223.1,714.6,224.7,710.8,224.7z"/>
                        <path d="M575.1,209.8c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c1.7,0,2.8-0.4,3.5-0.7c0.6-0.3,1.4,0,1.7,0.6c0.3,0.6,0,1.4-0.6,1.7C578.2,209.5,576.8,209.8,575.1,209.8z"/>
                        <path d="M710.8,209.8c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-2.8,0-4.5-0.9-5.8-1.7c-0.6-0.4-0.8-1.1-0.4-1.7c0.4-0.6,1.1-0.8,1.7-0.4
                            c1.1,0.7,2.4,1.3,4.5,1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.5c1.7-1.4,3.7-3,7.4-3c0,0,0,0,0,0c0.7,0,1.2,0.6,1.3,1.2c0,0.7-0.5,1.3-1.2,1.3
                            c-2.8,0-4.2,1.1-5.8,2.4C716.6,208.2,714.6,209.8,710.8,209.8z"/>
                        <path d="M575.1,194.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            s-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4
                            c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            s4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3
                            c1.8,0,3.2,0.3,4.6,1c0.6,0.3,0.9,1.1,0.6,1.7c-0.3,0.6-1.1,0.9-1.7,0.6c-1.1-0.5-2.1-0.8-3.5-0.8c-3,0-4.4,1.1-6,2.5
                            C580.9,193.3,578.9,194.9,575.1,194.9z"/>
                        <path d="M738,194.9c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-1.5,0-2.8,0.3-3.9,1c-0.6,0.4-1.4,0.2-1.7-0.4
                            c-0.4-0.6-0.2-1.4,0.4-1.7c1.5-0.9,3.2-1.3,5.2-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3
                            c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c0.7,0,1.3,0.6,1.3,1.3C739.2,194.4,738.7,194.9,738,194.9z"/>
                        <path d="M738,180c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3
                            c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.9,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3s5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4
                            c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5
                            c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3
                            c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.4-1.1,6-2.4c0.8-0.7,1.7-1.4,2.8-1.9c0.6-0.3,1.4-0.1,1.7,0.5
                            c0.3,0.6,0.1,1.4-0.5,1.7c-0.9,0.4-1.6,1-2.4,1.6C743.8,178.4,741.8,180,738,180z"/>
                        <path d="M710.8,165.2c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3
                            c-3.9,0-5.8-1.6-7.6-3c-1.6-1.3-3-2.4-5.9-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5
                            c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.5c-1.7,1.4-3.7,3-7.5,3c-3.8,0-5.8-1.6-7.6-3
                            c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4c-1.8,1.4-3.7,3-7.6,3c-3.8,0-5.8-1.6-7.6-3c-1.6-1.3-3.1-2.4-6-2.4c-3,0-4.4,1.1-6,2.4
                            c-1.8,1.4-3.7,3-7.6,3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,5.9-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3c1.6,1.3,3.1,2.4,6,2.4c3,0,4.4-1.1,6-2.4
                            c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.5,3c1.6,1.3,3.1,2.5,6,2.5c3,0,4.4-1.1,6-2.4c1.8-1.4,3.7-3,7.6-3c3.8,0,5.8,1.6,7.6,3
                            c1.6,1.3,3.1,2.4,6,2.4c2.9,0,4.3-1.1,6-2.4c1.4-1.1,3-2.4,5.7-2.8c0.7-0.1,1.3,0.4,1.4,1c0.1,0.7-0.4,1.3-1,1.4
                            c-2,0.3-3.1,1.2-4.5,2.3C716.6,163.6,714.7,165.2,710.8,165.2z"/>
                    </g>
                </svg>
            </i>
        </div>
        <app-base>
            <router-view />
        </app-base>
    </div>
</template>

<script>

window.loaderStart=new Date()

import './assets/scss/styles.scss'
import AppBase from 'layout/AppBase'

import Variables from 'src/mixins/variables';

export default {
    components: {
        AppBase
    },
    mixins: [ Variables ],
    data: () => ({
        firstload: true,
        firstloadOut: false,
    }),
    metaInfo() {
        return {
            titleTemplate: `%s`
        }
    },
    created() {
        // Browser and OS classes
        document.documentElement.classList.add(`is-${this.browser.os.replace(' ', '-').toLowerCase()}`)
        document.documentElement.classList.add(`is-${this.browser.name}`)

        this.$store.dispatch('loader/startLoad', null, { root: true });
    },
    mounted() {

        // Firstload animation out
        const unwatch = this.$store.watch(() => this.$store.getters['loader/loadCount'], (lastLoad) => {
            if(lastLoad === 1) {

                let delay = new Date()
                delay = (delay - window.loaderStart)
                delay = delay > 4000 ? 0 : (4000 - delay)

                // Remove Loader
                setTimeout(() => {
                    this.$store.dispatch('loader/endLoad', null, { root: true });
                    this.firstloadOut = true
                    setTimeout(() => {
                        this.firstload = false
                        unwatch()
                    }, 600);
                }, delay);
            }
        })
    }
}
</script>

<style lang="scss">

    .o-firstloader {
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 100%;

        &.is-hidding {

            .o-firstloader__bg {
                opacity: 0;
                transition: opacity .4s $out-sine .2s;
            }

            .o-firstloader__icon {
                opacity: 0;
                transition: opacity .2s ease-out;
            }
        }

        &:not(.is-hidding) + .l-wrapper {
            height: 100vh;
            overflow: hidden;
        }
    }


    .o-firstloader__bg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color-green;
    }

    .o-firstloader__icon {
        position: absolute;
        bottom: -5.5vmax;
        left: -7vmax;
        display: block;
        overflow: hidden;
    }

    .o-firstloader__svg {
        --icon-size: 75vmax;

        display: block;
        width: var(--icon-size);
        height: calc(643/842 * var(--icon-size));
        fill: $color-orange;

        g {
            --delay: 0s;
        }

        .svg-waves {

            path {
                opacity: 0;
                animation: anim-svg-wave 4s ease-in-out infinite;

                @for $i from 1 through 30 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        animation-delay: calc(var(--delay) + #{$i/(2 * 30)}s)
                    }
                }
            }
        }

        .svg-trees {

            path {
                opacity: 0;
                animation: anim-svg-wave 4s ease-in-out infinite;

                @for $i from 1 through 100 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        animation-delay: calc(var(--delay) + #{$i/(3 * 100)}s)
                    }
                }
            }
        }

        .svg-waves--center   { --delay: 0s   }
        .svg-trees--left     { --delay: .2s   }
        .svg-waves--left     { --delay: .4s  }
        .svg-trees--middle   { --delay: .6s  }
        .svg-waves--right    { --delay: .8s  }
        .svg-trees--bottom   { --delay: 1s   }
        .svg-trees--right    { --delay: 1.2s }

        @media #{md("lg")} {
            --icon-size: 60vmax;
        }
    }

    @keyframes anim-svg-wave {
        0% {
            opacity: 0;
            transform: translate(0, 1%);
        }
        10% {
            opacity: 1;
            transform: translate(0);
        }
        60% {
            opacity: 1;
            transform: translate(0);
        }
        67% {
            opacity: 0;
            transform: translate(0, -.5%);
        }
        100% {
            opacity: 0;
            transform: translate(0, -.5%);
        }
    }
</style>
