<template>
    <i :class="`o-icon o-icon--${icon}`">
        <svg :class="`svg-${icon}`">
            <title v-if="title">{{ title }}</title>
            <use v-bind:xlink:href="`/dist/svg/sprite.svg#svg-${icon}`"></use>
        </svg>
    </i>
</template>

<script>

export default {
    name: 'Icon',
    props: {
        icon: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        }
    }
}

</script>
