<template>
    <hr
        v-if="block"
        :class="[
            `o-spacer`,
            `--size-${block.size}`
        ]"
    >
</template>

<script>
    export default {
        name: 'BlockSpacer',
        props: {
            block: {
                type: Object | Array
            },
            blockType: {
                type: String
            },
            classes: {
                type: String
            }
        }
    }
</script>

<style lang="scss">
</style>
