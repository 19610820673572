<template>
    <header
        class="l-header"
        :class="{ 'is-reveal' : reveal }"
    >
        <nav class="l-header__inner">
            <router-link
                to="/"
                class="l-header__logo"
                title="Accueil"
            >
                <icon icon="logo-circle-inner" />
                <icon icon="logo-circle-outer" />
            </router-link>


            <ul class="l-nav">
                <li
                    v-for="item in nav"
                    :key="item.slug"
                    class="l-nav__item">
                    <router-link
                        :to="`/${item.slug}`"
                        :title="item.title"
                        class="l-nav__link"
                    >
                        {{ item.title }}
                    </router-link>
                </li>
            </ul>
        </nav>
    </header>
</template>

<script>
    import Icon from 'objects/Icon'

    export default {
        name: 'AppHeader',
        components: {
            Icon
        },
        computed:{
            reveal() {
                return this.$store.getters['loader/isEnding'] || this.$store.getters['loader/isEnded']
            },
            nav() {
                return this.$store.getters.getPagesBySlugs(['series', 'aventures', 'journal', 'guides'])
            }
        },
    }
</script>

<style lang="scss">

    .l-header {
        color: var(--color-header, #{$color-green});

        z-index: 10;
        transition: color .4s ease-out .2s;

        @media #{md("md", "max")} {
            display: none;
            visibility: hidden;
        }

        @media #{md("md")} {

            .l-header__logo {
                opacity: 0;
                transform: rotateY(60deg) scale(.6, .8);
                will-change: transform, opacity;
            }

            .l-nav__item {
                opacity: 0;
                will-change: transform, opacity;

                &:nth-child(1),
                &:nth-child(2) {
                    transform-origin: 100% 50%;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    transform-origin: 0 50%;
                }

                &:nth-child(1) {
                    transform: translate(1em, 0) scale(.8);
                }

                &:nth-child(2) {
                    transform: translate(.75em, 0) scale(.9);
                }

                &:nth-child(3) {
                    transform: translate(-.75em, 0) scale(.9);
                }

                &:nth-child(4) {
                    transform: translate(-1em, 0) scale(.8);
                }
            }

            &.is-reveal {

                .l-header__logo {
                    opacity: 1;
                    transform: rotateY(0) scale(1);
                    transition: all .3s $out-quad;
                }

                .l-nav__item {
                    opacity: 1;
                    transform: translate(0) scale(1);

                    &:nth-child(2),
                    &:nth-child(3) {
                        transition: all .3s $out-quad .2s;
                    }

                    &:nth-child(1),
                    &:nth-child(4) {
                        transition: all .3s $out-quad .3s;
                    }
                }
            }
        }
    }

    .l-header__inner {
        @apply flex items-center justify-center;
        width: 100%;
        height: $header-height;
    }

    .l-header__logo {
        position: absolute;
        z-index: 10;
        top: calc(50% - 7em/2);
        left: calc(50% - 7em/2);
        width: 7em;
        height: 7em;

        .o-icon {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: inherit;
            height: inherit;
            transition: transform .2s ease-out;
        }

        svg {
            width: 100%;
            height: 100%;
        }

        &:hover {

            .o-icon {
                transition: transform .3s ease-out;
            }

            .o-icon--logo-circle-inner {
                transform: scale(.95) translate(0);
            }

            .o-icon--logo-circle-outer {
                transform: scale(1.05);
            }
        }
    }



    /*----------  Header Navigation  ----------*/


    .l-nav {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        grid-gap: 7vw;

        &:before {
            content: "";
            display: block;
            grid-column: 3;
        }

        @screen lg {
            grid-gap: 10vw;
        }
    }

    .l-nav__item {
        @apply block text-center;
    }

    .l-nav__link {
        @apply inline-block font-heading text-md;
        padding-top: .2em;
        padding-bottom: .2em;
        user-select: none;

        &:before,
        &:after {
            @include pseudo-el($height: 2px, $bg: currentcolor);
            position: absolute;
            top: 100%;
            left: 0;
            transform: scale(0, 1) translate(0, 0);
        }

        &:before {
            transition: transform .2s ease-out .1s;
        }

        &:after {
            transition: transform .15s ease-in;
        }

        &:hover {

            &:after,
            &:before {
               transform: scale(1);
                transition: transform .3s $out-quint;
            }
        }

        &.is-active {

            &:before {
                transform: scale(1);
                transition: transform .1s ease-out;
            }

            &:after {
                transition: transform .2s ease-out;
                transform: scale(.8, 1) translate(0, .4em);
            }
        }
    }

</style>
