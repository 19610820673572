import debounce from 'lodash/debounce';

import { detect } from 'detect-browser';
const browser = detect();

export default {
    data: () => ({
        W: {
            w: window.innerWidth,
            h: window.innerHeight,
        },
        mainWidth: 0,
        sidebarWidth: 0,
        browser: null,
    }),
    created() {
        this.browser = browser
    },
    mounted() {

        const $sidebar = document.querySelector('.l-menu__sidebar')

        const set = () => {
            // Define window vars
            this.W = {
                w: window.innerWidth,
                h: window.innerHeight,
            }

            this.sidebarWidth = this.W.w > 767 ? $sidebar.offsetWidth : 0;

            this.mainWidth = this.W.w - this.sidebarWidth;

            // Check mediaquery for horizontal layout
            if(window.matchMedia(this.horizontalBreakpoint).matches) {
                this.isHorizontal = true
            } else {
                this.isHorizontal = false
            }
        }

        // Set size
        set()

        // Window events
        window.addEventListener('resize', debounce(set, 100));
    }
}
