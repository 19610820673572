import Vue from 'vue'
import types from 'store/types'
import apiMethods from 'services/api/methods'

import merge from 'lodash/merge'

const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
    count: initData.count || {},
    posts: [],
    series: [],
    adventures: [],
    //authors: [],
    //guides: [],
    users: [],
    hunters: [],
    settings: [],
}

// getters, make function easy to access by vue
//instead
const getters = {

    // Post
    getPostByIdx:     state => idx   => state.posts.find(q => q.idx === idx),
    getPostByUid:     state => uid   => state.posts.find(q => q.uid === uid),
    getPostBySlug:    state => slug  => state.posts.find(q => q.slug === slug),

    // Posts
    getPostsByAuthor:  state => id   => state.posts.filter(q => q.authorId === id),

    // Serie
    getSerieByIdx:  state => idx  => state.series.find(q => q.idx === idx),
    getSerieByUid:  state => uid  => state.series.find(q => q.uid === uid),
    getSerieBySlug: state => slug => state.series.find(q => q.slug === slug),

    // Episode
    getEpisodeNumber:  state => episode => state.series.find(s => s.id === parseInt(episode.serie.id)).episodes.findIndex(e => parseInt(e.id) === episode.id) +1,

    // Adventure
    getAdventureByIdx:  state => idx  => state.adventures.find(q => q.idx === idx),
    getAdventureByUid:  state => uid  => state.adventures.find(q => q.uid === uid),
    getAdventureBySlug: state => slug => state.adventures.find(q => q.slug === slug),

    // Adventures
    getAdventuresByAuthor:  state => id => state.adventures.filter(q => q.authorId === id),

    // User
    getUserByIdx:      state => idx       => state.users.find(q => q.idx === idx),
    getUserById:       state => id        => state.users.find(q => q.id === id),
    getUserBySlug:     state => slug      => state.users.find(q => q.slug === slug),
    getUserByUsername: state => username  => state.users.find(q => q.username === username),
    getUserByUid:      state => uid       => state.users.find(q => q.uid === uid),

    getUsersByType:    state => type      => state.users.filter(q => q.type === type),

/*
    // Author
    getAuthorByIdx:      state => idx       => state.authors.find(q => q.idx === idx),
    getAuthorById:       state => id        => state.authors.find(q => q.id === id),
    getAuthorBySlug:     state => slug      => state.authors.find(q => q.slug === slug),
    getAuthorByUsername: state => username  => state.authors.find(q => q.username === username),
    getAuthorByUid:      state => uid       => state.authors.find(q => q.uid === uid),
*/

}

// actions
const actions = {

    //
    //
    // ENTRIES
    [types.LOAD_ENTRIES_BY_USER](store, {slug, user_type}) {

        if ( typeof user_type === 'undefined' )
            user_type = 'guide'

        //const DISPACTCH = (user_type === 'author') ? types.LOAD_AUTHOR_BY_SLUG : types.LOAD_GUIDE_BY_SLUG

        // Load user data
        return store.dispatch(types.LOAD_USER_BY_SLUG, {slug: slug, user_type: user_type})
            .then((user) => {

                // TODO Simplify this
                //if ( user_type === 'guide' ) {

                    // Get `guide` index from store
                    let idx = store.state.users.findIndex(user => user.slug == slug)

                    // If `entries` prop exists in our guide, skip.
                    if ( store.state.users[idx].entries.length > 0 )
                        return Promise.resolve(user)

/*
                } else if ( user_type === 'author' ) {

                    // Get `guide` index from store
                    let idx = store.state.authors.findIndex(user => user.slug == slug)

                    // If `entries` prop exists in our guide, skip.
                    if ( store.state.authors[idx].entries.length > 0 )
                        return Promise.resolve(user)

                }
*/

                // Load post from guide
                return new Promise((resolve, reject) => {

                    //let action
                    let userId

                    const _adventures = store.state.adventures
                    const _posts = store.state.posts

                    apiMethods.getEntriesByAuthor(user.id)
                        .then(entriesData => {
                            entriesData.forEach(entry => {

                                userId = user.id

                                // If the entry is already in the store, copy it
                                if ( _adventures.find(a => a.id === entry.id) || _posts.find(a => a.id === entry.id)) {
                                    store.commit(types.LOAD_ENTRIES_BY_USER, {id:userId, data:entry})

                                // If not, do the dispatch
                                } else {

                                    // Set action by section type
                                    let action = (entry.section == 'adventure')
                                                ? types.LOAD_ADVENTURE_BY_SLUG
                                                : types.LOAD_POST_BY_SLUG
                                    //
                                    // Keep this to debug when more than 12 entries of each
                                    // section type will be available to look if this part works properly
                                    // console.log('LOAD_ENTRIES_BY_USER::ENTRY NOT IN STORE', action, slug)
                                    store.dispatch(action, entry.slug)
                                        .then(results => {
                                            store.commit(types.LOAD_ENTRIES_BY_USER, {id:userId, data:results})
                                        })
                                }

                                resolve(entriesData)
                            })
                        })
                })

                resolve(user)
            })
    },

    // POSTS
    [types.LOAD_POSTS](store, {limit, offset}) {
        limit = typeof limit === 'undefined' ? 12 : limit
        offset = typeof offset === 'undefined' ? 0 : offset

        // If the total entries is equal to the state lenght, don't call the API
        if ( store.state.posts.length === store.state.count.posts )
            return Promise.resolve(store.state.posts)

        return new Promise((resolve, reject) => {

            apiMethods.getPosts(limit, offset)
                .then(postsData => {

                    // Init the mutation only if guides arent fully loaded
                    if ( store.state.posts.length < postsData.length ) {
                        store.commit(types.LOAD_POSTS, postsData)
                    }

                    resolve(postsData)
                })
        })
    },

    // POST
    [types.LOAD_POST_BY_SLUG](store, slug) {

        let existingPost = store.getters.getPostBySlug(slug)

        if (existingPost && existingPost.isSingleFetched) {
          return Promise.resolve(existingPost)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getPost(slug)
                .then(postData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof postData !== 'undefined' ) {
                        let post = merge({}, postData, {isSingleFetched: true})
                        store.commit(types.LOAD_POST_BY_SLUG, post)
                        resolve(post)
                    }
                })
        })

    },

    // SERIE
    [types.LOAD_SERIE_BY_SLUG](store, slug) {

        let existingSerie = store.getters.getSerieBySlug(slug)

        if (existingSerie && existingSerie.isSingleFetched) {
          return Promise.resolve(existingSerie)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getSerie(slug)
                .then(serieData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof serieData !== 'undefined' ) {
                        let serie = merge({}, serieData, {isSingleFetched: true})
                        store.commit(types.LOAD_SERIE_BY_SLUG, serie)
                        resolve(serie)
                    }
                })
        })

    },


    // ADVENTURES
    [types.LOAD_ADVENTURES](store, {limit, offset}) {
        limit = typeof limit === 'undefined' ? 12 : limit
        offset = typeof offset === 'undefined' ? 0 : offset

        // If the total entries is equal to the state lenght, don't call the API
        if ( store.state.adventures.length === store.state.count.adventures )
            return Promise.resolve(store.state.adventures)

        return new Promise((resolve, reject) => {

            apiMethods.getAdventures(limit, offset)
                .then(adventuresData => {

                    // Init the mutation only if guides arent fully loaded
                    if ( store.state.adventures.length < adventuresData.length ) {
                        store.commit(types.LOAD_ADVENTURES, adventuresData)
                    }

                    resolve(adventuresData)
                })
        })
    },

    // ADVENTURE
    [types.LOAD_ADVENTURE_BY_SLUG](store, slug) {

        let existingAdventure = store.getters.getAdventureBySlug(slug)

        if (existingAdventure && existingAdventure.isSingleFetched) {
          return Promise.resolve(existingAdventure)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getAdventure(slug)
                .then(adventureData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof adventureData !== 'undefined' ) {
                        let adventure = merge({}, adventureData, {isSingleFetched: true})
                        store.commit(types.LOAD_ADVENTURE_BY_SLUG, adventure)
                        resolve(adventure)
                    }
                })
        })

    },

    //
    //
    // GUIDES
    [types.LOAD_ALL_GUIDES](store) {

        const _guides = store.getters.getUsersByType('guide')

        // If the total entries is equal to the state lenght, don't call the API
        if ( _guides.length === store.state.count.guides )
            return Promise.resolve( _guides )

        return new Promise((resolve, reject) => {
            apiMethods.getGuides()
                .then(guidesData => {

                    // Init the mutation only if guides arent fully loaded
                    if ( _guides.length < guidesData.length ) {
                        store.commit(types.LOAD_ALL_GUIDES, guidesData)
                    }

                    resolve(guidesData)
                })
        })
    },

    // GUIDE
    [types.LOAD_USER_BY_SLUG](store, {slug, user_type}) {

        if ( typeof user_type === 'undefined' )
            user_type = 'guide'

        let existingGuide = store.getters.getUserBySlug(slug)

        if (existingGuide && existingGuide.isSingleFetched) {
          return Promise.resolve(existingGuide)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getUser(slug, 'slug', user_type)
                .then(guideData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof guideData !== 'undefined' ) {
                        let guide = merge({}, guideData, {entries: [], isSingleFetched: true})
                        store.commit(types.LOAD_USER_BY_SLUG, guide)
                        resolve(guide)
                    }
                })
        })

    },
    [types.LOAD_USER_BY_ID](store, {id, user_type}) {

        if ( typeof user_type === 'undefined' )
            user_type = 'guide'

        let existingGuide = store.getters.getUserById(id)

        if (existingGuide && existingGuide.isSingleFetched) {
          return Promise.resolve(existingGuide)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getUser(id, 'id', user_type)
                .then(guideData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof guideData !== 'undefined' ) {
                        let guide = merge({}, guideData, {entries: [], isSingleFetched: true})
                        store.commit(types.LOAD_USER_BY_ID, guide)
                        resolve(guide)
                    }
                })
        })

    },

    //
    //
    // AUTHORS
    [types.LOAD_ALL_AUTHORS](store) {

        const _authors = store.getters.getUsersByType('author')

        // If the total entries is equal to the state lenght, don't call the API
        if ( _authors.length === store.state.count.authors )
            return Promise.resolve( _authors )

        return new Promise((resolve, reject) => {
            apiMethods.getAuthors()
                .then(authorsData => {

                    // Init the mutation only if authors arent fully loaded
                    if ( _authors.length < authorsData.length ) {
                        store.commit(types.LOAD_ALL_AUTHORS, authorsData)
                    }

                    resolve(authorsData)
                })
        })
    },

/*
    // AUTHOR
    [types.LOAD_AUTHOR_BY_SLUG](store, slug) {

        let existingAuthor = store.getters.getAuthorBySlug(slug)

        if (existingAuthor && existingAuthor.isSingleFetched) {
          return Promise.resolve(existingAuthor)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getAuthor(slug)
                .then(authorData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof authorData !== 'undefined' ) {
                        let author = merge({}, authorData, {entries: [], isSingleFetched: true})
                        store.commit(types.LOAD_AUTHOR_BY_SLUG, author)
                        resolve(author)
                    }
                })
        })

    },
    [types.LOAD_AUTHOR_BY_ID](store, id) {

        let existingAuthor = store.getters.getAuthorById(id)

        if (existingAuthor && existingAuthor.isSingleFetched) {
          return Promise.resolve(existingAuthor)
        }

        return new Promise((resolve, reject) => {
            apiMethods.getAuthor(id, 'id')
                .then(authorData => {

                    // Check for valid response to avoid an empty with only the `isSingleFetched` prop
                    if ( typeof authorData !== 'undefined' ) {
                        let author = merge({}, authorData, {entries: [], isSingleFetched: true})
                        store.commit(types.LOAD_AUTHOR_BY_ID, author)
                        resolve(author)
                    }
                })
        })

    },
*/


    //
    //
    // GLOBALS
    [types.GLOBALS](store) {

        return new Promise((resolve, reject) => {

            apiMethods.getGlobals()
                .then(globalsData => {

                    // Init the mutation only if settings arent fully loaded
                    if ( store.state.settings.length < 1 ) {
                        store.commit(types.GLOBALS, globalsData)
                    }

                    resolve(globalsData)
                })
        })
    },


    //
    //
    // INITIAL DATA
    [types.FETCH_INITIAL_DATA](store) {

        // Start loader
        store.dispatch('loader/startLoad', null, { root: true });

        let postsPromise = apiMethods.getPosts()
        let seriesPromise = apiMethods.getSeries()
        let adventuresPromise = apiMethods.getAdventures()

        Promise.all([postsPromise, seriesPromise, adventuresPromise])
            .then((results) => {

                let data = {
                    posts: results[0],
                    series: results[1],
                    adventures: results[2]
                }
                store.commit(types.FETCH_INITIAL_DATA, data)

            })
    },

}

// mutations
const mutations = {

    //
    //
    // POSTS
    [types.LOAD_POSTS](state, posts) {

        posts.forEach(post => {

            let idx = state.posts.findIndex(a => a.id === post.id)

            // If guide is not in index, push it to the store
            if (idx === -1) {
                state.posts.push(post)
            } else {
                //
            }
        })
    },


    // POST
    [types.LOAD_POST_BY_SLUG](state, post) {
        let idx = state.posts.findIndex(p => p.id === post.id)

        if (idx !== -1) {
            Vue.set(state.posts, idx, post)
        } else {
            state.posts.push(post)
        }
    },

    //
    //
    // SERIES

    // SERIE
    [types.LOAD_SERIE_BY_SLUG](state, serie) {
        let idx = state.series.findIndex(p => p.id === serie.id)
        if (idx !== -1) {
            Vue.set(state.series, idx, serie)
        } else {
            state.series.push(serie)
        }
    },

    //
    //
    // ADVENTURES
    [types.LOAD_ADVENTURES](state, adventures) {

        adventures.forEach(adventure => {

            let idx = state.adventures.findIndex(a => a.id === adventure.id)

            // If guide is not in index, push it to the store
            if (idx === -1) {
                state.adventures.push(adventure)
            } else {
                //
            }
        })
    },

    // ADVENTURE
    [types.LOAD_ADVENTURE_BY_SLUG](state, adventure) {
        let idx = state.adventures.findIndex(p => p.id === adventure.id)

        if (idx !== -1) {
            Vue.set(state.adventures, idx, adventure)
        } else {
            state.adventures.push(adventure)
        }
    },

    //
    //
    // GUIDES
    [types.LOAD_ALL_GUIDES](state, guides) {

        guides.forEach(guide => {

            let idx = state.users.findIndex(g => g.id === guide.id)

            // If guide is not in index, push it to the store
            if (idx === -1) {
                state.users.push(guide)
            } else {
                //
            }
        })
    },

    // USER
    [types.LOAD_USER_BY_SLUG](state, guide) {
        let idx = state.users.findIndex(p => p.id === guide.id)
        if (idx !== -1) {
            Vue.set(state.users, idx, guide)
        } else {
            state.users.push(guide)
        }
    },
    [types.LOAD_USER_BY_ID](state, guide) {
        let idx = state.users.findIndex(p => p.id === guide.id)
        if (idx !== -1) {
            Vue.set(state.users, idx, guide)
        } else {
            state.users.push(guide)
        }
    },

    //
    //
    // AUTHORS
    [types.LOAD_ALL_AUTHORS](state, authors) {

        authors.forEach(author => {

            let idx = state.users.findIndex(g => g.id === author.id)

            // If author is not in index, push it to the store
            if (idx === -1) {
                state.users.push(author)
            } else {
                //
            }
        })
    },

/*
    // AUTHOR
    [types.LOAD_AUTHOR_BY_SLUG](state, author) {
        let idx = state.authors.findIndex(p => p.id === author.id)
        if (idx !== -1) {
            Vue.set(state.authors, idx, author)
        } else {
            state.authors.push(author)
        }
    },
    [types.LOAD_AUTHOR_BY_ID](state, author) {
        let idx = state.authors.findIndex(p => p.id === author.id)
        if (idx !== -1) {
            Vue.set(state.authors, idx, author)
        } else {
            state.authors.push(author)
        }
    },
*/


    // ENTRIES
    [types.LOAD_ENTRIES_BY_USER](state, {id, data}) {

        //if ( user_type === 'guide' ) {

            let idx = state.users.findIndex(guide => guide.id === id)

            // Abort if Guide doesnt exist
            if (idx === -1)
                return

            state.users[idx].entries.push(data)

/*
        } else if ( user_type === 'author' ) {

            let idx = state.authors.findIndex(author => author.id === id)

            // Abort if Author doesnt exist
            if (idx === -1)
                return

            state.authors[idx].entries.push(data)

        }
*/


    },

    //
    //
    // GLOBALS
    [types.GLOBALS](state, settings) {
        state.settings = settings
    },

    //
    //
    // INITIAL DATA
    [types.FETCH_INITIAL_DATA](state, data) {
        state.posts = data.posts
        state.series = data.series
        state.adventures = data.adventures

        // Start loader
        this.dispatch('loader/endLoad', null, { root: true });
    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
