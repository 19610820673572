<template>
    <component
        ref="title"
        :is="tag"
        class="o-mask-title | t-poster | lazyload"
        :class="{ 'o-mask-title--no-img' : this.image === null }"
        :data-bg="image"
        v-view="animate"
        @lazybeforeunveil="loadBackgroundImage"
    >
        {{ title }}
    </component>
</template>

<script>

import Variables from 'src/mixins/variables';

import 'lazysizes';
import gsap from 'gsap';

export default {
    name: 'MaskTitle',
    props: {
        tag: {
            type: String,
            default: 'h1'
        },
        title: {
            type: String,
            required: true
        },
        image: {
            type: String,
            default: null
        }
    },
    mixins: [ Variables ],
    methods: {
        loadBackgroundImage() {

            if (this.image === null)
                return

            this.$refs.title.style.backgroundImage =`url('${this.image}')`

        },
        animate(e) {

            // Ignore firefox because animation is buggy
            if (this.browser == 'firefox')
                return

            if (this.image === null)
                return

            let backgroundPosition = `50% ${(1 - e.percent.top) * 100}%`
            // let scale = e.percent.center.y < 0.5 ? 1 + (0.5 - e.percent.center.y)/10 : 1

            gsap.to(this.$el, .2, {
                backgroundPosition,
                // scale,
                ease: 'power2.out'
            })
        }
    }
}

</script>

<style lang="scss">

    .o-mask-title {
        display: inline-block;
        @include responsive-type(420, 1600, 72, 280);
        background-position: 50% 50%;
        background-size: cover;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        transform-origin: 50% 100%;

        &--no-img {
            color: $color-green;

        }

        .is-firefox & {
            background-position: 50% 50% !important;
        }
    }

</style>
