<template>
    <div
        class="l-wrapper"
        :class="{'menu-is-open' : menuIsOpen}"
    >
        <app-header />
        <app-menu @toggleMenu="toggleMenu"/>
        <main class="l-main">
            <slot />
        </main>
        <app-footer />
        <loader />
  </div>

</template>

<script>
    import AppHeader from 'layout/AppHeader'
    import AppFooter from 'layout/AppFooter'
    import AppMenu from 'layout/AppMenu'
    import Loader from 'objects/Loader'

    export default {
        name: 'AppBase',
        components: {
            AppHeader,
            AppFooter,
            AppMenu,
            Loader,
        },
        data: () => ({
            menuIsOpen: false,
        }),
        watch: {
            '$route': function (to, from) {
                this.navIsOpen = false;
            },
        },
        methods: {
            toggleMenu(isOpen) {
                this.menuIsOpen = isOpen
            }
        }
    }
</script>
