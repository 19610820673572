<template>
    <ul class="o-link-table">
        <li
            v-for="(item, i) in items"
            v-if="item.logo"
            :key="`link-${i}`"
            class="o-link-table__item"
        >
            <a
                v-if="item.website"
                :href="item.website"
                :title="item.title"
                target="_blank"
            >
                <asset
                    v-if="item.logo.url"
                    :src="item.logo.url"
                    :alt="item.title"
                />
            </a>
            <asset
                v-else-if="item.logo.url"
                :src="item.logo.url"
                :alt="item.title"
            />
        </li>
    </ul>
</template>

<script>

    import Asset from 'objects/Asset'

    export default {
        name: 'LinkTable',
        components: {
            Asset
        },
        props: {
            items: {
                type: Array,
                default: () => []
            },
        }
    }
</script>

<style lang="scss">

.o-link-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    @include reset-list;
}

.o-link-table__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .75em 1.5em;



    a {
        opacity: 0.5;
        transition: opacity 0.25s $in-out-quad;
        mix-blend-mode: multiply;

        img {
            filter: grayscale(1);

        }

        &:hover {
            opacity: 1;
        }
    }
    &:nth-child(2n) {
        border-left: $border
    }

    &:nth-child(n + 3) {
        border-top: $border
    }

    @screen sm {
        padding-right: 2.5em;
        padding-left: 2.5em;
    }
}

</style>
