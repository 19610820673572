<template>
    <div
        v-if="block"
        class="b-authors"
    >
        <div v-if="block.headline" class="grid__row">
            <div class="grid__col--12 grid__col--8@lg grid__col--7@xxl">
                <h2
                    class="l-section__title | t-poster-30"
                    v-html="block.headline"
                ></h2>
            </div>
        </div>
        <card-grid
            v-if="authors"
            :cards="authors"
            :ratio="{ 0: 1, 480: 4/3, 768: 5/4 }"
        />
    </div>
</template>

<script>

    import types   from 'store/types'
    import CardGrid     from 'components/CardGrid'

    export default {
        name: 'BlockAuthors',
        components: {
            CardGrid
        },
        props: {
            block: {
                type: Object | Array
            },
            blockType: {
                type: String
            }
        },
        data: () => {
            return {
                authors: null
            }
        },
        created() {
            this.$store.dispatch(types.LOAD_ALL_AUTHORS)
                .then((authors) => {

                    this.authors = authors.map(author => ({
                        ...author,

                        body: {
                            title: author.name,
                            excerpt:  this.$options.filters.truncate(author.body, 80)
                        },
                        tags: author.specialities,
                        button: {
                            label: 'Voir les articles',
                            to: `/artisans/${author.slug}`
                        }
                    }))
                })

        }
    }
</script>

<style lang="scss">

    .b-authors {}

</style>


