<template>
    <span
        :class="[ color ? `o-badge--${color}` : '']"
        class="o-badge"
    >
        <slot>
            <icon
                :icon="icon"
            >
            </icon>
        </slot>
    </span>
</template>

<script>
    import Icon from 'objects/Icon'

    export default {
        name: 'Badge',
        components: {
            Icon
        },
        props: {
            icon: {
                type: String,
                required: true
                /*

                Possible values:
                round-adventures | round-guides | round-journal | round-newsletter | round-play | round-series | round-general | round-advice

                */
            },
            color: {
                type: String,
                default: 'gold'
            },
        }
    }
</script>

<style lang="scss">

    .o-badge {
        --color-bg:     #{$color-light};
        --color-icon:   #{$color-green};
        --size: 5em;

        position: absolute;
        top: calc(-1 * var(--size)/2);
        right: calc(-1 * var(--size)/2);
        display: flex;
        width: var(--size);
        height: var(--size);
        font-size: 1rem;
        background-color: var(--color-bg);
        border-radius: 50%;

        .o-icon {
            --color-svg: var(--color-icon);

            display: flex;
            width: inherit;
            height: inherit;
        }

        svg {
            width: 75%;
            height: 75%;
            margin: auto;
        }

        &--orange {
            --color-bg:     #{$color-orange};
        }

        &--gold {
            --color-bg:     #{$color-gold};
        }

        &--grey {
            --color-bg:     #{$color-grey};
        }

        &--green {
            --color-bg:     #{$color-green};
            --color-icon:   #{$color-gold};
        }

        @screen xs {
            --size:  6em;
        }

        @screen md {
            --size:  7em;
        }

        @screen lg {
            --size:  8em;
        }
    }

</style>
