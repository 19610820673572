<template>
    <div class="c-card-grid">
        <filters
            v-if="filters"
            :filters="filtersList"
            @change="updateFilter"
        />

        <transition-group
            name="anim-card-grid"
            tag="div"
            class="c-card-grid__list"
        >
            <div
                v-for="(card, i) in cardsVisible"
                :key="`card-${card.slug}`"
                class="c-card-grid__item"
            >
                <card
                    :prefix="card.prefix"
                    :category="card.category"
                    :asset="card.image ? card.image : card.vimeo ? card.vimeo : null"
                    :autoplay="false"
                    :content="card.body"
                    :tags="card.tags"
                    :button="card.button"
                    :entry="card"
                    :ratio="ratio"
                />
            </div>
            <div
                v-if="filters && cardsVisible.length === 0"
                key="no-results"
                class="c-card-grid__empty"
            >
                <h2 class="t-poster-40">Il n'y a aucun résultat pour votre recherche</h2>
            </div>
            <div
                v-if="filters && hasMoreContent"
                key="load-more"
                class="c-card-grid__btn"
            >
                <btn
                    label="Voir plus"
                    color="green"
                    @click.native="updatePage(page + 1)"
                />
            </div>
        </transition-group>
    </div>
</template>

<script>

    import types from 'store/types'

    import Btn from 'components/Btn'
    import Card from 'components/Card'
    import Icon from 'objects/Icon'
    import Filters from 'objects/Filters'

    import isEmpty from 'lodash/isEmpty'

    export default {
        name: 'CardGrid',
        components: {
            Btn,
            Card,
            Icon,
            Filters,
        },
        props: {
            cards: {
                type: Array | Object
            },
            filters: {
                type: Boolean,
                default: false,
            },
            ratio: {
                default: () => {
                    return { 0: 1, 480: 4/3, 768: 4/3 }
                }
            }
        },
        data: () => ({
            totalCards: 0,
            page: null,
            pagination: 12,
            filtersList: [],
            cardsFiltered: null,
            cardsVisible: [],
            dynamicFilters: [
                {
                    type: 'serie',
                    label: 'Série',
                    icon: 'circ-series',
                    field: 'title',
                },
                {
                    type: 'level',
                    label: 'Niveau d\'avancement',
                    icon: 'circ-level',
                    field: 'title',
                },
                {
                    type: 'author',
                    label: 'Guide',
                    icon: 'circ-compass',
                    field: 'fullName',
                },
                {
                    type: 'species',
                    label: 'Gibier',
                    icon: 'circ-crosshair',
                    field: 'title',
                }
            ]
        }),
        created() {
            this.cardsFiltered = this.cards

            if(this.filters) {
                this.defineFilters()
            }
        },
        computed: {
            hasMoreContent() {
                return this.totalCards - this.pagination * this.page > 0;
            }
        },
        methods: {
            defineFilters() {
                this.filtersList = []

                // Dynamically add filters that exist in the card
                let items, item
                this.dynamicFilters.forEach(filter => {
                    items = []

                    // If at least oen card has the filter, add to filter list
                    if (this.cards.find(card => typeof card[filter.type] !== 'undefined')) {
                        this.cards.forEach(card => {

                            item = card[filter.type]

                            // If item is array, loop through all subitems and add if not in items
                            if (Array.isArray(item)) {

                                item.forEach(subitem => {
                                    if(subitem !== null && !items.find(i => i === subitem[filter.field])) {
                                        items.push(subitem[filter.field])
                                    }
                                })

                            // If item is object, add if not in items
                            } else {

                                if(item !== null && !items.find(i => i === item[filter.field])) {
                                    items.push(item[filter.field])
                                }
                            }
                        })

                        // Add filter to filter list with all filter items
                        this.filtersList.push({
                            type: filter.type,
                            label: filter.label,
                            icon: filter.icon,
                            field: filter.field,
                            items: items,
                        })
                    }
                })

            },
            updateFilter(filters) {

                // If no filter, show all cards
                if (isEmpty(filters)) {
                    this.cardsFiltered = this.cards
                    return;
                }

                let cardsToRemove = []
                let cardValue, filterValue, activeFilter
                this.cards.forEach((card, i) => {
                    for(let filter in filters) {
                        cardValue = card[filter]

                        // Remove card if property is not defined or nll
                        if(typeof cardValue === 'undefined' || cardValue === null) {
                            cardsToRemove.push(i);
                            return
                        } else {

                            activeFilter = this.filtersList.find(f => f.type === filter)
                            cardValue = typeof cardValue === 'object' && typeof cardValue[activeFilter.field] !== 'undefined' ? cardValue[activeFilter.field] : cardValue
                            filterValue = filters[filter]

                            if (Array.isArray(cardValue)) {

                                // Remove card if object doesn't contain the filter
                                if (!cardValue.find(i => i[activeFilter.field] === filterValue)) {
                                    cardsToRemove.push(i)
                                    return;
                                }
                            // Remove card if value is not the same than the filter
                            } else if (cardValue !== filterValue) {
                                cardsToRemove.push(i)
                                return;
                            }
                        }
                    }
                })

                // Remove cards that don't have the filter
                this.cardsFiltered = this.cards.filter((card, i) => cardsToRemove.indexOf(i) == -1)
            },
            updatePage(number) {
                this.page = number
                this.cardsVisible = this.cardsFiltered.filter((card, i) => i < this.pagination * this.page )
            }
        },
        watch: {
            cardsFiltered() {
                this.totalCards = this.cardsFiltered.length
                this.updatePage(1)
            },
        }
    }
</script>


<style lang="scss">

    .c-card-grid {
    }

    .c-card-grid__list {
        display: grid;
        grid-template-columns: 1fr;
        @include grid-breakpoints(grid-row-gap, .5);
        @include grid-breakpoints(grid-column-gap, .5);

        @screen sm {
            grid-template-columns: repeat(2, 1fr);
        }

        @screen lg {
            max-width: 45em;
            margin-right: auto;
            margin-left: auto;
        }

        @screen xl {
            max-width: none;
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .c-card-grid__item {
        max-width: 100%;
        transform-origin: 0 50%;
        transition: opacity .4s ease-out, transform .4s $out-expo;

        .c-card__title {
            hyphens: auto;
        }

        @screen sm {

            .c-btn__icon {
                display: none;

                & + .c-btn__label {
                    margin-left: 0;
                }
            }


        }

        @screen xl {

            .c-btn__icon {
                display: block;

                & + .c-btn__label {
                    margin-left: .75em;
                }
            }
        }
    }

    .c-card-grid__empty {
        grid-column: 1 / -1;
        margin-top: 5vh;
        margin-bottom: 5vh;
        text-align: center;
    }

    .c-card-grid__btn {
        grid-column: 1 / -1;
        text-align: center;
        margin-top: 1em;
    }

    // Animation
    .anim-card-grid-enter {
        transform: translate(-2em, 0) scale(.9, 1);
        opacity: 0;
    }

    .anim-card-grid-leave-to{
        transform: translate(-2em, 0) scale(.9, 1);
        opacity: 0;
    }

    .anim-card-grid-leave-active {
        position: absolute;
        z-index: -1;
    }


</style>
