import Vue from 'vue'
import Router from 'vue-router'
import store from 'store/index'
import types from 'store/types'

import Home from 'views/Home'
import About from 'views/About'
import Contact from 'views/Contact'
import Page from 'views/Page'       // ?
import Series from 'views/Series'
import Serie from 'views/Serie'
import Adventures from 'views/Adventures'
import Adventure from 'views/Adventure'
import Journal from 'views/Journal'
import Post from 'views/Post'
import Guides from 'views/Guides'
import Guide from 'views/Guide'
import Authors from 'views/Authors'
import Author from 'views/Author'

// Vue Router
Vue.use(Router)

// Handle scroll with our code, not the default browser setup or vue.js
if (typeof history.scrollRestoration !== 'undefined') {
    history.scrollRestoration = 'manual'
}


function getComponent(name) {
    switch(name) {
        case 'home':
            return Home;
        case 'series':
            return Series;
        case 'aventures':
            return Adventures;
        case 'journal':
            return Journal;
        case 'guides':
            return Guides;
        case 'artisans':
            return Authors;
        case 'a-propos':
            return About;
        case 'contact':
            return Contact;
        default:
            return Page;
    }
}

// Loop through craft entries to determine adequate route component
const routes = []
let pageData, pageComponent;

Object.keys(window.__initialData__.routes).forEach(pageId => {
    pageData = window.__initialData__.routes[pageId]
    pageComponent = getComponent(pageData.slug)

    routes.push({
        name: pageComponent.name,
        component: pageComponent,
        path: pageData.path,
        meta: {
            id: pageData.id
        }
    })
})

routes.push.apply(routes, [
    {
        name: 'Capsule',
        component: Post,
        path: '/journal/capsules/:entrySlug',
    },
    {
        name: 'Chronique',
        component: Post,
        path: '/journal/:chroniques/:entrySlug',
    },
    {
        name: 'Serie',
        component: Serie,
        path: '/series/:entrySlug'
    },
    {
        name: 'Adventure',
        component: Adventure,
        path: '/aventures/:entrySlug'
    },
    {
        name: 'Guide',
        component: Guide,
        path: '/guides/:userName'
    },
    {
        name: 'Author',
        component: Author,
        path: '/artisans/:userName'
    },
    {
        name: '*',
        path: '*',
        redirect: '/404'
    }
])

const router = new Router({
    base: '/',
    routes,
    linkActiveClass: 'is-active',
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
})


router.beforeEach((to, from, next) => {
    // store.dispatch('startPageLoader')

    store.dispatch('loader/startLoad', null, { root: true });

    let tweenPromise = new Promise((resolve, reject) => {
        resolve();
    })

    let promises = [tweenPromise]

    let toRouteId = to.meta ? to.meta.id : null

    if (toRouteId) {
        promises.push( store.dispatch(types.LOAD_PAGE, toRouteId) )
    }

    //wait for the tween and the load page before going to the next() route
    Promise.all(promises)
        .then(() => {
            // store.dispatch('stopPageLoader')
            next();
        })
        .catch((e) => {
            next();
        })

    // Preload other pages if its the first load
    if (from.name === null) {

        let otherRoutes = routes.reduce((ids, route) => {
            let currentRouteId = route.meta ? route.meta.id : null
            if (currentRouteId !== null && currentRouteId !== toRouteId) {
                ids.push(currentRouteId)
            }
            return ids
        }, [])

        store.dispatch(types.PRELOAD_PAGES, otherRoutes)
    }

})

router.afterEach((to, from, next) => {
    if ( from.name !== null ) {
        document.body.classList.remove(`p-${from.name.toLowerCase()}`)
    }

    store.dispatch('loader/endLoad', null, { root: true });

    document.body.classList.add(`p-${to.name.toLowerCase()}`)
})

export default router
export { router, routes }
