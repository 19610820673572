<template>
    <div class="o-tabs">
        <ul class="o-tabs__list">
            <li
                v-for="(tab, i) in tabs"
                :key="`tab-${i}`"
                class="o-tabs__tab | t-heading-20"
            >
                <span class="o-tabs__text">{{ tab }}</span>
            </li>
        </ul>
    </div>
</template>

<script>

    export default {
        name: 'Tabs',
        props: {
            tabs: {
                type: Array,
                default: () => []
            }
        }
    }
</script>


<style lang="scss">

.o-tabs {
    display: flex;
    justify-content: center;
}

.o-tabs__list {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    text-align: center;

    &:after,
    &:before {
        @include pseudo-el($height: 2px, $bg: currentColor);
        position: absolute;
        left: 0;
    }

    &:before {
        top: 0;
    }

    &:after {
        bottom: 0;
    }
}

.o-tabs__tab {
    grid-row: 1;
    padding: .75em 1.5em;
    white-space: nowrap;

    &:nth-child(n+2):before {
        @include pseudo-el($width: 2px, $bg: currentColor);
        position: absolute;
        top: 0;
        left: calc(-2px/2);
    }

    @screen sm {
        padding-right: 2.5em;
        padding-left: 2.5em;
    }
}

.o-tabs__text {
    display: block;
}

</style>
