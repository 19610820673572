import Vue from 'vue'
import router from 'src/router'
import mixin from 'config/mixin'
import store from 'store/index'
import App from 'src/App';
import axios from 'axios'
import types from 'store/types'
import view from './directives/view'
import Vimeo from 'vimeo'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics'
import VuePlyr from 'vue-plyr'
import * as VueGoogleMaps from 'vue2-google-maps'

// Google Analytics
const isProd = process.env.NODE_ENV === 'production'

// Meta descriptions
Vue.use(VueMeta, {})

Vue.use(VueAnalytics, {
    id: 'UA-165726230-1',
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd
    }
})

// Set vue vars
Vue.config.productionTip = false

// Always return data instead of response
axios.interceptors.response.use((response) => response.data);

// Plyr video
Vue.use(VuePlyr)

// Google map
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLEMAP_KEY
    }
})

// APP
new Vue({
    el: '#app',
    router: router,
    store: store,
    render: h => h(App),
    created() {
        this.$store.dispatch(types.FETCH_INITIAL_DATA)
        this.$store.dispatch(types.GLOBALS)
    }
})
