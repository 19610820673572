<template>
    <article
        :class="[ category ? `c-card--${category.handle}` : '']"
        class="c-card"
    >
        <span
            v-if="asset"
            class="c-card__asset"
        >
            <asset
                :src="asset"
                :alt="asset.title"
                :width="asset.width"
                :height="asset.height"
                :autoplay="autoplay"
                :ratio="ratio"
            />
        </span>
        <div class="c-card__content">

            <frame
                v-if="category"
                :label="category.name"
                :modifier="category.handle"
                y="top"
                x="center"
            />

            <div
                v-if="isObject(content)"
                class="c-card__wrapper"
            >
                <adventure-label
                    v-if="entry"
                    :adventure="entry"
                    class="c-card__prefix"
                />
                <h3
                    v-if="content.title"
                    v-html="content.title"
                    class="c-card__title | t-poster-40"
                ></h3>
                <p
                    v-if="content.excerpt"
                    v-html="content.excerpt"
                    class="c-card__excerpt"
                ></p>

            </div>

            <div
                v-else-if="content"
                v-html="content"
                class="c-card__wrapper | t-cms"
            >
            </div>

            <tags
                v-if="tags"
                :tags="tags"
            ></tags>

            <div
                v-if="button"
                class="c-card__button"
            >
                <btn
                    tag="router-link"
                    :label="button.label"
                    :href="button.to ? button.to : null"
                    :iconBefore="button.iconBefore ? button.iconBefore : null"
                    :iconAfter="button.iconAfter ? button.iconAfter : null"
                    color="orange"
                />
            </div>
        </div>
    </article>
</template>

<script>
    import Btn     from 'components/Btn'

    import AdventureLabel   from 'objects/AdventureLabel'
    import Asset            from 'objects/Asset'
    import Frame            from 'objects/Frame'
    import Tags             from 'objects/Tags'

    export default {
        name: 'Card',
        components: {
            Btn,
            AdventureLabel,
            Asset,
            Frame,
            Tags
        },
        props: {
            category: {
                type: Object
            },
            asset: {
                type: Object
            },
            autoplay: {
                type: Boolean,
                default: true
            },
            content: {
                type: String | Object
            },
            tags: {
                type: Array | Object
            },
            button: {
                type: Object
            },
            entry: {
                type: Object
            },
            ratio: {
                default: () => {
                    return { 0: 1, 480: 4/3, 768: 4/3 }
                }
            }
        }
    }
</script>

<style lang="scss">

    .c-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: $color-light;

        &__asset {
            display: block;
            background-color: $color-green;

            img {
                @include img;
            }
        }

        &__prefix {
            margin-bottom: 0.75rem;
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 2em 1.4em 1.4em;
        }

        &__excerpt {
            @apply mt-4;
        }

        .o-frame {

            & + * {
                margin-top: .75rem;
            }
        }

        .o-tags {
            margin-top: 1em;

            &:last-child {
                margin-bottom: 0 ;
            }
        }

        &__button {
            margin-top: 2em;
        }

        .c-btn__bg:after {
            background-color: $color-grey;
        }

        &--capsules,
        &--chroniques {
            padding: 1.4em 1.4em calc(1.4em + 3em/2);

            .c-card__asset {
                z-index: 1;
            }

            .c-card__content {
                margin-top: -2px;
                border: 2px solid currentColor;

                .c-card__wrapper {
                    margin-bottom: 2em;
                }
            }

            .o-frame {
                z-index: 1;
            }

            .c-card__button {
                position: absolute;
                bottom: calc(-3em/2);
                left: 50%;
                margin-top: 0;
                padding-right: 2em;
                padding-left: 2em;
                white-space: nowrap;
                transform: translate(-50%, 0);
            }
        }
    }

</style>

