<template>
    <div
        class="o-video-background"
        :class="[
            { 'is-loaded' : isLoaded },
            { 'o-video-background--cover' : cover }
        ]"
        v-view="bgParallax"
    >
        <div
            class="o-video-background__inner"
            ref="inner"
        >
            <span
                v-if="poster"
                class="o-video-background__placeholder"
                :style="`background-image:url('${poster.src}')`"
            >
                <img
                    class="lazyload"
                    :src="poster.src"
                >
            </span>

            <img
                v-if="defaultImage && !autoplay"
                :src="defaultImage.src"
                @load="isLoaded = true"
            />

            <video
                v-else-if="poster && defaultVideo && autoplay"

                ref="player"
                @lazyloaded="isLoaded = true"
                v-view="playInViewport"

                class="lazyload"
                :data-poster="poster.src"
                data-autoplay=""
                preload="none"

                muted="true"
                loop="true"
                playsinline="true"
                allowtransparency
            >
                <source
                    :data-size="defaultVideo.size"
                    :src="defaultVideo.src"
                    :type="defaultVideo.type"
                >
            </video>
        </div>
    </div>
</template>

<script>

import 'lazysizes';

import Variables from 'src/mixins/variables';

import debounce from 'lodash/debounce';
import gsap from 'gsap';

export default {
    name: 'VideoBackground',
    mixins: [ Variables ],
    props: {
        videoID: {
            type: String,
            required: true,
        },
        autoplay: {
            type: Boolean,
            default: true,
        },
        parallax: {
            type: Boolean,
            default: true,
        },
        cover: {
            type: Boolean,
            default: false,
        },
        ratio: {
            default: () => {
                return { 0: 1, 480: 4/3, 768: 3/4 }
            }
        }
    },
    data: () => ({
        isLoaded       : false,
        isPaused       : false,
        resize         : false,

        videoSources   : null,
        posterSources  : null,

        poster  : null,
        defaultVideo   : null,
        defaultImage    : null,

        client         : null,
        client_id      : '7372c868f85e709acb290d5913af96cdfd8cc0ad',
        client_secret  : 'gi4SD1YiBOlEJljL0G23PrnS+EWsHksJcW5lox/+iwnC4Ewahwt/JRC0X4bvF/r03gdp4ybXfc2k+Yb1QcyjtUOm1WL44TTRz45+1rp1/M7rgAb3U+J3/wL65cVEhnFR',
        access_token   : 'ed4e3604797abe6860ef3c0dbea5ee43',
    }),
    computed: {

        player () {
            return this.$refs.player;
        }
    },
    methods: {

        playInViewport(e) {

            if(!this.autoplay || !this.isLoaded) return;

            let top = e.percent.top
            let left = e.percent.left

            if ( (top >= 1 || top <= 0) || (left >= 0.99 || left <= 0) ) {
                this.player.pause()
            }
            else {
                this.player.play()
            }
        },
        setRatio() {
            let ratio
            if(typeof this.ratio === 'number') {
                ratio = `${1/this.ratio * 100}%`
            } else {
                ratio = this.getResponsiveRatio(this.ratio)
                ratio =`${1/ratio * 100}%`
            }

            this.$el.style.setProperty('--ratio', ratio);
        },
        getResponsiveRatio(ratios) {
            let prev = -1
            let i
            let loop = 0
            let ratiosLength = Object.keys(ratios).length
            for(i in ratios) {
                loop++
                i = parseInt(i);
                if ((prev != -1) && (this.W.w < i)) {
                    return ratios[prev];
                } else {
                    prev = i;
                }

                if(loop === ratiosLength) {
                    return ratios[prev]
                }
            }
        },
        getPosterBySize( size ) {
            const posters = this.posterSources
            return posters.find(poster => poster.size === size)
        },
        getVideoBySize( size ) {
            const videos = this.videoSources
            return videos.find(video => video.size === size)
        },
        bgParallax(e) {
            if (!this.parallax) return

            let scale = e.percent.center.y > .5 ? 1 + (e.percent.center.y - .5)/5 : 1
            gsap.to(this.$refs.inner, .3, {
                scale,
                ease: 'sine.out'
            })
        }
    },
    created() {
        const Vimeo = require('vimeo').Vimeo;
        const client = new Vimeo(this.client_id, this.client_secret, this.access_token);

        client.request({
            method: 'GET',
            path: `/videos/${this.videoID}`
        }, (error, body) => {

            // Videos
            this.videoSources = body.files.map(file => ({
                size: file.width,
                src: file.link,
                type: file.type
            }))

            // Posters
            this.posterSources = body.pictures.sizes.map(file => ({
                size: file.width,
                src: file.link
            }))

            //
            this.poster = this.getPosterBySize(100)
            this.defaultVideo = this.getVideoBySize(1280) || this.getVideoBySize(1366) || this.getVideoBySize(640)

            //
            this.defaultImage = this.getPosterBySize(960)

        })
    },
    mounted() {

        if (!this.cover) {
            // Set video ratio
            this.setRatio();
        }

        // Bind window resize with ratio of multiple ratios
        if(typeof this.ratio === 'object' && !this.cover) {
            window.addEventListener('resize', this.resize = debounce(this.setRatio, 100));
        }
    },
    destroyed() {
        // Remove window event
        if(typeof this.ratio === 'object' && !this.cover) {
            window.removeEventListener('resize', this.resize);
        }
    }
}

</script>

<style lang="scss">

    .o-video-background {
        //position: relative;
        --ratio: 100%;
        width: 100%;

        overflow: hidden;

        &--cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .o-video-background__inner {
                height: 100%;
                padding-top: 0;
            }
        }

        &.is-loaded {

            .o-video-background__placeholder {
                transform: scaleY(0);
            }
        }

        .o-video-background__inner {
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-top: var(--ratio);
            transform-origin: 50% 100%;

            & > * {
                position: absolute;
            }

            @supports (object-fit: cover) {

                & > * {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 50% 50%;
                }
            }

            @supports not (object-fit: cover) {
                overflow: hidden;

                & > * {
                    top: 50%;
                    left: 50%;
                    min-width: 100%;
                    min-height: 100%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .o-video-background__placeholder {
        z-index: 1;
        display: block;
        background-size: 1px 1px;
        background-repeat: repeat;
        transition: transform .8s $out-expo;
        transform-origin: 50% 100%;
        pointer-events: none;

        img, &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }

        img {
            transform: scale(1.5);
            filter: blur(1px);
            opacity: 0.3;
            object-fit: cover;
            mix-blend-mode: multiply;
        }

        &:after {
            content: "";
            background-color: #f7f7f7;
            animation: backgroundPulse 3s ease-in-out infinite;
        }
    }

    @keyframes backgroundPulse {
        0% {
            opacity: 0
        }
        50% {
            opacity: 0.2
        }
        100% {
            opacity: 0
        }
    }

</style>
