<template>
    <div
        class="l-menu"
        :class="[
            { 'is-opening' : isOpening },
            { 'is-closing' : isClosing },
        ]"
    >
        <div class="l-menu__sidebar">
            <router-link
                to="/"
                title="Accueil Chasse Québec"
                class="l-menu__logo"
            >
                <icon icon="logo-symbol" />
            </router-link>
            <button
                class="l-menu__burger | o-burger"
                @click="isOpen ? close() : open()"
                aria-label="Ouvrir/Fermer le menu"
            >
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
        <nav class="l-menu__nav">
            <span class="l-menu__bg"></span>

            <router-link
                v-for="(thumb, i) in thumbs"
                :key="thumb.slug"
                :to="`/${thumb.slug}`"
                :title="thumb.title"
                class="l-menu__thumb"
                :class="`l-menu__thumb--${i + 1}`"
                @click.native="close()"
            >
                <span
                    class="l-menu__thumb-bg"
                    :style="thumb.fields.backgroundImage ? `background-image: url('${thumb.fields.backgroundImage.transform.medium}')` : false"
                ></span>
                <span class="l-menu__thumb-title | t-poster-80">
                    <span>{{ thumb.title }}</span>
                </span>
                <btn
                    class="l-menu__thumb-btn"
                    :label="`Découvrir les ${thumb.title}`"
                />
            </router-link>

            <div class="l-menu__inner">
                <ul class="l-menu__list">
                    <li
                        v-for="item in nav"
                        :key="item.slug"
                    >
                        <router-link
                            :to="`/${item.slug}`"
                            :title="item.title"
                            class="t-poster-80"
                            @click.native="close()"
                        >{{ item.title }}</router-link>
                    </li>
                </ul>
                <socials class="l-menu__socials" />
            </div>
        </nav>
    </div>
</template>

<script>
    import Btn from 'components/Btn'
    import Icon from 'objects/Icon'
    import Socials from 'objects/Socials'

    import { routes } from 'src/router'

    export default {
        name: 'AppMenu',
        components: {
            Btn,
            Icon,
            Socials,
        },
        data: () => ({
            isOpen: false,
            isOpening: false,
            isClosing: false,
            currentScroll: 0,
        }),
        computed: {
            thumbs() {
                return this.$store.getters.getPagesBySlugs(['series', 'aventures'])
            },
            nav() {
                return this.$store.getters.getPagesBySlugs(['journal', 'guides', 'a-propos', 'contact'])
            }
        },
        methods: {
            open() {
                this.isOpen = true

                // Add opening class for animation purpose
                this.isOpening = true
                setTimeout(() => {
                    this.isOpening = false
                }, 1200);

                this.currentScroll = window.scrollY
                document.documentElement.style.overflowY = 'hidden';
            },
            close() {
                this.isOpen = false

                /*
                // Add closing class for animation purpose
                this.isClosing = true
                setTimeout(() => {
                    this.isClosing = false
                }, 400);
                */

                // Add previous scroll
                document.documentElement.style.overflowY = 'scroll';
                window.scrollTo(0, this.currentScroll)
            },
        },
        watch: {
            isOpen(open) {
                // Emit event to parent
                this.$emit('toggleMenu', open)
            },
        }
    }
</script>

<style lang="scss">

.l-menu {
    --padding: 1em;

    z-index: 100;

    .menu-is-open & {
        z-index: 100;

        .l-menu__bg {
            transform: scale(1);
        }

        .l-menu__thumb {

            &--1 {

                .l-menu__thumb-title {
                    @include anim-text-show($duration: .6s, $delay: .4s);
                }
            }

            &--2 {

                .l-menu__thumb-title {
                    @include anim-text-show($duration: .6s, $delay: .6s);
                }
            }
        }

        .l-menu__thumb-btn {
            --color-text: #{$color-green};

            .c-btn__bg {
                transform: scale(1);
            }
        }

        .l-menu__thumb-bg {
            opacity: 1;
            transform: scale(1);
        }

        .l-menu__list {

            li {

                @for $i from 1 through 4 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;

                        @include anim-text-show($duration: #{.6 - $i/12}s, $delay: #{.4 + $i/12}s);
                    }
                }
            }
        }

        .l-menu__socials {

            &:before {
                transform: scale(1);
            }

            .o-icon {
                opacity: 1;
                transform: translate(0) scale(1);
            }
        }

        .o-socials__title {
            @include anim-text-show($duration: .4s, $delay: .8s);
        }
    }

    &.is-opening {

        .l-menu__bg {
            transition: transform .8s $out-expo;
        }

        // Thumb
        .l-menu__thumb {

            &--1 {

                .l-menu__thumb-bg {
                    transition: transform 1.2s $out-quint .2s, opacity 0s .2s;

                    &:after {
                        transition: transform .8s $out-circ .2s;
                    }
                }

                .l-menu__thumb-btn {
                    transition: color .4s ease-out .8s;

                    .c-btn__bg {
                        transition: transform .6s $out-quint .6s;
                    }
                }
            }

            &--2 {

                .l-menu__thumb-bg {
                    transition: transform 1.2s $out-quint .4s, opacity 0s .4s;

                    &:after {
                        transition: transform .6s $out-circ .4s;
                    }
                }

                .l-menu__thumb-btn {
                    transition: color .4s ease-out 1s;

                    .c-btn__bg {
                        transition: transform .6s $out-quint .8s;
                    }
                }
            }
        }

        .l-menu__socials {

            &:before {
                transition: transform .6s $out-quint .6s;
            }

            .o-icon {
                transition: all .4s $out-quint 1s;
            }
        }
    }

    &.is-closing {}

    @media #{md("md", "max")} {

        .menu-is-open & {

            .l-menu__nav {
                left: 0;
            }

            .l-menu__thumb-bg {

                &:after {
                    transform: scale(1, 0);
                }
            }
        }
    }

    @media #{md("md")} {
        --padding: 2em;

        .menu-is-open & {

            .l-menu__nav {
                top: 0;
            }

            .l-menu__thumb-bg {

                &:after {
                    transform: scale(0, 1);
                }
            }
        }
    }
}

.l-menu__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    background-color: $color-light;

    @media #{md("md", "max")} {
        justify-content: space-between;
        width: 100%;
        height: $menu-sidebar-size;
        padding-right: 1em;
        padding-left: 1em;
        border-bottom: $border-thin;
    }

    @media #{md("md")} {
        flex-direction: column;
        justify-content: center;
        width: $menu-sidebar-size;
        height: 100%;
        border-right: $border-thin;
    }
}

.l-menu__logo {
    display: flex;
    width: 2.75em;
    height: 2.75em;

    .o-icon {
        margin: auto;
    }

    &:after {
        @include pseudo-el($width: auto, $height: auto);
        position: absolute;
        top: calc(-.25em - .1em);
        right: -.25em;
        bottom: calc(-.25em + .1em);
        left: -.25em;
        border-radius: 50%;
        border: 0 solid $color-light;
        transition: border .2s ease-out;
    }

    &:hover {

        &:after {
            border: 3px solid $color-green;
        }
    }

    @media #{md("md")} {
        position: absolute;
        top: calc(#{$header-height/2} - 1.98em/2);
        left: calc(50% - 2.75em/2);
    }
}

.l-menu__burger {

    @media #{md("md")} {
    }
}

.l-menu__nav {
    position: fixed;

    @media #{md("md", "max")} {
        top: $menu-sidebar-size;
        left: -1000vw;
        width: 100%;
        height: calc(100% - #{$menu-sidebar-size});
        overflow-y: auto;
    }

    @media #{md("md")} {
        top: -1000vh;
        left: $menu-sidebar-size;
        width: calc(100% - #{$menu-sidebar-size});
        height: 100%;
    }
}

.l-menu__bg {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-green;
    transform: scale(1, 0);
    transform-origin: 50% 0;
    will-change: transform;

    @media #{md("md", "max")} {
        top: $menu-sidebar-size;
        left: 0;
    }

    @media #{md("md")} {
        top: 0;
        left: $menu-sidebar-size;
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }
}

// Thumbs
.l-menu__thumb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: var(--padding);
    overflow: hidden;

    &:hover,
    &.is-active {

        .l-menu__thumb-bg {
            transform: scale(1.1);
            transition: transform 4s ease-out;
        }

        .l-menu__thumb-title {

            &:after {
                transform: scale(1);
                transform-origin: 0 50%;
                transition: transform .8s $out-quint;
            }
        }
    }

    @media #{md("md")} {
        position: absolute;
        left: 0;
        width: 50%;
        height: 50%;

        &--1 { top: 0 }
        &--2 { bottom: 0 }
    }

    @media #{md("lg")} {
        width: 60%;
    }
}

.l-menu__thumb-bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-orange;
    background-position: 50% 50%;
    background-size: cover;
    opacity: 0;
    transform: scale(1.1);
    transition: transform .6s ease-out;
    will-change: transform, opacity;

    &:after {
        @include pseudo-el($bg: $color-green);
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 50% 100%;
        transition: inherit;
        will-change: transform;
    }

    @media #{md("md")} {

        &:after {
            transform-origin: 100% 50%;
        }
    }
}

.l-menu__thumb-title {
    @include anim-text-hide;

    &:after {
        @include pseudo-el($height: .06em, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .4s $out-quint;
    }

    @media #{md("md", "max")} {
        margin-bottom: 1em;
    }
}

.l-menu__thumb-btn {
    --color-text: transparent;

    .c-btn__bg {
        transform: scale(1, 0);
        transform-origin: 50% 100%;
    }

    @media #{md("md")} {

        .c-btn__bg {
            transform: scale(0, 1);
            transform-origin: 0 50%;
        }
    }
}

.l-menu__inner {
    --color-text: #{$color-gold};

    padding: var(--padding);
    color: var(--color-text);

    @media #{md("md")} {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        overflow-y: auto;
    }

    @media #{md("lg")} {
        width: 40%;
    }
}

.l-menu__list {
    padding-top: var(--padding);
    padding-bottom: var(--padding);

    li {
        @include anim-text-hide;

        &:nth-child(n+2) {
            margin-top: 1.5em;
        }
    }

    a {
        display: inline-block;

        &:after {
            @include pseudo-el($height: .06em, $bg: currentColor);
            position: absolute;
            bottom: 0;
            left: 0;
            transform: scale(0, 1);
            transform-origin: 100% 50%;
            transition: transform .4s $out-quint;
        }

        &:hover,
        &.is-active {

            &:after {
                transform: scale(1);
                transform-origin: 0 50%;
                transition: transform .8s $out-quint;
            }
        }
    }

    @media #{md("md")} {
        margin-top: auto;
        margin-bottom: auto;
    }

    @media #{md("xl")} {

        li {

            &:nth-child(n+2) {
                margin-top: 2em;
            }
        }
    }
}


.l-menu__socials {
    padding-top: var(--padding);

    &:before {
        @include pseudo-el($height: 2px, $bg: currentColor);
        top: calc(-1 * var(--padding));
        transform: scale(0, 1);
        transform-origin: 0 50%;
    }

    .o-icon {
        opacity: 0;
        transform: translate(0, .5em) scale(0);
    }

    .o-socials__title {
        @include anim-text-hide;
    }

    @media #{md("md")} {
        margin-top: auto;
    }
}


/*===================================
=            Burger icon            =
===================================*/


.o-burger {
    display: block;
    width: 2em;
    height: 1.5em;
    transition: transform .3s ease-out;

    span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background-color: currentcolor;
        transition: inherit;
        will-change: transform;

        &:after {
            @include pseudo-el($bg: $color-green-light);
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0, 1);
            transform-origin: 0 50%;
        }

        &:nth-child(1) {
            top: 0;

            &:after {
                transition: transform .3s $out-quad 0s;
            }
        }

        &:nth-child(2) {
            top: calc(50% - 2px/2);

            &:after {
                transition: transform .25s $out-quad .05s;
            }
        }

        &:nth-child(3) {
            bottom: 0;

            &:after {
                transition: transform .2s $out-quad .1s;
            }
        }
    }

    &:hover {
        //transform: scale(.9);

        span {

            &:after {
                transform: scale(1);
            }
        }
    }

    .menu-is-open & {

        span {

            &:after {
                transform-origin: 100% 50%;
            }

            &:nth-child(1) {
                transform: translate(15%, 0) rotate(45deg);
                transform-origin: 0 50%;
            }

            &:nth-child(2) {
                transform: scale(0, 1);
                transition: transform .2s ease-in;
            }

            &:nth-child(3) {
                transform: translate(15%, 0) rotate(-45deg);
                transform-origin: 0% 50%;
            }
        }

        &:hover {

            span {
            }

        }
    }
}


</style>
