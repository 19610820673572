<template>
    <div
        class="o-video"
        :class="[
            { 'is-init' : isInit },
            { 'is-fixed' : isFixed }
        ]"
    >
        <div
            class="o-video__inner"
            ref="inner"
        >
            <vue-plyr
                :options="plyrOptions"
                class="o-video__plyr"
                ref="plyr"
            >
                <div class="plyr__video-embed">
                    <iframe
                        :src="`https://player.vimeo.com/video/${videoID}?loop=false&byline=false&portrait=false&title=false&speed=true&transparent=0&gesture=media`"
                        allowfullscreen
                        allowtransparency
                        allow="autoplay"
                    >
                    </iframe>

                    <div class="o-video__overlay">
                        <h2
                            v-if="title"
                            class="o-video__title | t-poster-80"
                        >
                            {{ title }}
                        </h2>
                        <span class="o-video__icon">
                            <icon icon="round-play" />
                        </span>
                    </div>
                </div>
            </vue-plyr>
        </div>
    </div>
</template>

<script>
import Icon from 'objects/Icon'

import Variables from 'src/mixins/variables';

import debounce from 'lodash/debounce';
import gsap from 'gsap';


export default {
    name: 'VideoPlyr',
    components: {
        Icon
    },
    props: {
        videoID: {
            type: String,
            required: true
        },
        sticky: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        }
    },
    mixins: [ Variables ],
    data: () => ({
        isInit: false,
        isFixed: false,
        el: null,
        ratio: 16/9,
        playing: false,
        plyrOptions: {
            controls: ['play', 'progress', 'current-time', 'volume', 'mute', 'fullscreen'],
            invertTime: false,
        },
    }),
    computed: {
        player() {
            return this.$refs.plyr.player
        }
    },
    methods: {
        set() {
            let rect = this.$el.getBoundingClientRect()
            this.el = {
                top: rect.top + window.scrollY,
                left: rect.left,
                w: this.$el.offsetWidth,
                h: this.$el.offsetHeight,
            }

            this.videoFixedWidth = this.W.w < 768 ? '100%' : '33.333%'

            if(!this.isFixed) {
                gsap.set(this.$refs.inner, {
                    width: this.el.w
                })
            }
        },
        init() {
            // Set variables
            this.set()

            // Set real video ratio
            this.ratio = Math.round(this.el.w/this.el.h * 1000)/1000

            gsap.set(this.$el, {
                height: 0,
                paddingTop: `${1/this.ratio * 100}%`
            })

            this.isInit = true;
        },
        scroll() {

            if(window.scrollY > this.el.top) {

                if(!this.isFixed) {

                    gsap.to(this.$refs.inner, .6, {
                        width: this.W.w * 0.3,
                        ease: 'power3.out',
                        onStart: () => {
                            this.isFixed = true
                        },
                    })
                }
            } else if(window.scrollY <= this.el.top && this.isFixed) {
                this.isFixed = false

                gsap.to(this.$refs.inner, .6, {
                    // y: 0,
                    width: this.el.w,
                    ease: 'power3.out',
                })
            }
        },
    },
    mounted() {

        if (this.sticky) {
            // Init
            this.init()

            // Window events
            window.addEventListener('scroll', this.scroll)
            window.addEventListener('resize', debounce(this.set, 100));
        }

        // Update playing variable
        this.player.on('play', (e) => {
            this.playing = true
        })
        this.player.on('pause', (e) => {
            this.playing = false
        })
    },
    destroyed() {

        if (this.sticky) {
            window.removeEventListener('scroll', this.scroll)
            window.removeEventListener('resize', debounce(this.set, 100));
        }
    }
}

</script>

<style lang="scss">
    @import 'vue-plyr/dist/vue-plyr.css';

    .o-video {
        width: 100%;

        &.is-init {

            .o-video__inner {
                position: absolute;
                top: 0;
                left: 0;
                // height: 100%;
            }

            .o-video__plyr {
                // height: inherit;
            }
        }

        &.is-fixed {
            z-index: 50;
            transform-origin: 0 100%;

            .o-video__inner {
                position: fixed;
            }

            .o-video__icon {
                opacity: 0;
            }

            .plyr__controls {
                font-size: .7em;

                svg {
                    transform: scale(.8);
                }
            }
        }

        &:hover {

            .o-video__overlay:before {
                opacity: .2;
            }

            .o-video__title {
                transform: translate(0, -1.5rem);
            }
        }

        @media #{md("md", "max")} {

            &.is-fixed {

                .o-video__inner {
                    top: $menu-sidebar-size;
                    left: 0;
                }
            }
        }

        @media #{md("md")} {


            &.is-fixed {

                .o-video__inner {
                    top: 0;
                    left: $menu-sidebar-size;
                }
            }
        }
    }

    .o-video__inner {
        width: 100%;
        transform-origin: 0 0;
    }

    .o-video__plyr {
        width: 100%;
    }

    .o-video__overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        @include grid-breakpoints(padding);
        pointer-events: none;
        user-select: none;

        &:before {
            @include pseudo-el($bg: $color-dark);
            position: absolute;
            top: 0;
            left: 0;
            opacity: .4;
            transition: opacity .2s ease-out;
        }
    }

    .o-video__title {
        --color-text: #{$color-light};
        transition: transform .4s ease-in-out;

        @media #{md("sm", "max")} {
            display: none;
        }

        @media #{md("lg")} {
            width: 75%;
        }

        @media #{md("xl")} {
            width: 50%;
        }
    }

    .o-video__icon {
        position: absolute;
        top: calc(50% - 4em/2);
        left: calc(50% - 4em/2);
        display: flex;
        width: 4em;
        height: 4em;
        background-color: $color-light;
        border-radius: 50%;
        transition: opacity .1s;

        .o-icon {
            width: calc(100% - .4em);
            height: calc(100% - .4em);
            margin: auto;
        }

        svg {
            width: 100%;
            height: 100%;
        }

        @media #{md("md")} {
            font-size: 1.5em;
        }

        @media #{md("xl")} {
            font-size: 2em;
        }
    }

    // Plugin override

    @mixin rangeInput {
        border: 1px solid $color-green;
        background-color: $color-light;
    }

    .plyr--video {

        .plyr__controls {
            padding: 0 .5em;
            height: 2.75em;
            background: $color-dark;
            transform: translate(0, 100%);
        }

        .plyr__control {
            padding: .4em;
            border-radius: 0;

            svg {
                filter: none;
            }

            &:first-child {
                margin-right: 0;
            }

            &:hover,
            &.plyr__tab-focus {
                background: $color-green;
                box-shadow: none;
                opacity: .8;
            }
        }

        .plyr__time--current {
            margin-right: auto;
        }

        input[type="range"] {
            width: 100%;
            height: .3em;
            margin-right: 0;
            margin-left: 0;
            color: $color-gold;
            -webkit-appearance: none;
            background-color: $color-dark;
            border-radius: 0;

            &:focus {
              outline: none;
            }


            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                @include rangeInput;
            }

            &::-ms-track {
                width: 100%;
                cursor: pointer;
                background: transparent;
                border-color: transparent;
                color: transparent;
                @include rangeInput;
            }

            &::-moz-range-thumb {
                @include rangeInput;
            }
        }

        .plyr__tooltip {
            padding: .3em .6em;
            font-size: .65em;
            color: $color-light;
            background-color: $color-green;
            border-radius: 0;

            &:before {
                border-top-color: $color-green;
            }
        }

        &.plyr--hide-controls {

            .plyr__controls {
                opacity: 1;
                pointer-events: initial;
            }
        }

        &.plyr--paused,
        &.plyr--playing {

            .o-video__overlay {
                opacity: 0;
            }
        }

        &.plyr--stopped {

            .o-video__overlay {
                opacity: 1;
            }

        }

        &:hover {

            .plyr__controls {
                transform: translate(0);
            }

            .plyr__progress__container input[type="range"] {
                transform: scale(1);

                &::-webkit-slider-thumb {
                    transform: scale(1);
                }

                &::-ms-track {
                    transform: scale(1);
                }

                &::-moz-range-thumb {
                    transform: scale(1);
                }
            }
        }
    }

    .plyr__progress__container {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        margin-left: 0;

        .plyr__progress {
            left: 0;
            margin-right: 0;
            margin-left: 0;
        }

        input[type="range"] {

            &::-webkit-slider-thumb {
                transform: scale(0);
                transition: transform .2s ease-out;
            }

            &::-ms-track {
                transform: scale(0);
                transition: transform .2s ease-out;
            }

            &::-moz-range-thumb {
                transform: scale(0);
                transition: transform .2s ease-out;
            }
        }

        .plyr__progress__buffer {
            display: none;
        }
    }

</style>
