<template>
    <section
        :class="[ color ? `--bg-${color}` : '']"
        class="l-hero"
        v-view.reveal="{ handler: setHeader, end: 1.5 }"
    >
        <div class="l-hero__inner">
            <span
                ref="bg"
                class="l-hero__bg"
                :style="`--scale-y: ${bg.scale}`"
            ></span>
            <div class="grid">
                <header class="l-hero__content">
                    <mask-title
                        :title="title"
                        :image="asset"
                        class="l-hero__title"
                    />
                    <badge
                        v-if="badgeIcon"
                        :icon="badgeIcon"
                    />
                </header>
            </div>
        </div>

        <tabs
            v-if="tabs"
            :tabs="tabs"
            class="l-hero__tabs"
        />
    </section>
</template>

<script>
    import Tabs from 'objects/Tabs'
    import Badge from 'objects/Badge'
    import MaskTitle from 'objects/MaskTitle'

    import gsap from 'gsap'

    export default {
        name: 'Hero',
        components: {
            Tabs,
            Badge,
            MaskTitle,
        },
        props: {
            title: {
                type: String,
                required: true
            },
            color: {
                type: String,
                default: 'orange'
            },
            asset: {
                type: String,
                default: null
            },
            badgeIcon: {
                type: String,
                default: 'round-series'
            },
            tabs: {
                type: Array,
                default: null
            }
        },
        data: () => ({
            headerColor: false,
            docStyle: document.documentElement.style,
            bg: {
                scale: 1
            },
        }),
        mounted() {
            this.docStyle.setProperty('--color-header', 'var(--color-light)');

            this.headerColor = ['gold', 'green'].includes(this.color) ? 'var(--color-light)' : 'var(--color-green)';
        },
        methods: {
            setHeader(e) {
                if (this.headerColor) {
                    this.docStyle.setProperty('--color-header', this.headerColor);
                    this.headerColor = false
                }


                let scale = e.percent.center.y < .5 ? 1 - (.5 - e.percent.center.y)/7 : 1

                gsap.to(this.bg, .3, {
                    scale
                })
            }
        },
        destroyed() {
            this.docStyle.removeProperty('--color-header');

        }
    }

</script>

<style lang="scss">

    .l-hero {
        --color-bg: #{$color-light};
        margin-bottom: calc(theme("fontSize.mask")/4);

        text-align: center;

        &.js-reveal {

            .l-hero__bg {
                background-color: $color-dark;
                transform: translate(0, -100%);
                will-change: transform;

                &:after {
                    @include pseudo-el($bg: var(--color-bg));
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: scale(1, 0);
                    transform-origin: 50% 0;
                    will-change: transform;
                }
            }

            .l-hero__title {
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                opacity: 0;
                transform: scale(.7);
                transform-origin: 50% 0%;
                will-change: transform, opacity;
            }

            .o-badge {
                transform: scale(0);
                transform-origin: 25% 75%;

                .o-icon {
                    opacity: 0;
                    transform: scale(.9);
                }
            }

            .o-tabs__list {

                &:after,
                &:before {
                    transform: scale(0, 1);
                }
            }

            .o-tabs__tab {

                &:before {
                    transform: scale(1, 0);
                }
            }

            .o-tabs__text {
                opacity: 0;
            }

            &.is-visible {

                .l-hero__bg {
                    transform: translate(0);
                    transition: transform .8s $out-expo;

                    &:after {
                        transform: scale(1);
                        transition: transform .8s $in-out-quad .2s;
                    }
                }

                .l-hero__title {
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                    opacity: 1;
                    transform: scale(1);
                    transition: all .8s $out-quint .4s;
                }

                .o-badge {
                    transform: scale(1);
                    transition: transform .4s $out-expo .8s;

                    .o-icon {
                        opacity: 1;
                        transform: scale(1);
                        transition: opacity .3s ease-out 1s, transform .3s $out-expo 1s;
                    }
                }

                .o-tabs__list {

                    &:after,
                    &:before {
                        transform: scale(1);
                        transition: transform .4s $out-quad .8s;
                    }

                }

                .o-tabs__tab {

                    &:before {
                        transform: scale(1);
                        transition: transform .2s $out-quad .8s;
                    }
                }

                .o-tabs__text {
                    opacity: 1;
                    transition: opacity .3s $out-sine 1.2s;
                }
            }
        }

        @media #{md("xs", "max")} {

            .o-badge {
                display: none;
            }
        }
    }

    .l-hero__bg {
        --scale-y: 1;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: auto;
        background-color: var(--color-bg);
        transform-origin: 50% 0;
        transform: scaleY(var(--scale-y));

        @screen md {
            top: -$header-height;
        }
    }

    .l-hero__inner {

        .grid {
            display: block;
        }

        @media #{md("md", "max")} {
            padding-top: 2em;
        }
    }

    .l-hero__content {
        z-index: 5;
        display: inline-block;
        bottom: calc(theme("fontSize.mask")/-4);
    }

    .l-hero__title {}

    .l-hero__tabs {
        //margin-top: calc(theme("fontSize.mask")/-4);
        --color-text: #{$color-green};
        margin-top: calc(theme("fontSize.mask")/4);
    }

</style>
