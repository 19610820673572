<template>
    <div v-if="adventure">
        <app-section
            color="green"
            class="l-section--no-padding"
        >

            <Deco class="p-adventure__deco" />
            <div class="grid__row | --items-center">
                <div
                    v-if="adventure.vimeo && adventure.vimeo.full"
                    class="grid__col--12 grid__col--6@md grid__col--8@lg --no-gutter"
                >
                    <video-plyr
                        :key="`player-${componentKey}`"
                        :videoID="adventure.vimeo.full"
                        :sticky="true"
                    />
                </div>
                <div class="l-section__content | p-adventure__header | grid__col--12 grid__col--6@md grid__col--4@lg">
                    <adventure-label
                        :adventure="adventure"
                        color="light"
                    />
                    <h2 class="t-poster-40" v-html="adventure.title"></h2>
                    <div
                        v-if="adventure.body"
                        v-html="adventure.body"
                        class="t-cms"
                    ></div>
                </div>
                <div
                    v-if="adventure.cover"
                    class="p-cover | grid__col--12 grid__col--6@md --no-gutter"
                >
                    <asset
                        :src="adventure.cover.url"
                        :alt="adventure.cover.title"
                        :width="adventure.cover.width"
                        :height="adventure.cover.height"
                    />
                </div>
            </div>
            <div class="o-table">
                <div
                    v-if="guide"
                    class="o-table__col"
                >
                    <div class="o-table__header">
                        <icon icon="circ-compass" />
                        <h3 class="t-heading-20">Guide</h3>
                    </div>
                    <router-link
                        :to="guide.url"
                        class="o-table__body block">
                        <span>{{ guide.name }}</span>
                        <span
                            v-if="guide.image"
                            class="o-table__avatar"
                        >
                            <asset :src="guide.image.sm" />
                        </span>
                    </router-link>
                </div>
                <div
                    v-if="adventure.hunters"
                    class="o-table__col"
                >
                    <div class="o-table__header">
                        <icon icon="circ-crosshair" />
                        <h3 class="t-heading-20">Chasseurs</h3>
                    </div>
                    <div class="o-table__body">
                        <ul>
                            <li
                                v-for="hunter in adventure.hunters"
                                v-if="hunter"
                                :key="`hunter-${hunter.id}`"
                            >
                                {{ hunter.name }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    v-if="adventure.location"
                    class="o-table__col"
                >
                    <div class="o-table__header">
                        <icon icon="circ-picker" />
                        <h3 class="t-heading-20">{{ adventure.location.name }}</h3>
                    </div>
                    <div class="o-table__body">
                        <GmapMap
                            v-if="adventure.location.lng"
                            class="o-table__map"
                            :zoom="12"
                            :center="getCoordinates(adventure.location)"
                            :options="googleMapsOpts"
                        >
                            <GmapCustomMarker
                                :marker="getCoordinates(adventure.location)"
                            >
                                <span class="o-table__marker"></span>
                            </GmapCustomMarker>
                        </GmapMap>
                    </div>
                </div>
            </div>
        </app-section>

        <app-section v-if="adventure.builder">
            <div class="grid__row --justify-center --justify-start@lg">
                <article class="grid__col--12 grid__col--7@md --offset-5@md grid__col--7@lg --offset-4@lg">
                    <builder-loop :blocks="adventure.builder" />
                </article>
            </div>
        </app-section>


        <app-section
            v-if="adventure.products.length && isReady"
        >
            <h2 class="t-heading-20">Matériel utilisé</h2>
            <slider
                :key="`slider-${componentKey}`"
                class="c-slider--gear"
                ref="gearSlider"
            >
                <div
                    v-for="(product, i) in adventure.products"
                    :key="`gear-${i}`"
                    class="c-slider__slide"
                >
                    <img
                        v-if="product.image"
                        :src="product.image[0].lg"
                        :alt="product.title"
                        class="c-slider__img"
                        @load="refreshSlider"
                    />
                    <div
                        v-if="product.title || product.body"
                        class="c-slider__content"
                    >
                        <h3
                            v-if="product.title"
                            class="t-poster-60"
                        >{{ product.title }}</h3>
                        <div
                            v-if="product.body"
                            v-html="product.body"
                        >
                        </div>
                    </div>
                </div>
            </slider>
        </app-section>

        <app-section
            v-if="adventure.posts.length && posts.length"
        >
            <h2 class="t-heading-20 | mb-8">En lien avec cette aventure</h2>
            <card-grid :cards="posts" />
        </app-section>

        <app-section
            v-if="push"
            :bgVideo="push.vimeo ? push.vimeo.preview: false"
            push="next"
        >
            <div class="grid__row | --justify-center">
                <div class="grid__col--12 grid__col--8@md grid__col--6@lg">
                    <h2 class="t-heading-20">À découvrir</h2>
                    <h3 class="l-section__title | t-poster-60">{{ push.title }}</h3>
                    <btn
                        tag="router-link"
                        :href="`/${push.uri}`"
                        label="Visionner l’épisode"
                        iconBefore="play"
                    />
                </div>
            </div>
        </app-section>

    </div>
</template>

<script>
    import types        from 'store/types'
    import merge        from 'lodash/merge'

    import BuilderLoop  from 'templates/builder/BuilderLoop'

    import AppSection   from 'layout/AppSection'
    import CardGrid     from 'components/CardGrid'

    import AdventureLabel  from 'objects/AdventureLabel'
    import Asset           from 'objects/Asset'
    import Icon            from 'objects/Icon'
    import Tags            from 'objects/Tags'
    import Deco            from 'objects/Deco'
    import VideoPlyr       from 'objects/VideoPlyr'

    import Btn from 'components/Btn'
    import Slider from 'components/Slider'

    import { mapStyles } from 'vendors/google-maps'
    import {gmapApi} from 'vue2-google-maps'
    import GmapCustomMarker from 'vue2-gmap-custom-marker'

    import 'src/filters/uscape'

    export default {
        name: 'Adventure',
        components: {
            BuilderLoop,
            AppSection,
            CardGrid,
            AdventureLabel,
            Asset,
            Icon,
            Tags,
            Btn,
            Deco,
            Slider,
            VideoPlyr,
            GmapCustomMarker
        },
        data: () => ({
            adventure: null,
            guide: null,
            posts: [],
            googleMapsOpts: {
                disableDefaultUi: true,
                zoomControl: true,
                zoomControlOptions: {
                    style: 0,
                    position: 8
                },
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                draggable: false,
                styles: mapStyles
            },
            componentKey: 0,
        }),
        metaInfo() {
            return {
                title: this.adventure ? this.$options.filters.uscape(this.adventure.seo.title) : null,
            }
        },
        created() {
            this.setPage()
        },
        mounted() {
            this.isReady = true
        },
        computed:{
            google: gmapApi,
            push() {

                if ( !this.adventure ) {
                    return
                }

                const _adventure = this.adventure
                const _series = this.$store.state.data.series
                let _pushEntry = null;

                // If the adventure is related to a serie, get the next episode
                if ( _adventure.serie && _adventure.serie.id ) {

                    const _serie = _series.find(s => s.id == _adventure.serie.id)

                    if ( typeof _serie === 'undefined' || _serie == 'undefined')
                        return

                    const _serieEpisodes = _serie.episodes
                    const _episodeNumber = this.$store.getters.getEpisodeNumber(_adventure)

                    // If the episode is not the last (online) of the serie, fetch the next one
                    if ( _episodeNumber < _serieEpisodes.length ) {

                        _pushEntry = _serieEpisodes[_episodeNumber]

                        // Standardize `vimeo` prop
                        _pushEntry = merge(_pushEntry, {'vimeo' : _pushEntry.vimeo[0]})

                    // If not, fetch another serie
                    } else {

                        _pushEntry = _series.find(s => s.id !== _serie.id)

                    }

                // If not, fetch a "random" serie
                } else {
                    _pushEntry = _series[0]

                }

                return _pushEntry
            }
        },
        methods: {

            setPage() {

                if (this.isEmpty(this.$route.params.entrySlug)) {
                    return this.$router.push(-1)
                }

                this.$store
                    .dispatch(types.LOAD_ADVENTURE_BY_SLUG, this.$route.params.entrySlug)
                    .then((adventure) => {
                        this.adventure = adventure
                        this.setPosts(adventure.posts)
                        this.$store.dispatch(types.LOAD_USER_BY_ID, {id: adventure.authorId})
                            .then(guide => {
                                this.guide = guide
                            })
                    })

            },
            setPosts(_posts) {

                this.posts = [] /* Empty */

                if ( !_posts )
                    return

                _posts.forEach((_post) => {

                    this.$store
                        .dispatch(types.LOAD_POST_BY_SLUG, _post.slug)
                        .then((post) => {

                            post = {
                                ...post,
                                body: {
                                    title: post.title,
                                    excerpt: post.excerpt
                                },
                                button: {
                                    label: 'Lire la suite',
                                    to: post.url
                                }
                            }

                            this.posts.push(post)
                        })
                })
            },
            refreshSlider() {
                if ( this?.$refs?.gearSlider )
                    return this.$refs.gearSlider.resize()
            },
            getCoordinates: (position) => ({ lat: position.lat, lng: position.lng })
        },
        watch: {
            $route() {
                this.setPage()
            },
            adventure() {
                this.componentKey++
            },
        }
    }
</script>

<style lang="scss">

    .p-adventure__header {

        @media #{md("md", "max")} {
            margin-top: 2em;
            margin-bottom: 2em;
        }
    }

    /*----------  Deco  ----------*/

    .p-adventure__deco {
        --deco-right: 0;
        --deco-bottom: 0;

        svg {
            fill: $color-dark;
        }

        @media #{md("md")} {
            --deco-bottom: 10vmax;
        }
    }


    /*=============================
    =            Table            =
    =============================*/

    .o-table {
        display: flex;
        margin-bottom: 2em;

        @media #{md("sm", "max")} {
            flex-direction: column;
        }

        @media #{md("sm")} {
            flex-wrap: wrap;
        }

        @media #{md("md")} {
            margin-top: 2em;
        }
    }

    .o-table__col {
        overflow: hidden;

        @media #{md("sm", "max")} {

            &:nth-child(n+2) {
                margin-top: 1.5em;
            }
        }

        @media #{md("sm")} {
            //flex: 1;
            width: calc(1/2 * 100%);

            &:first-child:last-child {
                width: 100%;
            }

            &:nth-child(2) {
                border-left: $border-thin-gold;
            }

            &:nth-child(3) {
                flex: 0 0 auto;
                width: 100%;
                // grid-column: 1/3
            }
        }

        @media #{md("lg")} {
            width: calc(1/4 * 100%);

            &:nth-child(1):nth-last-child(2),
            &:nth-child(2):nth-last-child(1) {
                width: calc(1/2 * 100%);
            }

            &:nth-child(3) {
                //flex: 1;
                width: calc(2/4 * 100%);
                border-left: $border-thin-gold;
                // grid-column: initial;
            }
        }
    }

    .o-table__body,
    .o-table__header {
        padding-right: 2vw;
        padding-left: 4vw;

        @media (min-width: #{$layout-main--max-width}) {
            padding-right: 4em;
            padding-left: 4em;
        }
    }

    .o-table__header {
        display: flex;
        align-items: center;
        padding-top: .5em;
        padding-bottom: .5em;
        border-top: $border-thin-gold;
        border-bottom: $border-thin-gold;

        .o-icon {
            color: $color-gold;
            margin-right: .5em;
        }
    }

    .o-table__body {
        padding-top: 1em;
        padding-bottom: 1em;
        color: $color-light;
    }

    .o-table__avatar {
        display: block;
        width: 7em;
        height: 7em;
        margin-top: 1em;
        border-radius: 50%;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            max-width: none;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .o-table__map {
        width: 100%;
        height: 15em;
        margin-bottom: -2.5em;

        & > *:first-child {
            height: 100%;
        }
    }

    .o-table__marker {
        position: absolute;
        top: calc(50% - 24px/2);
        left: calc(50% - 24px/2);
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $color-orange;
    }

</style>
