<template>
    <component
        :is="tag"
        :to="url !== null ? url : false"
        :class="className"
        v-view.reveal="1"
    >
        <video-background
            :videoID="videoID"
            :ratio="ratio"
            class="c-card-video__asset"
        />
        <div
            v-if="title || button"
            class="c-card-video__inner"
        >
            <adventure-label
                v-if="entry"
                :adventure="entry"
                class="c-card-video__prefix"
                color="light"
            />
            <h3
                v-if="title"
                class="c-card-video__title | t-poster-60"
                v-html="title"
            >
            </h3>

            <btn
                v-if="button"
                tag="span"
                :label="button"
                iconBefore="play"
                class="c-card-video__btn"
            />
        </div>
    </component>
</template>

<script>

    import Btn from 'components/Btn'

    import AdventureLabel   from 'objects/AdventureLabel'
    import VideoBackground  from 'objects/VideoBackground'

    export default {
        name: 'CardVideo',
        components: {
            Btn,
            AdventureLabel,
            VideoBackground
        },
        props: {

            title: {
                type: String,
                default: ''
            },
            button: {
                type: String,
                default: null
            },
            videoID: {
                type: String | Number
            },
            url: {
                type: String,
                default: null
            },
            ratio: {
                default: () => {
                    return { 0: 1, 480: 4/3, 768: 3/4 }
                }
            },
            revealDirection: {
                type: String,
                default: 'left'
            },
            entry: {
                type: Object
            }
        },
        computed: {
            className() {
                return `c-card-video c-card-video--${this.revealDirection}`
            },
            tag() {
                //console.log(this.url)
                return this.url !== null ? 'router-link' : 'div'
            }
        }
    }
</script>

<style lang="scss">

    .c-card-video {
        display: block;

        /*
        &:hover {

            .c-card-video__title {
                opacity: .5;
            }

            .c-card-video__prefix {
                opacity: .8;
            }
        }
        */

        &.js-reveal {
            overflow: hidden;

            &.c-card-video--right {
                --trfs-x: 0;
                --trfs-y: 1;
                --trfo: 100% 50%;
                --trfo-btn: 0 50%;
            }

            &.c-card-video--left {
                --trfs-x: 0;
                --trfs-y: 1;
                --trfo: 0 50%;
                --trfo-btn: 100% 50%;
            }

            &.c-card-video--top {
                --trfs-x: 1;
                --trfs-y: 0;
                --trfo: 50% 0;
                --trfo-btn: var(--trfo);
            }

            &.c-card-video--bottom {
                --trfs-x: 1;
                --trfs-y: 0;
                --trfo: 50% 100%;
                --trfo-btn: var(--trfo);
            }

            &:after {
                @include pseudo-el($bg: $color-grey-light);
                position: absolute;
                top: 0;
                left: 0;
                transform-origin: var(--trfo);
            }

            .c-card-video__asset {
                transform: scale(1.2);
                transform-origin: var(--trfo);

                &:after {
                    @include pseudo-el($bg: $color-dark);
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: .8;
                }
            }

            .c-card-video__title,
            .c-card-video__prefix {
                opacity: 0;
            }

            .c-card-video__btn {
                --color-text: transparent;

                .c-btn__bg {
                    transform: scale(var(--trfs-x), var(--trfs-y));
                    transform-origin: var(--trfo-btn);
                }
            }

            &.is-visible {

                &:after {
                    transform: scale(var(--trfs-x), var(--trfs-y));
                    transition: transform .8s $out-expo;
                }

                .c-card-video__asset {
                    transform: scale(1);
                    transition: transform .8s $out-sine;

                    &:after {
                        opacity: 0;
                        transition: opacity .8s $out-sine;
                    }
                }

                .c-card-video__title,
                .c-card-video__prefix {
                    opacity: 1;
                    transition: opacity .4s $out-sine .2s;
                }

                .c-card-video__btn {
                    --color-text: #{$color-green};
                    transition: color .4s ease-out .6s;

                    .c-btn__bg {
                        transform: scale(1);
                        transition: transform .6s $in-out-quint .2s;
                    }
                }
            }
        }
    }

    .c-card-video__inner {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.5em 4em 1.5em 1.5em;
    }

    .c-card-video__title,
    .c-card-video__prefix {
        transition: opacity .3s ease-out;
    }

    .c-card-video__prefix {
        margin-bottom: 0.75rem;
    }

    .c-card-video__title {
        --color-text: #{$color-light};

        & + .c-card-video__btn {
            margin-top: 1.75rem;
        }
    }

    .c-card-video__btn {}

</style>
