<template>
    <img
        v-if="srcType == 'string'"
        :src="src"
        :width="width"
        :height="height"
        :loading="loading"
        class="u-img"
    >

    <video-background
        v-else-if="src.preview"
        :videoID="src.preview"
        :autoplay="autoplay"
        :parallax="false"
        :ratio="ratio ? ratio : false"
    />

    <figure-ratio
        v-else-if="ratio && src.lg"
        :ratio="ratio"
        :image="src"
    />

    <img
        v-else-if="src.lg"
        :src="src.lg"
        class="u-img"
    >
</template>

<script>

    import FigureRatio      from 'objects/FigureRatio'
    import VideoBackground  from 'objects/VideoBackground'

    export default {
        name: 'Asset',
        components: {
            FigureRatio,
            VideoBackground
        },
        props: {
            asset: {
                type: Object | Array | String,
            },
            src: {
                type: Object | Array | String
            },
            classes: {
                type: String,
                default: 'u-img'
            },
            width: {
                type: Number,
                default: null
            },
            height: {
                type: Number,
                default: null
            },
            loading: {
                type: String,
                default: 'lazy'
            },
            autoplay: {
                type: Boolean,
                default: true
            },
            ratio: {
                default: () => {
                    return false
                }
            }
        },
        computed: {

            srcType() {
                if (this.src)
                    return typeof this.src
            }

        }
    }
</script>
