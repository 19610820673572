<template>
    <div :class="className">

        <textarea
            v-if="type == `textarea`"
            :id="`input-${_uid}`"
            class="o-input__input | text-md"
            v-model="value"
            :type="type"
            :required="required"
            :autocomplete="autocomplete !== false"
            :name="autocomplete"
            @focus="isActive = true"
            @blur="isActive = false"
        />

        <div v-else-if="type == `select`">
            <select
                :id="`input-${_uid}`"
                class="o-input__input | text-md"
                v-model="value"
                :type="type"
                :required="required"
                :autocomplete="autocomplete !== false"
                :name="autocomplete"
                @focus="isActive = true"
                @blur="isActive = false"
            >
                <option
                    v-if="options"
                    v-for="option in options"
                    :value="option.value"
                >
                    {{ option.label }}
                </option>
            </select>
            <icon
                icon="arrow-down"
                class="o-filters__arrow"
            />
        </div>

        <input
            v-else
            :id="`input-${_uid}`"
            class="o-input__input | t-heading text-md"
            v-model="value"
            :type="type"
            :required="required"
            :autocomplete="autocomplete !== false"
            :name="autocomplete"
            @focus="isActive = true"
            @blur="isActive = false"
        />
        <label
            v-if="label && !disabled"
            :for="`input-${_uid}`"
            class="o-input__label"
        >
            <span class="o-input__text | t-heading text-md" v-html="required ? `${label}*` : label"></span>

        </label>
    </div>
</template>

<script>

import Icon from 'objects/Icon'

export default {
    name: 'AppInput',
    components: {
        Icon
    },
    props: {
        modifier: {
            type: String,
            default: 'text'
        },
        label: false,
        defaultValue: false,
        required: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        options: {
            type: Array
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autocomplete: false,
    },
    data: () => ({
        value: '',
        isActive: false,
        isFilled: false,
    }),
    created() {
        if(this.defaultValue) {
            this.value = this.defaultValue
            this.isFilled = true
        }
    },
    computed: {
        className() {
            let className = 'o-input'
            className += ` o-input--${this.modifier}`
            className += this.disabled ? ' is-disabled' : ''
            className += this.isActive || this.isFilled ? ' is-filled' : ''
            return className
        }

    },
    watch: {
        value(val) {
            this.$emit('change', val)
            if (val !== '') {
                this.isFilled = true
            } else {
                this.isFilled = false
            }
        }
    }
};

</script>

<style lang="scss">

.o-input {
    color: $color-dark;

    &__input {
        display: block;
        width: 100%;
        color: currentColor;
        padding: 1rem;
        line-height: 1;
        border: 2px solid currentColor;
        border-radius: 0;

        transition: border-color 0.25s ease-in-out;

        &:active, &:focus {
            border-color: $color-orange;
        }
        &::placeholder {
            color: currentColor;
            opacity: 1;
        }
    }

    &__label {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 1.1rem;
        pointer-events: none;

        .o-input__text {
            will-change: transform;
            transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
            transform-origin: top left;
        }
    }

    &--textarea {

        .o-input__input {
            height: 7em;
        }

        .o-input__label {
            height: auto;
            padding-top: 1rem;
        }
    }

    &--select {
        position: relative;

        .o-input__input {

            &:active, &:focus {

                & + .o-icon {
                    transform: rotate(-180deg);
                }
            }
        }
        .o-icon {
            position: absolute;
            top: 1.5rem;
            right: 1rem;
        }
    }

    &--text {

        &:before {
            @include pseudo-el($bg: $color-green);
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1, 0);
            transform-origin: 50% 100%;
            transition: transform .2s ease-out;
        }
    }

    &.is-filled {

        .o-input__label {
            .o-input__text {
                transform: translateY(-0.75rem) scale(0.5);
                opacity: 0.6;
            }
        }
    }

    &.is-disabled {
        pointer-events: none;

        &:before {
            background-color: $color-grey;
        }

        .o-input__input {
            color: $color-light;
        }
    }
}

</style>
