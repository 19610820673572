import Vue from 'vue';
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'

// DEFINED GLOBAL VARIABLES AND METHODS IN TEMPLATES
Vue.mixin({
    methods: {
        isEmpty(value) {
            return isEmpty(value);
        },
        isObject(value) {
            return isObject(value);
        },
    },
});
