<template>
    <transition
        name="loader"
        :css="false"

        @enter="enter"
        @leave="leave"
    >
        <div
            v-if="isLoading"
            class="o-loader"
            ref="container"
        >
            <span ref="bg" class="o-loader__bg"></span>
            <span ref="bar" class="o-loader__bar">
                <span ref="logo" class="o-loader__logo">
                    <span ref="progress" class="o-loader__progress">
                        <svg viewBox="0 0 100 100">
                            <circle ref="circle" cx="50" cy="50" r="47.5"" stroke-width="var(--stroke-width)" />
                        </svg>
                    </span>
                    <icon icon="logo-symbol" />
                </span>
                <!--
                <span
                    ref="progress"
                    class="o-loader__progress"
                ></span>
                -->
            </span>
        </div>
    </transition>
</template>

<script>

import Icon from 'objects/Icon'

import Variables from 'src/mixins/variables';
import { EventBus, EVENTS } from 'src/event-bus'

import { mapGetters } from 'vuex'

import gsap from 'gsap';
import DrawSVG from 'plugins/DrawSVGPlugin';
gsap.registerPlugin(DrawSVG)

export default {
    name: 'Loader',
    components: {
        Icon
    },
    mixins: [ Variables ],
    data: () => ({
        state: null,
        ease: {},
        tl: gsap.timeline()
    }),
    computed: {
        ...mapGetters({
            isLoading: 'loader/isLoading',
        }),
    },
    methods: {
        enter(container, done) {

            // Update store animation state to 'starting'
            this.updateState('starting');


            // Animate in
            gsap.timeline()
                .set(container, {
                    display: 'block'
                })
                .set(this.$refs.circle, {
                    drawSVG: '0%'
                })
                .set(this.$refs.bg, {
                    opacity: 1
                })
                .to(this.$refs.bar, 0.4, {
                    opacity: 1,
                    ease: 'sine.out',
                    onStart: () => {

                        // Update store animation state to 'covering'
                        this.updateState('covering');

                        // Trigger done function
                        done()
                    }
                })
                .add('progressStart')
                .to(this.$refs.circle, 1, {
                    //scaleY: .8,
                    drawSVG: '75%',
                    ease: 'sine.out',
                }, 'progressStart')
                .to(this.$refs.progress, .8, {
                    //scaleY: .8,
                    rotate: '135deg',
                    ease: 'sine.out'
                }, 'progressStart')

        },
        leave(container, done) {

            gsap.timeline()
                .add('progressEnd')
                .to(this.$refs.circle, 0.6, {
                    // scaleX: 1,
                    // scaleY: 1,
                    drawSVG: '100%',
                    ease: 'sine.out',
                    onStart: () => {
                        window.scroll(0, 0)
                    }
                }, 'progressEnd')
                .to(this.$refs.progress, 0.6, {
                    // scaleX: 1,
                    // scaleY: 1,
                    rotate: '180deg',
                    ease: 'sine.out'
                }, 'progressEnd')
                .add('out')
                .to(this.$refs.circle, 0.6, {
                    drawSVG: '0%',
                    ease: 'sine.inOut',
                }, 'out')
                .to(this.$refs.progress, 0.6, {
                    rotate: '-180deg',
                    ease: 'sine.inOut',
                }, 'out')
                .set(this.$refs.progress, {
                    opacity: 0
                })
                .to(this.$refs.bg, 0.4, {
                    //scaleY: 0,
                    opacity: 0,
                    ease: 'sine.out',
                    onStart: () => {
                        // Update store animation state to 'ending'
                        this.updateState('ending');
                    },
                }, 'out+=.2')
                .to(this.$refs.bar, 0.4, {
                    opacity: 0,
                    ease: 'sine.out',
                })
                .set(container, {
                    display: 'none'
                })
                .set(this.$refs.bg, {
                    clearProps: 'all'
                })
                .set(this.$refs.progress, {
                    clearProps: 'all',
                })
                .set(this.$refs.circle, {
                    clearProps: 'all',
                    onComplete: () => {
                        done()

                        // Update store animation state to null
                        this.updateState('ended');
                    }
                })

        },
        updateState(state) {
            this.state = state
            this.$store.dispatch('loader/updateAnimationState', state, { root: true });
        },
    },
}

</script>

<style lang="scss">

/*----------  Loader styles  ----------*/

.o-loader {
    z-index: 500;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.o-loader__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-grey-light;

    opacity: 0;
    //transform: scaleY(0);
    transform-origin: 50% 0;

    @media #{md("md", "max")} {
        top: $menu-sidebar-size;
        left: 0;
    }

    @media #{md("md")} {
        top: 0;
        left: $menu-sidebar-size;
    }
}

.o-loader__bar {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: $color-light;
    opacity: 0;

    @media #{md("md", "max")} {
        width: 100%;
        height: $menu-sidebar-size;
    }

    @media #{md("md")} {
        width: $menu-sidebar-size;
        height: 100%;
    }
}

.o-loader__logo {
    display: flex;
    width: 2.75em;
    height: 2.75em;
    border-radius: 50%;

    .o-icon {
        margin: auto;
    }

    @media #{md("md", "max")} {
        top: calc(#{$menu-sidebar-size/2} - 2.75em/2);
        left: 1em;
    }

    @media #{md("md")} {
        position: absolute;
        top: calc(#{$header-height/2} - 1.98em/2);
        left: calc(50% - 2.75em/2);
    }
}

.o-loader__progress {
    --stroke-width: 5px;

    position: absolute;
    top: calc(-1.5 * var(--stroke-width)/2 - .1em);
    left: calc(-1.5 * var(--stroke-width)/2);
    display: block;
    width: calc(100% + var(--stroke-width) * 1.5);
    height: calc(100% + var(--stroke-width) * 1.5);
    will-change: transform;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: transparent;
        stroke: $color-green;
        transform: rotate(-90deg);
    }

    circle {
        stroke-dasharray: 10 20;
        will-change: auto;
    }
}

/*
.o-loader__progress {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-gold;
    display: none;

    @media #{md("md", "max")} {
        transform-origin: 0 100%;
        transform: scaleX(0);
    }

    @media #{md("md")} {
        transform-origin: 0 0;
        transform: scaleY(0);
    }
}
*/


/*----------  Animation  ----------*/

/*
.anim-loader-enter-active,
.anim-loader-leave-active {
    transition: z-index 1s;

    .o-loader__bg {
        transform: scale(1);
    }
}

.anim-loader-enter-active {

    .o-loader__bg {
        transform-origin: 50% 0;
        transition: transform .4s ease-out;
    }
}

.anim-loader-leave-active {

    .o-loader__bg {
        transform-origin: 50% 100%;
        transition: transform .4s ease-out;
    }
}

.anim-loader-enter,
.anim-loader-leave-to {
    z-index: 499;

    .o-loader__bg {
        transform: scale(1, 0);
    }
}
*/

</style>
