<template>

    <component
        :is="tag"
        :href="tag == 'a' && href !== null ? href : false"
        :to="hrefTo"
        :class="className"
        :rel="tag == 'a' ? 'noopener' : false"
    >
        <span
            v-if="format !== 'simple'"
            class="c-btn__bg"></span>
        <icon
            v-if="iconBefore !== null"
            class="c-btn__icon"
            :icon="iconBefore"
        ></icon>
        <span class="c-btn__label">
            <slot>{{ label }}</slot>
        </span>
        <icon
            v-if="iconAfter !== null"
            class="c-btn__icon"
            :icon="iconAfter"
        ></icon>
    </component>

</template>

<script>
    import Icon from 'objects/Icon'

    export default {
        name: 'Button',
        components: {
            Icon
        },
        props: {
            tag: {
                type: String,
                default: 'button',
            },
            href: {
                type: String,
                default: null,
            },
            label: {
                type: String,
                default: 'Button Label'
            },
            iconBefore: {
                type: String,
                default: null
            },
            iconAfter: {
                type: String,
                default: null
            },
            color: {
                type: String,
                default: null
            },
            format: {
                type: String,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },
        computed: {

            className() {

                let name = 'c-btn'
                name += this.format !== null ? ` c-btn--${this.format}` : ''
                name += this.color !== null ? ` c-btn--${this.color}` : ''
                name += this.disabled ? ' is-disabled' : ''
                return name;
            },
            hrefTo() {

                // Force CMS link to Vue route
                if (this.tag === 'router-link' && this.href !== null) {
                    return this.href.replace(window.location.origin, '')
                }

                return ''
            }
        }
    }
</script>

<style lang="scss">

.c-btn {
    --color-bg: #{$color-light};
    --color-text: #{$color-green};

    display: inline-flex;
    align-items: center;
    min-height: 3em;
    padding: .25em 1.5em;
    color: var(--color-text);
    cursor: pointer;
    overflow: hidden;
    transition: color .2s ease-out, opacity .2s ease-out;

    &--simple {
        padding-right: 0;
        padding-left: 0;

        &:hover {
            opacity: .7;
        }
    }

    &--orange {
        --color-bg: #{$color-orange};
        --color-text: #{$color-green};

        .c-btn__bg:after {
            background-color: $color-light;
        }
    }

    &--green {
        --color-bg: #{$color-green};
        --color-text: #{$color-light};
    }


    /*
    &--gold {
        --color-bg: #{$color-gold};
        --color-text: #{$color-light};
    }
    */

    &:hover {

        &:not(.c-btn--simple) {
            color: var(--color-bg);
        }

        .c-btn__bg:after {
            transform: translate(0, -2px);
            transform-origin: 0 50%;
            transition: transform .6s $out-quint;
        }
    }

    @media #{md("xs", "max")} {
        font-size: .9rem;
    }
}

.c-btn__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);

    &:after {
        @include pseudo-el($bg: var(--color-text));
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0, calc(-100% - 1px) );
        transform-origin: 100% 50%;
        transition: transform .4s $out-quint;
    }
}

.c-btn__label {
    @apply font-heading text-md;
    z-index: 1;
    display: block;
    white-space: nowrap;
    text-transform: uppercase;

    & + .c-btn__icon {
        margin-left: .75em;
    }
}

.c-btn__icon {
    z-index: 1;
    margin-left: -.75em;

    & + .c-btn__label {
        margin-left: .75em;
    }
}

</style>
