<template>
    <div>

        <hero
            v-if="page"
            :title="page.title"
            :color="page.fields.color.label"
            :asset="page.fields.backgroundImage ? page.fields.backgroundImage.transform.large : ''"
            :tabs="tabs"
            badgeIcon="round-series"
        />

        <app-section
            v-if="series"
        >
            <article
                v-for="(serie, i) in series"
                :key="`serie-${i}`"
                v-if="serie.episodes.length"
                class="l-section__article | grid__row --justify-between"
            >
                <div class="grid__col--12 grid__col--6@md grid__col--5@lg grid__col--4@xl">
                    <div class="t-content">
                        <h2 class="t-poster-60">{{ serie.title }}</h2>

                        <p class="t-heading-20">{{ serie.episodes.length }} épisodes</p>

                        <link-table
                            v-if="serie.sponsors"
                            :items="serie.sponsors"
                            image="logo"
                            link="website"
                        />

                        <p>{{ serie.excerpt }}</p>

                        <btn
                            tag="router-link"
                            label="Découvrez la série"
                            :href="serie.url"
                            iconAfter="arrow-right-circ"
                            format="simple"
                        />
                    </div>
                </div>
                <div class="grid__col--12 grid__col--6@md grid__col--7@xxxl | --no-gutter --gutter@xs --no-gutter@md">
                    <card-video
                        v-if="serie.episodes[0]"
                        :entry="serie.episodes[0]"
                        :title="serie.episodes[0].title"
                        button="Visionner l’épisode"
                        :url="`/aventures/${serie.episodes[0]['slug']}`"
                        :videoID="serie.episodes[0].vimeo[0].preview"
                        :ratio="1"
                    />
                </div>
            </article>
        </app-section>

        <push
            v-if="page"
            :push="page.fields.cta"
        />

    </div>
</template>

<script>

    import types from 'store/types'

    import Hero        from 'layout/Hero'
    import AppSection     from 'layout/AppSection'
    import CardVideo   from 'components/CardVideo'
    import Push         from 'components/Push'
    import Btn         from 'components/Btn'
    import Asset       from 'objects/Asset'
    import Badge       from 'objects/Badge'
    import LinkTable   from 'objects/LinkTable'


    export default {
        name: 'Series',
        components: {
            Hero,
            AppSection,
            CardVideo,
            Push,
            Btn,
            Asset,
            Badge,
            LinkTable,
        },
        metaInfo() {
            return {
                title: this.page.seo.title,
            }
        },
        computed: {

            page() {
                let pageId = this.$route.meta.id
                let page = this.$store.getters.getPageById(pageId)
                if (typeof page === 'undefined') {
                    return null
                }
                return page
            },

            // fetch Posts
            series() {

                return this.$store.state.data.series
            },

            tabs() {

                const _series = this.$store.state.data.count.series
                const _episodes = this.$store.state.data.count.episodes

                return [
                    `${_series} séries`,
                    `${_episodes} épisodes`
                ]

            }

        },
        created() {

            if (!this.page) {
                this.$store.dispatch(types.LOAD_PAGE, this.$router.currentRoute.meta.id)
            }

        }
    }
</script>

<style lang="scss">

    .l-section {

        &__article {

            .t-content {

                @media #{md("md", "max")} {
                    margin-bottom: 2rem;
                }
            }

            .o-link-table {

                @media #{md("lg", "max")} {
                    display: none;
                }
            }
        }
    }
</style>
