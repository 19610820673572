import Vue from 'vue'
import Vuex from 'vuex'
import pages from './modules/pages'
import data from './modules/data'
import loader from './modules/loader'
Vue.use(Vuex)

// Store is where all the actions, and data are stored (save)
const store = new Vuex.Store({
    modules: {
        pages,
        data,
        loader,
    },
    strict: process.env.NODE_ENV !== 'production'
})

export default store
