<template>
    <tags
        v-if="episodeNumber && serie"
        :tags="`Épisode ${episodeNumber}`"
        :color="color"
        :stroke="true"
    />
</template>

<script>

import types from 'store/types'
import Tags  from 'objects/Tags'

export default {
    name: 'AdventureLabel',
    components: {
        Tags
    },
    props: {
        adventure: {
            type: Object,
            default: null,
            required: true
        },
        color: {
            type: String,
            default: 'green'
        },
    },
    data: () => ({
        isAdventure: false,
    }),
    computed: {

        _adventure() {

           if ( !this.isAdventure )
                return

           if ( !this.adventure )
                return

            const _adventure = this.adventure
            const _newAdventure = this.$store.getters.getAdventureBySlug(_adventure.slug)

            return (_newAdventure) ? _newAdventure : _adventure
        },
        episodeNumber() {

            if ( !this._adventure )
                return

            if ( !this._adventure.serie )
                return

            const _adventure = this._adventure

            return (_adventure.serie.id) ? this.$store.getters.getEpisodeNumber(_adventure) : null
        },
        serie() {

            if ( !this._adventure.serie )
                return

            const _serie = this._adventure.serie

            return (_serie.title) ? _serie.title : null
        }
    },
    created() {


        // Look for adventure only
        if ( this.adventure.section === 'adventure') {

            // If serie is not defined; fetch the whole Adventure
            if ( typeof this.adventure.serie === 'undefined') {

                this.$store.dispatch(types.LOAD_ADVENTURE_BY_SLUG, this.adventure.slug)
                    .then((data) => {
                        this.isAdventure = true
                    })

            // Serie seems to be defined..
            } else {
                this.isAdventure = true
            }
        }
    }
}

</script>

<style lang="scss">

</style>
