<template>
    <div class="o-filters">
        <ul class="o-filters__list">
            <li
                v-for="(filter, i) in filters"
                v-if="filter.items.length > 0"
                :key="`filter-${filter.type}`"
                class="o-filters__item"
                :class="{ 'is-active' : activeFilter === i }"
            >
                <button
                    class="o-filters__btn"
                    @click="activeFilter = activeFilter === i ? -1 : i"
                >
                    <icon
                        v-if="filter.icon"
                        :icon="filter.icon"
                        class="o-filters__icon"
                    />
                    <span
                        v-if="filter.label"
                        class="o-filters__text"
                        :class="{ 'has-active' : filter.value !== defaultValue }"
                    >
                        <span class="o-filters__label | t-heading-20">{{ filter.label }}</span>
                        <span
                            class="o-filters__active"
                            @click.prevent="setOption(filter, defaultValue)"
                        >
                            {{ filter.value }}
                            <icon icon="cross"/>
                        </span>
                    </span>
                    <icon
                        icon="arrow-down"
                        class="o-filters__arrow"
                    />
                </button>
                <ul
                    class="o-filters__dropdown | o-dropdown"
                >
                    <li
                        v-for="(item, j) in filter.items"
                        :key="`dropdown-${_uid}-${j}`"
                        class="o-dropdown__item"
                    >
                        <input
                            :id="`${_uid}-${filter.type}-${j}`"
                            :value="item"
                            type="checkbox"
                            :name="filter.type"
                            @change="setOption(filter, item)"
                            :checked="filter.value === item"
                        >
                        <label
                            :for="`${_uid}-${filter.type}-${j}`"
                        >
                            {{ item }}
                        </label>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>

import types from 'store/types'

import Icon from 'objects/Icon'

export default {
    name: 'Filters',
    components: {
        Icon
    },
    props: {
        filters: {
            type: Array,
            required: true
        },
    },
    data: () => ({
        defaultValue: null,
        value: {},
        activeFilter: false,
    }),
    created() {
        // Set filters
        this.filters.forEach(filter => {
            filter.value = this.defaultValue
        })

        // Document event
        document.addEventListener('click', this.clickEvent)
    },
    methods: {
        setOption(filter, val) {

            filter.value = filter.value === val ? this.defaultValue : val

            // Generate query
            let args = {}
            this.filters.forEach(filter => {
                if (filter.value !== this.defaultValue) {
                    args[filter.type] = filter.value
                }
            })

            this.$emit('change', args)

            this.close();
        },
        close() {
            this.activeFilter = -1
        },
        clickEvent(e) {
            // Close select when clicked outside
            if (!this.$el.contains(e.target)){
                this.close()
            }
        },
    },
    destroyed() {

        // Document event
        document.removeEventListener('click', this.clickEvent)
    }
}

</script>

<style lang="scss">

    /*----------  Filters  ----------*/

    .o-filters {
        --border-width: 2px;
        --border: var(--border-width) solid #{$color-green};

        margin-bottom: 2em;
    }

    .o-filters__list {
        margin-top: var(--border-width);
        margin-left: var(--border-width);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
        width: 100%;
    }

    .o-filters__item {
        margin-top: calc(-1 * var(--border-width));
        margin-left: calc(-1 * var(--border-width));
        border: var(--border);

        &.is-active {

            .o-filters__arrow {
                transform: rotate(180deg);
            }

            .o-dropdown {
                z-index: 50;
                pointer-events: initial;
            }
        }
    }

    .o-filters__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding-right: 2em;
        text-align: left;

        &.has-active {

            .o-filters__label {
                transform: translate(0, -35%);
            }

            .o-filters__active {
                opacity: 1;
                transition: opacity .2s ease-out .1s;
            }
        }
    }

    .o-filters__label {
        transition: transform .2s ease-out;
    }

    .o-filters__active {
        position: absolute;
        bottom: -35%;
        left: 0;
        display: inline-flex;
        align-items: center;
        width: auto;
        max-width: 100%;
        font-size: .8em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        opacity: 0;

        .o-icon {
            margin-left: .5em;
        }

        &:hover {
            opacity: .5;
            transition: opacity .2s ease-out;
        }
    }

    .o-filters__btn {
        display: flex;
        align-items: center;
        width: 100%;
        padding: .75em 1.25em;
        //border-top: var(--border);
        //border-bottom: var(--border);
    }

    .o-filters__icon {
        margin-right: .5em;
    }

    .o-filters__arrow {
        margin-left: auto;
        transition: transform .2s ease-out;
    }


    /*----------  Dropdown  ----------*/

    .o-dropdown {
        z-index: -10;
        position: absolute;
        top: 100%;
        left: calc(-1 * var(--border-width));

        width: calc(100% + var(--border-width) * 2);
        max-height: 17em;
        background-color: $color-grey-light;
        border: var(--border);

        overflow-y: auto;
        pointer-events: none;
    }

    .o-dropdown__item {
        font-size: .9em;

        &:nth-child(n+2) {
            --border-width: 1px;
            border-top: var(--border);
        }

        input {
           display: none;

            &:checked + label {
                color: $color-light;
                background-color: $color-green;
            }
        }

        label {
            display: block;
            width: 100%;
            padding: 1em 2em;
            user-select: none;
            cursor: pointer;
            transition: background-color .2s ease-out;

            &:hover {
                background-color: $color-grey-dark;
            }
        }
    }
</style>
