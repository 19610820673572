
// Basic props
const basics = `
    id
    uri
    uid
    title
    slug
`

// Image sizes
const imageSizes = `
    original : url

    xxs : url @transform (width: 320, immediately: false)
    xs  : url @transform (width: 480, immediately: false)
    sm  : url @transform (width: 640, immediately: false)
    md  : url @transform (width: 768, immediately: false)
    lg  : url @transform (width: 1024, immediately: false)
    xl  : url @transform (width: 1280, immediately: false)
    xxl : url @transform (width: 1440, immediately: false)
    xxxl: url @transform (width: 1600, immediately: false)

    xxs2X : url @transform (width: 320, immediately: false)
    xs2X  : url @transform (width: 480, immediately: false)
    sm2X  : url @transform (width: 640, immediately: false)
    md2X  : url @transform (width: 768, immediately: false)
    lg2X  : url @transform (width: 1024, immediately: false)
    xl2X  : url @transform (width: 1280, immediately: false)
    xxl2X : url @transform (width: 1440, immediately: false)
    xxxl2X: url @transform (width: 1600, immediately: false)
`

// Gallery Image sizes
const galleryImageSizes = `
    original : url

    xxs : url @transform (width: 320,     height: 210,  immediately: false)
    xs  : url @transform (width: 480,     height: 320,  immediately: false)
    sm  : url @transform (width: 640,     height: 425,  immediately: false)
    md  : url @transform (width: 768,     height: 510,  immediately: false)
    lg  : url @transform (width: 1024,    height: 680,  immediately: false)
    xl  : url @transform (width: 1280,    height: 850,  immediately: false)
    xxl : url @transform (width: 1440,    height: 960,  immediately: false)
    xxxl: url @transform (width: 1600,    height: 1065, immediately: false)

    xxs2X : url @transform (width: 320,   height: 210,  immediately: false)
    xs2X  : url @transform (width: 480,   height: 320,  immediately: false)
    sm2X  : url @transform (width: 640,   height: 425,  immediately: false)
    md2X  : url @transform (width: 768,   height: 510,  immediately: false)
    lg2X  : url @transform (width: 1024,  height: 680,  immediately: false)
    xl2X  : url @transform (width: 1280,  height: 850,  immediately: false)
    xxl2X : url @transform (width: 1440,  height: 960,  immediately: false)
    xxxl2X: url @transform (width: 1600,  height: 1065, immediately: false)
`

// Vimeo SuperTable block
const vimeo = `
    vimeo {
        ...on vimeo_BlockType {
            full
            preview
        }
    }
`

// Sponsor entry
const sponsor = `
    selectEntriesSponsor {
        title
        ... on sponsor_sponsor_Entry {
            website
            logo {
                id
                title
                url
            }
        }
    }
`

// Builder blocks (from Matrix)
const builderBlocks = `
    ... on builder_blockAuthors_BlockType {
        blockType: typeHandle
        headline
    }
    ... on builder_blockHeadline_BlockType {
        blockType: typeHandle
        headline
    }
    ... on builder_blockContent_BlockType {
        blockType: typeHandle
        wysiwyg
    }
    ... on builder_blockQuote_BlockType {
        blockType: typeHandle
        quote
    }
    ... on builder_blockVideo_BlockType {
        blockType: typeHandle
        vimeoId
    }
    ... on builder_blockGallery_BlockType {
        blockType: typeHandle
        images: images {
            ${galleryImageSizes}
        }
        display
    }
    ... on builder_blockSpacer_BlockType {
    blockType: typeHandle
        size
    }
`

export default {

    //
    //
    // General
    entriesByAuthor(authorId, limit, offset) {
        return `
            {
                entries(section: ["post","adventure"], authorId: ${authorId}, limit: ${limit}, offset: ${offset}, orderBy: "postDate DESC") {
                    ${basics}
                    postDate
                    authorId
                    sectionHandle
                    author {
                        id
                        fullName
                    }
                    ... on post_chroniques_Entry {
                        category: typeHandle
                        excerpt
                        image: image {
                            ${imageSizes}
                        }
                        selectCategoriesSpecies {
                            ${basics}
                        }
                    }
                    ... on post_capsules_Entry {
                        category: typeHandle
                        excerpt
                        ${vimeo}
                        selectCategoriesSpecies {
                            ${basics}
                        }
                    }
                    ... on adventure_adventure_Entry {
                        excerpt
                        ${vimeo}
                        locationName
                        location {
                            lat
                            lng
                        }
                        selectHunters
                        selectCategoriesSpecies {
                            ${basics}
                        }
                        selectEntrySerie {
                            ${basics}
                        }
                    }
                }
            }
        `
    },

    // Entry with builder
    pageBuilder(id) {
        return `
            {
                entries(id: ${id}) {
                    ... on about_about_Entry {
                        builder {
                            ${builderBlocks}
                        }
                    }
                    ... on contact_contact_Entry {
                        builder {
                            ${builderBlocks}
                        }
                    }
                }
            }
        `
    },

    //
    //
    // Posts
    posts(limit, offset) {

        let _query = `section: "post", limit: ${limit}, offset: ${offset}, orderBy: "postDate DESC"`

        return `
            {
                entries(${_query}) {
                    ${basics}
                    postDate
                    category: typeHandle
                    sectionHandle
                    authorId
                    author {
                        id
                        fullName
                    }
                    ... on post_chroniques_Entry {
                        excerpt
                        seo {
                          title
                        }
                        image: image {
                            ${imageSizes}
                        }
                        selectCategoriesSpecies {
                            ${basics}
                        }
                        selectCategoryLevel {
                            ${basics}
                        }
                    }
                    ... on post_capsules_Entry {
                        excerpt
                        seo {
                          title
                        }
                        ${vimeo}
                        selectCategoriesSpecies {
                            ${basics}
                        }
                        selectCategoryLevel {
                            ${basics}
                        }
                    }
                }
            }
        `
    },

    // Single post
    post(slug) {
        return `
            {
                entries(section: "post", slug: "${slug}") {
                    ${basics}
                    postDate
                    category: typeHandle
                    sectionHandle
                    authorId
                    author {
                        id
                        fullName
                    }
                    ... on post_chroniques_Entry {
                        excerpt
                        seo {
                          title
                        }
                        image: image {
                            ${imageSizes}
                        }
                        builder {
                            ${builderBlocks}
                        }
                        selectCategoriesSpecies {
                            ${basics}
                        }
                    }
                    ... on post_capsules_Entry {
                        excerpt
                        seo {
                          title
                        }
                        ${vimeo}
                        builder {
                            ${builderBlocks}
                        }
                        selectCategoriesSpecies {
                            ${basics}
                        }
                    }
                }
            }
        `
    },


    //
    //
    // Series
    series(limit, offset) {
        return `
            {
                entries(section: "serie", limit: ${limit}, offset: ${offset}, orderBy: "postDate DESC") {
                    ${basics}
                    ... on serie_serie_Entry {
                        excerpt
                        seo {
                          title
                        }
                        relatedAdventures (orderBy: "postDate ASC") {
                            ${basics}
                            section: sectionHandle
                            ... on adventure_adventure_Entry {
                                ${vimeo}
                            }
                        }
                        ${sponsor}
                        ${vimeo}
                    }
                }
            }
        `
    },

    // Serie
    serie(slug) {
        return `
            {
                entries(section: "serie", slug: "${slug}") {
                    ${basics}
                    ... on serie_serie_Entry {
                        body
                        excerpt
                        seo {
                          title
                        }
                        relatedAdventures (orderBy: "postDate ASC") {
                            ${basics}
                            section: sectionHandle
                            ... on adventure_adventure_Entry {
                                ${vimeo}
                            }
                        }
                        ${sponsor}
                        ${vimeo}
                    }
                }
            }
        `
    },


    //
    //
    // Adventures
    adventures(limit, offset) {

        let _query = `section: "adventure", limit: ${limit}, offset: ${offset}, orderBy: "postDate DESC"`

        return `
            {
                entries(${_query}) {
                    ${basics}
                    postDate
                    sectionHandle
                    authorId
                    author {
                        id
                        fullName
                    }
                    ... on adventure_adventure_Entry {
                        excerpt
                        seo {
                          title
                        }
                        ${vimeo}
                        locationName
                        location {
                            lat
                            lng
                        }
                        selectHunters
                        selectCategoriesSpecies {
                            ${basics}
                        }
                        selectEntrySerie {
                            ${basics}
                        }
                    }
                }
            }
        `
    },

    // Single adventure
    adventure(slug) {
        return `
            {
                entries(section: "adventure", slug: "${slug}") {
                    ${basics}
                    postDate
                    sectionHandle
                    authorId
                    author {
                        id
                        fullName
                    }
                    ... on adventure_adventure_Entry {
                        excerpt
                        seo {
                          title
                        }
                        ${vimeo}
                        locationName
                        location {
                            lat
                            lng
                        }
                        selectHunters
                        selectCategoriesSpecies {
                            ${basics}
                        }
                        selectEntrySerie {
                            ${basics}
                        }
                        selectEntriesProducts {
                            ${basics}
                            ... on product_product_Entry {
                                body
                                image: image {
                                    ${imageSizes}
                                }
                            }
                        }
                        selectEntriesPosts {
                            ${basics}
                        }
                        selectCategoriesDevices {
                            ${basics}
                        }
                        builder {
                            ${builderBlocks}
                        }
                    }
                }
            }
        `
    },


    //
    //
    // Users
    users(group, limit, offset) {
        return `
            {
                users(group: "${group}", limit: ${limit}, offset: ${offset}, orderBy: "title ASC") {
                    id
                    uid
                    username
                    name
                    firstName
                    lastName
                    ... on User {
                        body
                        image: image {
                            ${imageSizes}
                        }
                        selectCategoriesSpecialities {
                            ${basics}
                        }
                    }
                }
            }
        `
    },

    // Single user
    user(slug) {
        return `
            {
                users(search: "fullname::${slug.replace('-', '*')}") {
                    id
                    uid
                    username
                    name
                    firstName
                    lastName
                    ... on User {
                        body
                        image: image {
                            ${imageSizes}
                        }
                        selectCategoriesSpecialities {
                            ${basics}
                        }
                        selectCategoriesSpecies {
                            ${basics}
                        }
                    }
                }
            }
        `
    },
    userById(id) {
        return `
            {
                users(id: ${id}) {
                    id
                    uid
                    username
                    name
                    firstName
                    lastName
                    ... on User {
                        body
                        image: image {
                            ${imageSizes}
                        }
                        selectCategoriesSpecialities {
                            ${basics}
                        }
                        selectCategoriesSpecies {
                            ${basics}
                        }
                    }
                }
            }
        `
    },


    //
    //
    // Categories

    // Species
    species() {
        return `
            {
                categories(group: "species", orderBy: "title ASC") {
                    id
                    title
                    slug
                }
            }
        `
    },

    // Levels
    levels() {
        return `
            {
                categories(group: "levels") {
                    id
                    title
                    slug
                }
            }
        `
    },


    globals() {
        return `
        {
            globalSets {
                ...on settings_GlobalSet {
                    socialMedias {
                        ...on socialMedias_BlockType {
                            facebook,
                            instagram,
                            youtube
                        }
                    }
                    mailchimp: mailchimpSettings {
                        ...on mailchimpSettings_BlockType {
                            accountId,
                            listHandle,
                            listId,
                            tokenId
                        }
                    }
                    vimeox: vimeoSettings {
                        ...on vimeoSettings_BlockType {
                            clientId,
                            clientSecret,
                            accessToken
                        }
                    }
                }
            }
        }
        `
    }

}
