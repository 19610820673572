<template>
    <video-plyr
        v-if="block"
        :videoID="block.vimeoId"
        class="b-video"
    />
</template>

<script>

    import VideoPlyr from 'objects/VideoPlyr'

    export default {
        name: 'BlockVideo',
        components: {
            VideoPlyr
        },
        props: {
            block: {
                type: Object | Array
            },
            blockType: {
                type: String
            }
        },
    }
</script>

<style lang="scss">

    .b-video {}

</style>


