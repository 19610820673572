const fetchHunter = function(hunter) {

    if ( typeof hunter !== 'string' )
        return

    const hunters = window.__initialData__.hunters
    const objHunter = hunter.split('-')
    const type = objHunter[0]
    const id = objHunter[1]

    return hunters.find(hunter => hunter.id === id)

}

export default fetchHunter
