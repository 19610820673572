<template>
    <section
        class="l-section"
        :class="className"
        v-view.reveal="1.3"
    >
        <span class="l-section__bg">
            <video-background
                v-if="bgVideo"
                :videoID="bgVideo"
                :cover="true"
            />
        </span>

        <Deco v-if="push === true" class="l-section__deco" />
        <div class="l-section__inner">
            <div class="grid">
                <slot></slot>
            </div>
        </div>
    </section>
</template>

<script>

    import Deco  from 'objects/Deco'
    import VideoBackground  from 'objects/VideoBackground'

    export default {
        name: 'AppSection',
        components: {
            Deco,
            VideoBackground,
        },
        props: {
            color: {
                type: String,
                default: '',
            },
            bgVideo: {
                default: false,
            },
            push: {
                default: false,
            },
        },
        computed: {
            className() {
                let className = this.push ? typeof this.push === 'string' ? `l-section--${this.push}` : 'l-section--push' : ''
                className += this.color ? ` --bg-${this.color}` : ''
                return className
            },
        }
    }

</script>

<style lang="scss">

.l-section {
    --color-bg: transparent;

    padding-top: 10vh;
    padding-bottom: 10vh;
    overflow: hidden;

    &--push {
        overflow: hidden;

        .l-section__asset {
            margin-top: 3em;
        }

        .o-badge {
            right: 1em;
        }
    }

    &--next {
        --color-bg: #{$color-green};
        --color-text: #{$color-light};

        text-align: center;

        .l-section__bg {
            background-position: 50% 50%;
            background-size: cover;

            &:after,
            &:before {
                @include pseudo-el($width: 1px, $height: 10vh, $bg: $color-light);
                z-index: 1;
                position: absolute;
                left: calc(50% - 1px/2);
            }

            &:before {
                top: 0;
            }

            &:after {
                bottom: 0;
            }
        }

        .l-section__inner {
            padding-top: 5vh;
            padding-bottom: 5vh;
        }

        .l-section__title {
            margin-top: .4em;
            margin-bottom: .4em;
        }
    }

    &.js-reveal {

        &.l-section--push,
        &.l-section--next {
            overflow: hidden;

            &:before {
                @include pseudo-el($bg: $color-dark);
                position: absolute;
                top: 0;
                left: 0;
                transform: scale(1, 0);
                transform-origin: 50% 100%;
                will-change: transform;
            }

            .l-section__bg {
                transform: translate(0, 100%);
                will-change: transform;

                &:after,
                &:before {
                    transform: scale(1, 0);
                }

                &:before {
                    transform-origin: 50% 0;
                }

                &:after {
                    transform-origin: 50% 100%;
                }

                .o-video-background {
                    opacity: 0;
                    transform: scale(1.1);
                    will-change: transform, opacity;
                }
            }

            .l-section__inner {
                opacity: 0;
                transform: translate(0, 1em);
            }
        }

        &.is-visible {

            &.l-section--push,
            &.l-section--next {

                &:before {
                    transform: scale(1);
                    transition: transform .8s $out-expo;
                }

                .l-section__bg {
                    transform: translate(0);
                    transition: transform .8s $in-out-quad .2s;

                    &:after,
                    &:before {
                        transform: scale(1);
                        transition: all .3s $out-quad .8s;
                    }

                    .o-video-background {
                        opacity: 1;
                        transform: scale(1);
                        transition: all .3s $in-out-quad .8s;
                    }
                }

                .l-section__inner {
                    opacity: 1;
                    transform: translate(0);
                    transition: all .3s $out-quad .8s;
                }
            }
        }
    }

    &--no-padding {
        padding-top: 0;
        padding-bottom: 0;
    }

    &--pt0 {
        padding-top: 0;
    }

    &--pb0 {
        padding-bottom: 0;
    }

    @media #{md("lg", "max")} {

        &--push {
        }
    }

    @screen lg {

        &--push {

            .l-section__asset {
                padding-right: 3em;
            }

            .o-badge {
                right: 0;
            }
        }
    }
}


.l-section__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    background-color: var(--color-bg, #{$color-green});
}

// .l-section__inner {}

// .l-section__sub {
//     margin-bottom: .2em;
// }

.l-section__title {
    margin-bottom: 1em;
}

.l-section__content {
    padding-top: 2em;
    padding-bottom: 2em;

    > *:nth-child(n+2) {
        margin-top: 1rem;
    }
}

.l-section__article {

    &:nth-child(n+2) {
        margin-top: 6rem;
    }
}

.l-section__deco {
    --deco-left: 50%;
    --deco-bottom: -10%;

    @media #{md("md")} {
    }
}

</style>
