import types from 'store/types'

const state = {
    loading: 0,
    animationState: null,
}

// getters, make function easy to access by vue
//instead
const getters = {
    isLoading: state => state.loading > 0,
    isStarting: state => state.animationState == 'starting',
    isEnding: state => state.animationState == 'ending',
    isEnded: state => state.animationState == 'ended',
    loadCount: state => state.loading,
}

// actions
const actions = {

    startLoad(store) {
        store.commit('updateLoad', store.state.loading + 1)
    },
    endLoad(store) {
        store.commit('updateLoad', store.state.loading - 1)
    },
    resetLoad(store) {
        store.commit('updateLoad', 0)
    },

    updateAnimationState(store, currentState) {
        store.commit('updateAnimationState', currentState)
    },
}

// mutations
const mutations = {

    updateLoad(state, value) {
        state.loading = value
    },

    updateAnimationState(state, value) {
        state.animationState = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
