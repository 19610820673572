<template>
    <app-section
        v-if="serie"
        class="l-section--pt0"
    >
        <div class="grid__row">
            <video-plyr
                v-if="serie.vimeo.full"
                :videoID="serie.vimeo.full"
                :title="serie.title"
            />
        </div>
        <div class="grid__row --justify-center --justify-start@lg">
            <article class="l-section__content | grid__col--12 grid__col--7@md --order-2@md grid__col--7@lg">
                <h2 class="l-section__title | t-poster-80 u-dn@sm">{{ serie.title }}</h2>
                <div class="t-cms">
                    <h4 class="t-heading-20">Synopsis</h4>
                    <div v-html="serie.body"></div>
                </div>
            </article>
            <div class="grid__col--12 --no-gutter grid__col--8@xs grid__col--5@md --order-1@md grid__col--4@lg">

                <div class="p-serie__link-table | t-cms">

                    <h4 class="t-heading-20 | mt-4">Partenaires</h4>

                    <link-table
                        v-if="serie.sponsors"
                        :items="serie.sponsors"
                    />
                </div>
            </div>
        </div>
        <slider :title="`${episodes} épisodes`">
            <div
                v-for="(episode, i) in serie.episodes"
                :key="`episode-${i}`"
                class="c-slider__slide"
            >
                <card-video
                    :entry="episode"
                    :title="episode.title"
                    button="Visionner l’épisode"
                    :url="`/aventures/${episode.slug}`"
                    :videoID="episode.vimeo[0].preview"
                    revealDirection="right"
                    :ratio="1"
                />
            </div>
        </slider>
    </app-section>
</template>

<script>
    import AppSection from 'layout/AppSection'
    import VideoPlyr from 'objects/VideoPlyr'
    import Asset from 'objects/Asset'
    import LinkTable from 'objects/LinkTable'
    import Slider from 'components/Slider'
    import CardVideo from 'components/CardVideo'

    import types from 'store/types'

    import 'src/filters/uscape'

    export default {
        name: 'Serie',
        components: {
            AppSection,
            VideoPlyr,
            Asset,
            LinkTable,
            Slider,
            CardVideo,
        },
        metaInfo() {
            return {
                title: this.serie ? this.$options.filters.uscape(this.serie.seo.title) : null,
            }
        },
        computed:{
            serie() {
                return this.$store.getters.getSerieBySlug(this.$route.params.entrySlug)
            },
            episodes() {
                return this.serie.episodes.length
            }
        },
        created() {
            if (this.isEmpty(this.$route.params.entrySlug)) {
                return this.$router.push(-1)
            }

            // Load serie
            this.$store.dispatch(types.LOAD_SERIE_BY_SLUG, this.$route.params.entrySlug)
        }
    }
</script>

<style lang="scss">

    .p-serie__link-table {
        background-color: $color-light;
        padding-top: 2em;
        padding-bottom: 2em;
        @include grid-breakpoints(padding-right, .5);
        @include grid-breakpoints(padding-left, .5);
    }

</style>
