import Vue from 'vue'

Vue.filter('kebab', function (value) {
	if (!value) return ''

    // To string
	value = value.toString()

    return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                .replace(/([A-Z])([A-Z])/g, '$1-$2')
                .replace(/([a-z])([A-Z])/g, '$1-$2')
                .replace(/[\s_]+/g, '-')
                .toLowerCase()

})
