const GLOBALS = 'GLOBALS'

const LOAD_PAGE = 'LOAD_PAGE'
const PRELOAD_PAGES = 'PRELOAD_PAGES'
const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA'

const LOAD_ENTRIES_BY_USER = 'LOAD_ENTRIES_BY_USER'

const LOAD_POSTS = 'LOAD_POSTS'
const LOAD_POST_BY_SLUG = 'LOAD_POST_BY_SLUG'

const LOAD_SERIES = 'LOAD_SERIES'
const LOAD_SERIE_BY_SLUG = 'LOAD_SERIE_BY_SLUG'

const LOAD_ADVENTURES = 'LOAD_ADVENTURES'
const LOAD_ADVENTURE_BY_SLUG = 'LOAD_ADVENTURE_BY_SLUG'

const LOAD_ALL_GUIDES = 'LOAD_ALL_GUIDES'
const LOAD_USER_BY_SLUG = 'LOAD_USER_BY_SLUG'
const LOAD_USER_BY_ID = 'LOAD_USER_BY_ID'

const LOAD_ALL_AUTHORS = 'LOAD_ALL_AUTHORS'

export default {
    GLOBALS,
    LOAD_PAGE,
    PRELOAD_PAGES,
    FETCH_INITIAL_DATA,
    LOAD_ENTRIES_BY_USER,
    LOAD_POSTS,
    LOAD_POST_BY_SLUG,
    LOAD_SERIES,
    LOAD_SERIE_BY_SLUG,
    LOAD_ADVENTURES,
    LOAD_ADVENTURE_BY_SLUG,
    LOAD_ALL_GUIDES,
    LOAD_USER_BY_SLUG,
    LOAD_USER_BY_ID,
    LOAD_ALL_AUTHORS
}
