<template>
    <div class="c-slider">
        <div
            v-if="title || prevNextButtons"
            class="c-slider__top"
        >
            <h3
                v-if="title"
                class="c-slider__title | t-heading-20"
            >
                {{ title }}
            </h3>
            <div
                v-if="prevNextButtons"
                class="c-slider__nav"
            >
                <button
                    @click="$refs.flkty.previous()"
                    class="c-slider__btn c-slider__btn--prev"
                    :class="{ 'is-disabled' : currentSlide === 1}"
                >
                    <icon icon="arrow-left-long" />
                </button>
                <button
                    @click="$refs.flkty.next()"
                    class="c-slider__btn c-slider__btn--next"
                    :class="{ 'is-disabled' : currentSlide === totalSlides}"
                >
                    <icon icon="arrow-right-long" />
                </button>
            </div>
        </div>
        <flickity
            :options="flickityOpts"
            class="c-slider__flkty"
            :class="{ 'is-in-movement': isDragging }"
            ref="flkty"
        >
            <slot>
                <div
                    v-for="(slide, i) in slides"
                    :key="`slide-${i}`"
                    class="c-slider__slide"
                >
                </div>
            </slot>
        </flickity>
    </div>
</template>

<script>

    import Icon from 'objects/Icon'

    import Flickity from 'vue-flickity';

    export default {
        name: 'Slider',
        components: {
            Icon,
            Flickity,
        },
        props: {
            slides: {
                type: Array
            },
            title: {
                type: String,
                default: null
            },
            prevNextButtons: {
                type: Boolean,
                default: true
            },
        },
        data: () => ({
            flickityOpts: {
                contain: true,
                prevNextButtons: false,
                pageDots: false
            },
            totalSlides: 0,
            currentSlide: 1,
            isDragging: false,
        }),
        mounted() {
            // Set total slides
            this.totalSlides = this.$refs.flkty.slides().length

            const flkty = this.$refs.flkty

            // Update current slide on flickity select
            flkty.on('select', (i) => {
                this.currentSlide = i + 1
            })

            flkty.on('dragStart', () => this.isDragging = true );
            flkty.on('dragEnd', () => this.isDragging = false );

        },
        methods: {
            resize() {
                this.$refs.flkty.resize()
            }
        }
    }
</script>

<style lang="scss">

    .c-slider {
        margin-top: 5vh;

        &__flkty {

            &.is-in-movement {

                .c-slider__slide {
                    pointer-events: none;
                }
            }
        }

        &--gear {
            margin-top: 0;

            .c-slider__slide {
                display: flex;
                align-items: center;
                margin-right: 2em;
            }

            .c-slider__img {
                width: 20%;
                height: 23rem;
                margin-right: 2em;

                object-fit: contain;
                object-position: top;
            }

            .c-slider__content {
                align-self: flex-start;
            }

            @supports (mix-blend-mode:multiply) {
                mix-blend-mode: multiply
            }
        }

        @media #{md("xs", "max")} {

            &--gear {
                margin-top: 1em;
            }
        }
    }

    .c-slider__slide {
        width: 100%;

        &:nth-child(n+2) {
            margin-left: 1em;
        }

        @media #{md("xs")} {
            width: calc(91.5% - 2em);
        }

        @media #{md("sm")} {
            width: 70%;
        }

        @media #{md("md")} {
            width: 55%;
        }

        @media #{md("lg")} {
            width: 45%;
        }
    }

    .c-slider__top {
        display: flex;
        align-items: flex-end;
        margin-bottom: .5em;

        @media #{md("md")} {
            margin-bottom: 1em;
        }
    }

    .c-slider__title {
        width: 100%;
        margin-bottom: .3rem;
    }

    .c-slider__content {

        > *:nth-child(n+2) {
            margin-top: .5rem;
        }
    }

    .c-slider__nav {
        display: flex;
        margin-left: auto;
    }

    .c-slider__btn {
        display: block;
        padding-top: .5em;
        padding-bottom: .5em;

        &--prev {}

        &--next {
            margin-left: 1em;
        }

        &.is-disabled {
            opacity: 0.25;
        }
    }

    /* UX fixes */
    .flickity-viewport {
        overflow: visible;
    }

</style>
