import Vue from 'vue'
import isEmpty from 'lodash/isEmpty'
import 'src/filters/title'
import fetchHunter from 'src/utils/fetchHunter'

const parse = function(data) {

    let entry = {}
    let _entry = data

    // subObjects
    let category = {}
    let location = {}

    // Loop through fields
    for (let field in _entry) {

        let value = _entry[field];

        // Switch to build the new Object (in A-Z order).
        switch(true) {

            case ( field === 'author' ):
                entry.author = value
                break;

            case ( field === 'authorId' ):
                entry.authorId = value
                break;

            case ( field === 'builder' ):
                entry.builder = value
                break;

            case ( field === 'category' ):
                category.name = Vue.options.filters.title(value)
                category.handle = value
                break;

            case ( field === 'body' ):
                entry.body = value
                break;

            case ( field === 'excerpt' ):
                entry.excerpt = value
                break;

           case ( field === 'firstName' ):
                entry.firstName = value
                break;

            case ( field === 'id' ):
                entry.id = parseInt(value)
                break;

            case ( field === 'image' ):
                entry.image = value[0]
                break;

           case ( field === 'lastName' ):
                entry.lastName = value
                break;

            case ( field === 'locationName' ):
                location.name = value
                break;

            case ( field === 'location' ):
                location.lat = value.lat
                location.lng = value.lng
                break;

            case ( field === 'mailchimp' ):
                entry.mailchimp = ( !isEmpty(value) ) ? value[0] : null
                break;

           case ( field === 'name' ):
                entry.name = value
                break;

            case ( field === 'postDate' ):
                entry.date = value
                break;

            case ( field === 'relatedAdventures' ):
                entry.episodes = value
                break;

            case ( field === 'sectionHandle' ):
                entry.section = value
                break;

            case ( field === 'selectCategoryPeriod' ):
                entry.period = ( !isEmpty(value) ) ? value[0] : null
                break;

            case ( field === 'selectCategoriesDevices' ):
                entry.devices = value
                break;

            case ( field === 'selectCategoriesSpecialities' ):
                entry.specialities = value
                break;

            case ( field === 'selectCategoriesSpecies' ):
                entry.species = value
                break;

            case ( field === 'selectCategoryLevel' ):
                entry.level = ( !isEmpty(value) ) ? value[0] : null
                break;

            case ( field === 'selectEntriesProducts' ):
                entry.products = value
                break;

            case ( field === 'selectEntriesPosts' ):
                entry.posts = value
                break;

            case ( field === 'selectEntriesSponsor' ):
                const _value = value.map(s => ({
                    title : s.title,
                    website : s.website,
                    logo : ( !isEmpty(s.logo) ) ? s.logo[0] : null
                }))
                entry.sponsors = _value
                break;

            case ( field === 'selectHunters' ):

                let _hunters = ( !isEmpty(value) ) ? JSON.parse(value) : null

                if ( _hunters !== null ) {

                    _hunters = _hunters.filter(Boolean).map(hunter => {
                        return fetchHunter(hunter)
                    })
                }

                entry.hunters = _hunters

                break;

            case ( field === 'selectEntrySerie' ):
                entry.serie = ( !isEmpty(value) ) ? value[0] : null
                break;

            case ( field === 'seo' ):
                entry.seo = value
                break;

            case ( field === 'slug' ):
                entry.slug = value
                break;

            case ( field === 'socialMedias' ):
                entry.socialMedias = ( !isEmpty(value) ) ? value[0] : null
                break;

            case ( field === 'title' ):
                entry.title = value
                break;

            case ( field === 'uid' ):
                entry.uid = value
                break;

            case ( field === 'uri' ):
                entry.uri = value
                break;

            case ( field === 'username' ):
                entry.username = value
                break;

            case ( field === 'vimeo' ):
                entry.vimeo = ( !isEmpty(value) ) ? value[0] : null
                break;

            case ( field === 'vimeo' ):
                entry.vimeoId = value
                break;

            case ( field === 'website' ):
                entry.website = value
                break;


        }
    }

    // Merge subObjects to entry
    if ( typeof category !== "undefined" && !isEmpty(category) )
        entry.category = category;

    if ( typeof location !== "undefined" && !isEmpty(location) )
        entry.location = location;

    // Return
    return entry;

}

export default parse
