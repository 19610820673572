import axios from "axios";
import types from "store/types";
import apiMethods from "services/api/methods";

import parse from "services/api/parse";
import merge from "lodash/merge";

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__));
const state = {
    pages: initData.pages || [],
    nav: []
};

// getters, make function easy to access by vue
//instead
const getters = {
    getPageById: state => id =>
        state.pages.find(page => {
            if (typeof page !== "object") return;
            return page.id === id;
        }),
    getPageBySlug: state => slug =>
        state.pages.find(page => {
            if (typeof page !== "object") return;
            return page.slug === slug;
        }),
    getPagesBySlugs: (state, getters) => slugs => {
        const items = [];
        let page;

        slugs.forEach(slug => {
            page = getters.getPageBySlug(slug);
            if (typeof page !== "undefined") {
                items.push(page);
            }
        });

        return items;
    }
};

// actions
const actions = {
    [types.PRELOAD_PAGES](store, pagesIds) {
        pagesIds.forEach(id => {
            store.dispatch(types.LOAD_PAGE, id).catch(err => {
                console.error("err", err);
            });
        });
    },
    [types.LOAD_PAGE](store, pageId) {
        // Start loader
        store.dispatch("loader/startLoad", null, { root: true });

        let existingPage = store.getters.getPageById(pageId);

        if (existingPage && existingPage.isSingleFetched) {
            store.dispatch("loader/endLoad", null, { root: true });
            return Promise.resolve(existingPage);
        }

        return new Promise((resolve, reject) => {
            axios
                .get(`/actions/module/page/get-page?id=${encodeURI(pageId)}`)
                .then(data => {
                    if (typeof data.page === "undefined") {
                        reject();
                        return;
                    }

                    apiMethods.getPageBuilder(pageId).then(page => {
                        // Merge builder if it exists
                        if (page.builder) data.page.builder = page.builder;

                        // Mark page has single fetched
                        data.page.isSingleFetched = true;

                        store.commit(types.LOAD_PAGE, data.page);
                        resolve(data);
                    });
                });
        });
    }
};

// mutations
const mutations = {
    [types.LOAD_PAGE](state, page) {
        let idx = state.pages.findIndex(p => p.id === page.id);

        // If page is not found, add it to the a store
        if (idx === -1) {
            state.pages.push(page);

            // If not, replace it
        } else {
            state.pages[idx] = page;
        }

        // End loader
        this.dispatch("loader/endLoad", null, { root: true });
    }
};

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
};
