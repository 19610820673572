<template>
    <div v-if="author" class="l-author">
        <app-section
            color="gold"
        >
            <div class="grid__row --items-start --justify-end">
                <div class="p-header | t-content | grid__col--12 grid__col--6@md">
                    <h2 class="t-poster-80">{{ author.name }}</h2>
                    <div
                        v-html="author.body"
                        class="t-cms"
                    ></div>
                    <tags
                        v-if="author.specialities"
                        :tags="author.specialities"
                        color="light"
                        class="mt-4"
                    />
                </div>
                <div
                    v-if="author.image"
                    class="p-cover | grid__col--12 grid__col--6@md --no-gutter">
                    <asset
                        :src="author.image"
                        :alt="author.name"
                    />
                </div>
            </div>

        </app-section>

        <app-section>
            <card-grid
                v-if="entries && entries.length > 0"
                :cards="entries"
            />
            <div
                v-else
                key="no-results"
                class="c-card-grid__empty"
            >
                <h2 class="t-poster-40">{{ author.name }} n’a pas écrit d’articles pour le moment</h2>
            </div>

        </app-section>

    </div>
</template>

<script>

    import types        from 'store/types'
    import merge        from 'lodash/merge'
    import isEmpty      from 'lodash/isEmpty'

    import Hero         from 'layout/Hero'
    import AppSection   from 'layout/AppSection'
    import CardGrid     from 'components/CardGrid'
    import Tags         from 'objects/Tags'
    import Asset        from 'objects/Asset'

    export default {
        name: 'Author',
        components: {
            Hero,
            AppSection,
            CardGrid,
            Tags,
            Asset
        },
        metaInfo() {
            return {
                title: this.author ? `${this.author.name} | Chasse Québec` : null,
            }
        },
        computed:{
            author() {
                return this.$store.getters.getUserBySlug(this.$route.params.userName)
            },
            entries() {

                if ( !this.author )
                    return

                if ( !this.author.entries )
                    return

                return this.author.entries.map(entry => ({
                    ...entry,
                    body: {
                        title: entry.title,
                        excerpt: entry.excerpt
                    },
                    button: {
                        label: 'Lire la suite',
                        to: entry.url
                    }
                }))
            }
        },
        created() {

            if ( this.isEmpty(this.$route.params.userName) )
                return this.$router.push(-1)

            this.$store.dispatch(types.LOAD_ENTRIES_BY_USER, {slug: this.$route.params.userName, user_type: 'author'})
        }
    }
</script>

<style lang="scss">

    .l-author {
        .o-tags {
            margin-left: 0;
        }

    }

</style>

