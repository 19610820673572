import Vue from 'vue'

export const EventBus = new Vue();

export const EVENTS = {
}


//EventBus.$emit(EVENTS.<>, payload)
//EventBus.$on(EVENTS.<>, functionName)
//beforeDestroy => EventBus.$off(EVENTS.<>, functionName)
