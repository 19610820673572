<template>
    <div>

        <hero
            v-if="page"
            :title="page.title"
            :color="page.fields.color.label"
            :asset="page.fields.backgroundImage ? page.fields.backgroundImage.transform.large : ''"
            badgeIcon=""
        />

        <app-section>

            <div class="grid__row">
                <div class="grid__col--12 grid__col--8@lg grid__col--7@xxl">
                    <h2 class="l-section__title | t-poster-80">{{ title }}</h2>
                </div>
            </div>

<!--             <h2 class="l-section__title | t-poster-80">Tous les guides</h2> -->

            <card-grid
                v-if="authors"
                :cards="authors"
                :ratio="{ 0: 1, 480: 4/3, 768: 5/4 }"
            />

        </app-section>
    </div>
</template>

<script>

    import types   from 'store/types'
    import isEmpty from 'lodash/isEmpty'

    import Hero         from 'layout/Hero'
    import AppSection   from 'layout/AppSection'
    import CardGrid     from 'components/CardGrid'
    import Tags         from 'objects/Tags'
    import Asset        from 'objects/Asset'

    import 'src/filters/truncate'

    export default {
        name: 'Authors',
        components: {
            Hero,
            AppSection,
            CardGrid,
            Tags,
            Asset
        },
        data: () => {
            return {
                authors: null,
                title: 'Nos artisans',
            }
        },
        metaInfo() {
            return {
                title: this.page.seo.title,
            }
        },
        computed: {

            page() {
                let pageId = this.$route.meta.id
                let page = this.$store.getters.getPageById(pageId)
                if (typeof page === 'undefined') {
                    return null
                }
                return page
            }
        },
        methods: {
        },
        created() {
            this.$store.dispatch(types.LOAD_ALL_AUTHORS)
                .then((authors) => {

                    this.authors = authors.map(author => ({
                        ...author,

                        body: {
                            title: author.name,
                            excerpt:  this.$options.filters.truncate(author.body, 190)
                        },
                        tags: author.specialities,
                        button: {
                            label: 'Voir les articles',
                            to: `/artisans/${author.slug}`
                        }
                    }))
                })

        }
    }
</script>

