import Vue from 'vue'
import axios from 'axios'
import apiActions from 'config/api-actions'
import apiQuery from 'config/graphql-query'
import parse from 'services/api/parse'
import merge from 'lodash/merge'

import 'src/filters/kebab'

export default {

    //
    // Get an author entries (both adventures and posts)
    getEntriesByAuthor(id = null, limit = 12, offset = 0) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.entriesByAuthor(id, limit, offset)
        })
            .then(data => {
                return data.data.entries || []
            })
            .then(posts => {
                return posts.map(p => merge({}, parse(p), {
                    isSingleFetched: false,
                    url: `/${p.uri}`,
                }))
            })
    },


    // Get entry builder
    getPageBuilder(id) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.pageBuilder(id)
        })
            .then(data => {
                return data.data.entries[0] || []
            })
            .then(page => {

                // Return something only if the API output is valid
                if ( page.length !== 0 ) {
                    return parse(page)
                }
            })
    },

    //
    // Get posts
    getPosts(limit = 12, offset = 0) {

        return axios.post(apiActions.graphQL, {


            query: apiQuery.posts(limit, offset)
        })
            .then(data => {
                return data.data.entries || []
            })
            .then(posts => {
                return posts.map(p => merge({}, parse(p), {
                    isSingleFetched: false,
                    url: `/${p.uri}`,
                }))
            })
    },

    // Get single post
    getPost(slug) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.post(slug)
        })
            .then(data => {
                return data.data.entries[0] || []
            })
            .then(post => {

                // Return something only if the API output is valid
                if ( post.length !== 0 ) {
                    return merge({}, parse(post), {
                        isSingleFetched: false,
                        url: `/${post.uri}`,
                    })
                }
            })
    },


    //
    // Get series
    getSeries(limit = 12, offset = 0) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.series(limit, offset)
        })
            .then(data => {
                return data.data.entries || []
            })
            .then(series => {
                return series.map(p => merge({}, parse(p), {
                    isSingleFetched: false,
                    url: `/${p.uri}`,
                }))
            })
    },

    // Get single serie
    getSerie(slug) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.serie(slug)
        })
            .then(data => {
                return data.data.entries[0] || []
            })
            .then(serie => {

                // Return something only if the API output is valid
                if ( serie.length !== 0 ) {
                    return merge({}, parse(serie), {
                        isSingleFetched: false,
                        url: `/${serie.uri}`,
                    })
                }
            })
    },


    //
    // Get adventures
    getAdventures(limit = 12, offset = 0) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.adventures(limit, offset)
        })
            .then(data => {
                return data.data.entries || []
            })
            .then(adventures => {
                return adventures.map(p => merge({}, parse(p), {
                    isSingleFetched: false,
                    url: `/${p.uri}`,
                }))
            })
    },

    // Get single adventure
    getAdventure(slug) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.adventure(slug)
        })
            .then(data => {
                return data.data.entries[0] || []
            })
            .then(adventure => {

                // Return something only if the API output is valid
                if ( adventure.length !== 0 ) {

                    return merge({}, parse(adventure), {
                        isSingleFetched: false,
                        url: `/${adventure.uri}`,
                    })
                }
            })
    },


    //
    // Get guides
    getGuides(limit = 12, offset = 0) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.users('guide', limit, offset)
        })
            .then(data => {
                return data.data.users || []
            })
            .then(guides => {

                return guides.map(p => merge({}, parse(p), {
                    type: 'guide',
                    isSingleFetched: false,
                    slug: Vue.options.filters.kebab(p.name),
                    url: `/guides/${Vue.options.filters.kebab(p.name)}`
                }))
            })
    },

    // Get user
    getUser(value, type = 'slug', user_type = 'guide') {

        const _query = (type === 'slug' ) ? apiQuery.user(value) : apiQuery.userById(value)

        return axios.post(apiActions.graphQL, {
            query: _query
        })
            .then(data => {
                return (data.data.users && data.data.users[0]) ? data.data.users[0] : []
            })
            .then(user => {

                // Return something only if the API output is valid
                if ( user.length !== 0 ) {

                    let path = (user_type === 'author') ? 'artisans' : 'guides'
                    let slug = Vue.options.filters.kebab(user.name)

                    return merge({}, parse(user), {
                        type: user_type,
                        isSingleFetched: false,
                        slug: slug,
                        url: `/${path}/${slug}`
                    })
                }
            })
    },

    //
    // Get authors
    getAuthors(limit = 12, offset = 0) {

        return axios.post(apiActions.graphQL, {
            query: apiQuery.users('author', limit, offset)
        })
            .then(data => {
                return data.data.users || []
            })
            .then(authors => {
                return authors.map(p => merge({}, parse(p), {
                    type: 'author',
                    isSingleFetched: false,
                    slug: Vue.options.filters.kebab(p.name),
                    url: `/artisans/${Vue.options.filters.kebab(p.name)}`
                }))
            })
    },

/*
    // Get author
    getAuthor(value, type = 'slug') {

        const _query = (type === 'slug' ) ? apiQuery.user(value) : apiQuery.userById(value)

        return axios.post(apiActions.graphQL, {
            query: _query
        })
            .then(data => {
                return data.data.users[0] || []
            })
            .then(author => {

                // Return something only if the API output is valid
                if ( author.length !== 0 ) {
                    return merge({}, parse(author), {
                        isSingleFetched: false,
                        slug: Vue.options.filters.kebab(author.name)
                    })
                }
            })
    },
*/


    //
    //
    // Categories

    // Species
    getSpecies() {

        return axios.post(apiActions.graphQL, {
                query: apiQuery.species()
            })
            .then(data => {
                return data.data.categories || []
            })
    },


    // Levels
    getLevels() {

        return axios.post(apiActions.graphQL, {
                query: apiQuery.levels()
            })
            .then(data => {
                return data.data.categories || []
            })
    },


    //
    //
    // Globals
    getGlobals() {

        return axios.post(apiActions.graphQL, {
                query: apiQuery.globals()
            })
            .then(data => {
                return data.data.globalSets[0] || []
            })
            .then(settings => {
                return merge({}, parse(settings))
            })
    },
}
