<template>
    <div>

        <hero
            v-if="page"
            :title="page.title"
            :color="page.fields.color.label"
            :asset="page.fields.backgroundImage ? page.fields.backgroundImage.transform.large : ''"
            :tabs="tabs"
            badgeIcon="round-adventures"
        />

        <app-section
            v-if="featured"
        >
            <div>
                <Deco class="p-adventures__deco" />
                <article class="grid__row | --items-center --justify-center">
                    <div class="grid__col--12 grid__col--6@md grid__col--4@lg --no-gutter --gutter@xs --no-gutter@md">
                        <card-video :videoID="featured.vimeo.preview" />
                    </div>
                    <div class="grid__col--12 grid__col--6@md">
                        <div class="u-boxed | t-content">
                            <h2 class="t-heading-20">Aventure en vedette</h2>
                            <adventure-label
                                :adventure="featured"
                            />
                            <h3 class="t-poster-60" v-html="featured.title"></h3>
                            <btn
                                v-if="featured.button"
                                tag="router-link"
                                :label="featured.button.label"
                                :iconBefore="featured.button.iconBefore"
                                color="orange"
                                :href="featured.button.to"
                            />
                        </div>
                    </div>
                </article>
            </div>

            <card-grid
                v-if="adventures.length > 0"
                :cards="adventures"
                :filters="true"
            />

        </app-section>

        <push
            v-if="page.fields.cta"
            :push="page.fields.cta"
        />

    </div>
</template>

<script>

    import types from 'store/types'
    import merge from 'lodash/merge'

    import AppSection  from 'layout/AppSection'
    import Hero        from 'layout/Hero'

    import AdventureLabel   from 'objects/AdventureLabel'
    import Asset            from 'objects/Asset'
    import Deco            from 'objects/Deco'
    import Badge            from 'objects/Badge'

    import Btn        from 'components/Btn'
    import Push        from 'components/Push'
    import CardGrid   from 'components/CardGrid'
    import CardVideo  from 'components/CardVideo'

    export default {
        name: 'Adventures',
        components: {
            AppSection,
            Hero,
            AdventureLabel,
            Asset,
            Deco,
            Badge,
            Btn,
            Push,
            CardGrid,
            CardVideo
        },
        data: () => ({
            adventures: [],
            featured: null
        }),
        metaInfo() {
            return {
                title: this.page.seo.title,
            }
        },
        computed: {

            page() {
                let pageId = this.$route.meta.id
                let page = this.$store.getters.getPageById(pageId)
                if (typeof page === 'undefined') {
                    return null
                }
                return page
            },

            // Tabs (count)
            tabs() {
                const _adventures = this.$store.state.data.count.adventures
                const _guides = this.$store.state.data.count.guides

                return [
                    `${_adventures} aventures`,
                    `${_guides} guides`
                ]
            }
        },
        created() {

            // Fetch page if not found in store
            if (!this.page) {
                this.$store.dispatch(types.LOAD_PAGE, this.$router.currentRoute.meta.id)
            }

            // Load all adventures
            this.$store
                .dispatch(types.LOAD_ADVENTURES, {limit: -1})
                .then(adventures => {

                    this.adventures = adventures.map(adventure => ({
                        ...adventure,
                        body: {
                            title: adventure.title,
                            excerpt: adventure.excerpt
                        },
                        button: {
                            label: 'Découvrez l’aventure',
                            to: adventure.url,
                            iconBefore: 'play'
                        }
                    }))
                })
                    .then(adventures => {

                        let featuredAdventure = this.page.fields.selectEntryAdventure

                        if (featuredAdventure) {

                            const _featured = this.$store.getters.getAdventureBySlug(featuredAdventure.slug)

                            this.featured = merge(_featured, {
                                button: {
                                    label: 'Lire la suite',
                                    to: _featured.url,
                                }
                            })
                        }
                    })
        }
    }
</script>
