<template>
    <div class="b-builder">
        <component
            v-if="blocks"
            v-for="(block, key, i) in blocks"
            :key="key"
            :is="block.blockType"
            :block="block"
            :blockType="block.blockType|kebab"
        />
    </div>
</template>


<script>
    import BlockAuthors     from "templates/builder/BlockAuthors";
    import BlockContent     from "templates/builder/BlockContent";
    import BlockGallery     from "templates/builder/BlockGallery";
    import BlockHeadline    from "templates/builder/BlockHeadline";
    import BlockQuote       from "templates/builder/BlockQuote";
    import BlockSpacer      from "templates/builder/BlockSpacer";
    import BlockVideo       from "templates/builder/BlockVideo";

    import 'src/filters/kebab'

    export default {
        name: 'BuilderLoop',
        components: {
            BlockAuthors,
            BlockContent,
            BlockGallery,
            BlockHeadline,
            BlockQuote,
            BlockSpacer,
            BlockVideo,
        },
        props: {
            blocks: {
                type: Array | Object
            }
        },
    }
</script>

<style lang="scss">

    .b-builder {

        > * {

            &:nth-child(n+2) {
                margin-top: 2rem;
            }
        }
    }

</style>
