<template>
    <div v-if="page">

        <hero
            v-if="page.fields"
            :title="page.title"
            :color="page.fields.color.label"
            :asset="page.fields.backgroundImage ? page.fields.backgroundImage.transform.large : ''"
            :tabs="tabs"
            badgeIcon=""
        />

        <app-section v-if="page.builder" class="l-section--pt0">
            <div class="grid__row --justify-center">
                <article class="grid__col--12 grid__col--7@md grid__col--7@lg">
                    <builder-loop :blocks="page.builder" />
                </article>
            </div>
        </app-section>

        <app-section class="l-section--pt0">
            <div class="grid__row | --justify-center">
                <div class="grid__col--12 grid__col--7@md grid__col--7@lg">
                    <contact-form />
                </div>
            </div>
        </app-section>

        <app-section
            v-if="page.fields && page.fields.cta"
            :push="true"
            color="gold"
        >

            <div class="grid__row | --justify-around --items-center">
                <div class="grid__col--12 grid__col--6@md grid__col--5@lg grid__col--4@xl">
                    <div class="t-content">
                        <h2 class="t-poster-60">{{ page.fields.cta.heading }}</h2>
                        <div
                            v-html="page.fields.cta.content"
                            class="t-cms"></div>

                        <btn
                            v-if="page.fields.cta.button"
                            tag="router-link"
                            :label="page.fields.cta.button.label"
                            :href="page.fields.cta.button.url"
                            :iconAfter="page.fields.cta.button.icon ? page.fields.cta.button.icon : ''"
                            format="simple"
                        />
                    </div>
                </div>
                <div class="grid__col--12 grid__col--6@md grid__col--4@lg --no-gutter">
                    <div class="l-section__asset">
                        <asset
                            v-if="page.fields.cta.image"
                            :src="page.fields.cta.image"
                        />
                        <badge icon="round-guides" />
                    </div>
                </div>
            </div>

        </app-section>

    </div>
</template>

<script>

    import types from 'store/types'

    import BuilderLoop  from 'templates/builder/BuilderLoop'

    import AppSection   from 'layout/AppSection'
    import Hero         from 'layout/Hero'

    import Asset        from 'objects/Asset'
    import Badge        from 'objects/Badge'

    import Btn          from 'components/Btn'
    import ContactForm  from 'components/ContactForm'

    export default {
        name: 'Contact',
        components: {
            AppSection,
            BuilderLoop,
            Hero,
            Asset,
            Badge,
            Btn,
            ContactForm
        },
        data: () => ({
            page: null
        }),
        metaInfo() {
            return {
                title: this.page.seo.title,
            }
        },
        computed: {

            // Tabs (count)
            tabs() {

                return [
                    `Questions`,
                    `Commentaires`
                ]
            }
        },
        created() {

            let pageId = this.$route.meta.id
            let page   = this.$store.getters.getPageById(pageId)

            // Force a builder on this View
            if (typeof page !== 'undefined' && typeof page.builder !== 'undefined')
                this.page = page

            // If this.page is still not defined, fetch it
            if ( !this.page ) {
                this.$store.dispatch(types.LOAD_PAGE, pageId)
                    .then((data) => {
                        this.page = data.page
                    })
            }
        }
    }
</script>
