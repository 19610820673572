<template>
    <div
        v-if="socials"
        class="o-socials"
    >
        <h4 class="o-socials__title | t-heading-20">
            <span>Suivez-nous</span>
        </h4>
        <ul class="o-socials__list">
            <li
                v-for="(url, name) in socials"
                :key="name"
                class="o-socials__item"
            >
                <a
                    v-if="url"
                    :href="url"
                    :title="name"
                    target="_blank"
                    rel="noopener"
                    class="o-socials__link"
                >
                    <icon :icon="name" />
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import Icon from 'objects/Icon'

    export default {
        name: 'Socials',
        components: {
            Icon,
        },
        computed: {

            socials() {

                let _socials = this.$store.state.data.settings.socialMedias

                if ( _socials )
                    return _socials
            }
        }
    }
</script>

<style lang="scss">

.o-socials {}
.o-socials__title {
    display: block;
}

.o-socials__list {
    display: flex;
    align-items: center;
    margin-top: .5em;
}

.o-socials__item {

    &:first-child {
        margin-left: -.7em;
    }

    &:nth-child(n+2) {
        margin-left: 0.5em;
    }
}
.o-socials__link {
    display: flex;
    width: 2.5em;
    height: 2.5em;
    transition: color .2s ease-out;

    &:hover {
        color: $color-light;
    }

    .o-icon {
        margin: auto;
    }
}

</style>
