<template>
    <app-section
        :push="true"
        :color="push.color ? push.color.label : 'orange'"
    >
        <div class="grid__row | --justify-around --items-center">
            <div class="grid__col--12 grid__col--6@md grid__col--5@lg grid__col--4@xl">
                <div class="t-content">
                    <h2 class="t-poster-60">{{ push.heading }}</h2>
                    <div
                        v-html="push.content"
                        class="t-cms"></div>

                    <btn
                        v-if="push.button.url"
                        tag="router-link"
                        :label="push.button.label"
                        :href="push.button.url"
                        :iconAfter="push.button.icon ? push.button.icon : ''"
                        format="simple"
                    />
                </div>
            </div>
            <div class="grid__col--12 grid__col--6@md grid__col--4@lg --no-gutter">
                <div
                    v-if="push.image"
                    class="l-section__asset"
                >
                    <asset :src="push.image"/>
                    <badge :icon="`round-${push.slug}`" />
                </div>
            </div>
        </div>

    </app-section>
</template>

<script>

    import AppSection from 'layout/AppSection'
    import Btn from 'components/Btn'
    import Asset from 'objects/Asset'
    import Badge from 'objects/Badge'

    export default {
        name: 'Push',
        components: {
            AppSection,
            Btn,
            Asset,
            Badge,
        },
        props: {
            push: {
                type: Object,
                required: true
            },
            color: {
                type: String,
                default: 'orange'
            },
        },
    }
</script>

<style lang="scss">
</style>
